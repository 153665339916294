import { Box, styled } from '@mui/material';

export const CloseButton = styled(Box)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: ${({ theme }) => theme.palette.grey[600]};
  cursor: pointer;
`;

export const ToastContainer = styled(Box)`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto 1fr;
  align-items: center;
`;

export const IconContainer = styled(Box)`
  width: 2rem;
  height: 2rem;
`;
