import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import BigNumber from 'bignumber.js';
import debug from 'debug';
import { ethers } from 'ethers';
import React, { useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Iconify } from 'src/components/Iconify';
import { SliderConnectedWithInput } from 'src/components/SliderConnectedWithInput';
import { UiCard } from 'src/components/ui/UiCard';
import { UiExecuteButton } from 'src/components/ui/UiExecuteButton';
import { UiTextFieldWithMax } from 'src/components/ui/UiTextFieldWithMax';
import { MAX_UINT } from 'src/constants/eth';
import { useAllowance } from 'src/hooks/useAllowance';
import { useApprove } from 'src/hooks/useApprove';
import { usePendingUnwrap } from 'src/hooks/usePendingUnwrap';
import { AssetParam } from 'src/pages/[assetAddress]/components/AssetParam';
import { useAppChain } from 'src/providers/AppChainProvider';
import { useTokenBalance } from 'src/providers/BalancesProvider';
import { useSetModal } from 'src/providers/ModalsProvider';
import { useSelectedPoolWalletSummary } from 'src/providers/WalletSummaryProvider';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { BToken } from 'src/types/asset';
import { BN, getAtomicAmount } from 'src/utils/bigNumber';
import { calculateAPY } from 'src/utils/token';
import { bTokenAbi } from '../../../abi';
import { CollateralSwitch } from '../../../components/CollateralSwitch';
import tooltipsDic from '../../../configs/tooltips.json';
import { useBTokenPrice } from '../../../hooks/useBTokenPrice';
import { useTransactions } from '../../../providers/TransactionsProvider';
import { WrapUnwrap } from '../components/WrapUnwrap';
import { SupplyHelpMessage } from './SupplyHelpMessage';

const log = debug('components:Supply');

export const Supply = () => {
  const { asset } = useOutletContext<{ asset: BToken }>();
  const { balance } = useTokenBalance(asset.wrapped.address);
  const [{ chainConfig }] = useAppChain();
  const { userAddress, walletProvider } = useWeb3State();
  const price = useBTokenPrice(asset);
  const { balance: underlyingBalance } = useTokenBalance(asset.wrapped.address);
  const tokenApprove = useApprove(asset.underlyingAssetAddress, asset.address, 'approve');
  const [tokenAllowance, reloadAllowance] = useAllowance(
    asset.underlyingAssetAddress,
    userAddress,
    asset.address,
  );
  const setModal = useSetModal();
  const { accountBorrowingTotalUsd, accountBorrowLimitTotalUsd } = useSelectedPoolWalletSummary();
  const isMobile = useMediaQuery('(max-width:425px)');
  const { pendingAmount: claimAmount } = usePendingUnwrap(asset);
  const { trackTx, trackError } = useTransactions();

  const [amount, setAmount] = useState('0');

  const maxSupply = BigNumber.max(
    BN(balance.fullPrecision).minus(
      BN(claimAmount).div(BN(10).pow(Number(asset.wrapped.decimals))),
    ),
    0,
  ).toString();
  const borrowLimitUsed = BN(accountBorrowLimitTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd).div(accountBorrowLimitTotalUsd).times(100).toFixed(2)
    : 100;

  const featureBorrowLimit = BN(accountBorrowingTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd)
        .div(
          BN(accountBorrowLimitTotalUsd).plus(
            BN(amount)
              .times(BN(asset.collateralFactorMantissa.toString()).div(1e18))
              .times(price?.fullPrecision || '0'),
          ),
        )
        .times(100)
        .toFixed(2)
    : 0;

  const needApproval = BN(tokenAllowance).lt(
    getAtomicAmount(amount, Number(asset.wrapped.decimals)),
  );

  const errorMessage = BN(amount).gt(maxSupply) ? 'No Funds Available' : '';

  function approveToSpendTokens() {
    return tokenApprove(MAX_UINT).finally(reloadAllowance);
  }

  async function supply() {
    if (!walletProvider) return;

    log('call supply', getAtomicAmount(amount, Number(asset.wrapped.decimals)));

    let tx;

    try {
      const supplyValue = getAtomicAmount(amount, Number(asset.wrapped.decimals));
      const signer = await walletProvider.getSigner();
      const bTokenContract = new ethers.Contract(asset.address, bTokenAbi, signer);

      setModal({ key: 'loader', title: 'Confirm your transaction in the wallet' });

      tx = await bTokenContract.mint(supplyValue);

      setModal({
        key: 'loader',
        title: `Supplying ${amount} ${asset.wrapped.symbol}...`,
        txHash: tx.txHash,
      });

      trackTx(tx);
      log('tx', tx);
      await tx.wait();
      setModal(null);

      log(`${supplyValue} ${asset.wrapped.symbol} successfully supplied.`);
    } catch (err) {
      trackError(err, tx);
      setModal(null);
      console.error('Supply action failed:', err);
      throw err;
    }
  }

  const balanceForInput = useMemo(
    () => [{ label: 'Balance', value: balance, symbol: asset.wrapped.symbol, clickable: true }],
    [asset.wrapped.symbol, balance],
  );

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ padding: { xs: 1, md: 3 } }}>
          <SupplyHelpMessage />
        </Box>
        <WrapUnwrap isWrap label="Wrap for supply" />
      </Grid>
      <Grid item xs={12}>
        <UiCard>
          <Stack spacing={2}>
            <Typography variant="h4">Supply</Typography>
            <UiTextFieldWithMax
              balances={balanceForInput}
              maxValue={maxSupply}
              onValueChange={setAmount}
              value={amount}
              mb={2}
            />
            <Box>
              <SliderConnectedWithInput
                decimals={asset.wrapped.decimals}
                value={amount}
                maxValue={maxSupply}
                setValue={setAmount}
              />
            </Box>
            <Box>
              <Stack direction={'column'} flexWrap={'wrap'}>
                <Stack
                  direction={isMobile ? 'column' : 'row'}
                  justifyContent={'space-between'}
                  marginBottom={'1.5rem'}
                >
                  <AssetParam
                    name="Supply APY"
                    value={`${calculateAPY(asset.supplyRatePerBlock, chainConfig.blocksPerDay)} %`}
                    sx={{ marginBottom: isMobile ? '1.5rem' : '0' }}
                  />
                  <AssetParam
                    name="Distribution APY"
                    value="-%"
                    tooltipMessage={tooltipsDic.distributionAPY}
                    sx={{ textAlign: isMobile ? 'left' : 'right' }}
                  />
                </Stack>
                <Stack
                  direction={isMobile ? 'column' : 'row'}
                  justifyContent={'space-between'}
                  marginBottom={'1.5rem'}
                >
                  <AssetParam
                    name="Borrow Limit Used"
                    value={
                      <Stack direction="row" alignItems="center">
                        {borrowLimitUsed} %
                        <Iconify
                          icon="material-symbols:arrow-forward-rounded"
                          width={24}
                          sx={{ mx: 2 }}
                        />
                        {featureBorrowLimit} %
                      </Stack>
                    }
                  />
                  <AssetParam
                    name="Use as collateral"
                    value={<CollateralSwitch asset={asset} />}
                    sx={{ textAlign: isMobile ? 'left' : 'right' }}
                  />
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
                  <AssetParam
                    name="Wallet Balance"
                    value={`${underlyingBalance ? underlyingBalance.formatted : 0} ${
                      asset.wrapped.symbol
                    }`}
                  />
                </Stack>
              </Stack>
            </Box>
            <UiExecuteButton
              executeLabel={errorMessage || 'Supply'}
              onClick={needApproval ? approveToSpendTokens : supply}
              needApproval={needApproval}
              disabled={!needApproval && (Boolean(errorMessage) || !BN(amount).gt(0))}
            />
          </Stack>
        </UiCard>
      </Grid>
    </>
  );
};
