import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { UiQuestion } from 'src/components/ui/UiQuestion';

export const UiParam: FC<{
  name: string;
  value: string | bigint | ReactJSXElement;
  variant?: 'normal' | 'big';
  tooltipMessage?: string;
}> = ({ name, value, variant = 'normal', tooltipMessage }) => {
  return React.isValidElement(value) ? (
    <Stack
      sx={{
        flexWrap: 'wrap',
        alignItems: { xs: 'center', md: 'flex-start' },
        justifyContent: { xs: 'space-between' },
      }}
      direction={{ xs: 'row', md: 'column' }}
      mb={1}
    >
      <Typography sx={{ color: 'text.secondary' }}>
        {name}
        {tooltipMessage && <UiQuestion message={tooltipMessage} />}
      </Typography>
      {value}
    </Stack>
  ) : variant === 'normal' ? (
    <Stack
      sx={{
        flexWrap: 'wrap',
        paddingRight: { xs: '0', md: '2rem' },
        alignItems: { xs: 'center', md: 'flex-start' },
        justifyContent: { xs: 'space-between' },
        whiteSpace: 'nowrap',
      }}
      direction={{ xs: 'row', md: 'column' }}
      mb={1}
    >
      <Typography sx={{ color: 'text.secondary' }}>
        {name}
        {tooltipMessage && <UiQuestion message={tooltipMessage} />}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          fontSize: { xs: '1.2rem', sm: '1.375rem' },
          wordWrap: 'break-word',
          wordBreak: 'break-all',
        }}
      >
        {value.toString()}
      </Typography>
    </Stack>
  ) : (
    <Stack width={{ xs: '100%', md: '50%' }} mb="2rem">
      <Typography variant="h5" sx={{ color: 'text.secondary' }} fontWeight="normal">
        {name}
        {tooltipMessage && <UiQuestion message={tooltipMessage} />}
      </Typography>
      <Typography variant="h3" sx={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
        {value.toString()}
      </Typography>
    </Stack>
  );
};
