import { CHAIN_LIST, ChainConfig } from 'src/configs/chains.config';
import { ChainId, ChainUrl } from 'src/constants/chains';
import { Web3ControllerState } from 'src/utils/Web3Controller';
import { AppChainState } from 'src/providers/AppChainProvider';

export const getChainConfig = <T extends string>(chainUrl: T) =>
  CHAIN_LIST.find((chain) => chain.urlRepresentation === chainUrl) as T extends ChainUrl
    ? ChainConfig
    : ChainConfig | undefined;

export type Web3NetworkCheck = ReturnType<typeof checkWeb3Network>;

export const isChainUrlSupported = (
  chainUrlRepresentation?: string,
): chainUrlRepresentation is ChainUrl =>
  CHAIN_LIST.some((chain) => chain.urlRepresentation === chainUrlRepresentation);

export const isChainSupported = (chainId: null | number): chainId is ChainId =>
  CHAIN_LIST.some((chain) => chain.id === chainId);

export const extractChainFromPath = (pathname: string) => {
  const [, chainIUrl] = pathname.split('/');

  return chainIUrl;
};

export const getCurrentChainConfig = () => {
  const appChainUrl = extractChainFromPath(window.location.pathname);

  return CHAIN_LIST.find((chain) => chain.urlRepresentation === appChainUrl);
};

export const checkWeb3Network = (
  { userAddress, chainId }: Web3ControllerState,
  { chainConfig }: AppChainState,
) => ({
  isConnected: Boolean(userAddress),
  isSupported: isChainSupported(chainId),
  networksMatch: chainConfig.id === chainId, // app chain matches chain selected in the wallet provider
});
