import { Slider, Stack, Typography } from '@mui/material';
import { BTokenSummary } from 'src/hooks/useBTokenSummary';
import { AssetParam } from 'src/pages/[assetAddress]/components/AssetParam';
import { BToken } from 'src/types/asset';
import { BN } from 'src/utils/bigNumber';
import { muiSlider } from 'src/utils/cssStyles';
import { calcTokenSupplying } from '../../utils/token';
import { Iconify } from '../Iconify';

export const UseAsCollateralModal = ({
  asset,
  bTokenSummary,
  accountBorrowingTotalUsd,
  accountBorrowLimitTotalUsd,
}: {
  asset: BToken;
  bTokenSummary: BTokenSummary;
  accountBorrowingTotalUsd: string;
  accountBorrowLimitTotalUsd: string;
}) => {
  const tokenSupplying = calcTokenSupplying(
    asset,
    bTokenSummary?.balance?.wallet.raw.toString() || 0,
  );

  let borrowLimitDelta = BN(tokenSupplying)
    .times(BN(asset.collateralFactorMantissa.toString()).div(1e18))
    .times(bTokenSummary?.price?.fullPrecision || '0')
    .div(BN(10).pow(asset.wrapped.decimals.toString()))
    .div(BN(10).pow(asset.wrapped.decimals.toString()))
    .toString();

  if (asset.inMarket) {
    borrowLimitDelta = '-' + borrowLimitDelta;
  }

  const featureBorrowLimit = BN(accountBorrowLimitTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd)
        .div(BN(accountBorrowLimitTotalUsd).plus(borrowLimitDelta))
        .times(100)
        .toFixed(2)
    : 0;

  const borrowLimitUsed = BN(accountBorrowingTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd).div(accountBorrowLimitTotalUsd).times(100).toFixed(2)
    : 0;

  const isLiquidation = BN(featureBorrowLimit).gte(100);

  return (
    <Stack>
      {isLiquidation && (
        <Typography color={'error'} mb="1rem">
          Warning: Collateral Required!
        </Typography>
      )}
      <Typography>
        {isLiquidation
          ? 'This asset is required to support your borrowed assets. Either repay borrowed assets, or supply another asset as collateral.'
          : asset.inMarket
          ? 'This asset will no longer be used towards your borrowing limit, and can’t be seized in liquidation.'
          : 'Each asset used as collateral increases your borrowing limit. Be careful, this can subject the asset to being seized in liquidation.'}
      </Typography>
      <Stack width={'100%'}>
        <Slider
          min={0}
          max={100}
          valueLabelDisplay="on"
          valueLabelFormat={(value) => `${value}%`}
          disabled
          value={Number(borrowLimitUsed)}
          sx={{
            ...muiSlider,
            '.MuiSlider-thumb': {
              display: 'none',
            },
          }}
          style={{ marginLeft: 0 }}
        />
        <AssetParam
          name={BN(borrowLimitUsed).lt(100) ? 'Borrowing limit used' : 'Borrowing limit reached'}
          value={
            <Stack direction="row" alignItems="center">
              {borrowLimitUsed} %
              <Iconify icon="material-symbols:arrow-forward-rounded" width={24} sx={{ mx: 2 }} />
              {isLiquidation ? 'Liquidation' : `${featureBorrowLimit} %`}
            </Stack>
          }
        />
      </Stack>
    </Stack>
  );
};
