import { FC } from 'react';
import {
  DialogTitle,
  DialogContent,
  Link,
  Box,
  DialogActions,
  Dialog,
  Button,
} from '@mui/material';
import { useSetModal } from 'src/providers/ModalsProvider';
import { useAppChain } from 'src/providers/AppChainProvider';

export const SuccessModal: FC<{ title: string; txHash?: string }> = ({ title, txHash }) => {
  const setModal = useSetModal();
  const [{ chainConfig }] = useAppChain();

  return (
    <Dialog
      open={true}
      onClose={() => setModal(null)}
      maxWidth="xs"
      fullWidth
      sx={{
        '.MuiPaper-root': {
          alignItems: 'center',
        },
      }}
    >
      <DialogContent sx={{ alignItems: 'center', padding: 0, marginTop: '2.25rem' }}>
        <Box
          sx={{
            width: '6.5rem',
            height: '6.5rem',
            background: 'url(/images/success-arrow-up.svg) no-repeat 100% center',
          }}
        ></Box>
      </DialogContent>
      <DialogTitle
        textAlign="center"
        sx={{ padding: 0, fontSize: '1.25rem', marginTop: '1.25rem' }}
      >
        {title}
      </DialogTitle>
      {txHash && (
        <DialogActions sx={{ padding: 0 }}>
          <Link mt="1.25rem" href={`${chainConfig.explorer}/tx/${txHash}`} target={'_blank'}>
            Show transaction status
          </Link>
        </DialogActions>
      )}
      <DialogActions sx={{ mt: '1.25rem', mb: '1rem' }}>
        <Button variant="soft" color="inherit" onClick={() => setModal(null)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
