import { Box, useTheme } from '@mui/material';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';

import { ReactComponent as IconLogo } from 'src/assets/booster-logo.svg';

export const Logo: FC = () => {
  const theme = useTheme();
  const { chainUrlRepresentation, poolId } = useParams();

  return (
    <Link
      to={`/${chainUrlRepresentation}/${poolId}`}
      style={{ textDecoration: 'none', color: theme.palette.text.primary }}
    >
      <Box width={{ xs: '7rem', sm: 'auto' }}>
        <IconLogo style={{ display: 'block', width: '100%' }} />
      </Box>
    </Link>
  );
};
