import debug from 'debug';
import { Block, ethers } from 'ethers';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { medianFeedsAbi } from '../abi';
import { MEDIAN_FEEDS_ADDRESS, MEDIAN_FEEDS_CHAIN_RPC } from '../constants/chains';
import { ORACLE_ETH_ADDRESS, ZERO_ADDRESS } from '../constants/eth';
import { Q112 } from '../constants/prices';
import { useImmutableCallback } from '../hooks/useActualRef';
import { FCC } from '../types/FCC';
import { Metrics, QuoteMetrics, QuoteMetricsResp, SignetMerkleTree } from '../types/medianOracle';
import { AssetPricesWithQ112 } from '../types/prices';
import { BN } from '../utils/bigNumber';
import { isAddressesEq } from '../utils/compareAddresses';
import { useAppChain } from './AppChainProvider';
import { useAssets } from './AssetsProvider';

const log = debug('providers:MedianPricesProvider');

const medianFeedsRpcProvider = new ethers.JsonRpcProvider(MEDIAN_FEEDS_CHAIN_RPC);
const medianFeedsContract = new ethers.Contract(
  MEDIAN_FEEDS_ADDRESS,
  medianFeedsAbi,
  medianFeedsRpcProvider,
);

const medianPricesProviderInit: {
  loading: boolean;
  metrics: Metrics | null;
  quoteMetrics: QuoteMetrics | null;
  prices: AssetPricesWithQ112;
  signetMerkleTree: SignetMerkleTree | null;
  merkleTreeQuoteMetrics: QuoteMetrics | null;
  merkleWrightBlock: Block | null;
  updateMetrics: () => Promise<void>;
} = {
  loading: true,
  metrics: null,
  quoteMetrics: null,
  prices: {},
  signetMerkleTree: null,
  merkleTreeQuoteMetrics: null,
  merkleWrightBlock: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateMetrics: () => new Promise(() => {}),
};

const MedianOracleProviderCtx = createContext(medianPricesProviderInit);

export const GnosisMedianOracleProvider: FCC = ({ children }) => {
  const { assets } = useAssets();
  const [{ chainConfig }] = useAppChain();

  const [prices, setPrices] = useState<AssetPricesWithQ112>({});
  const [loading, setLoading] = useState(true);
  const [metrics, setMetrics] = useState<Metrics | null>(null);
  const [signetMerkleTree, setSignetMerkleTree] = useState<SignetMerkleTree | null>(null);
  const [merkleTreeQuoteMetrics, setMerkleTreeQuoteMetrics] = useState<QuoteMetrics | null>(null);
  const [merkleWrightBlock, setMerkleWrightBlock] = useState<Block | null>(null);
  const [quoteMetrics, setQuoteMetrics] = useState<QuoteMetrics | null>(null);

  const pricesFetched = useRef(false);

  useEffect(() => {
    log('prices changed', prices);
  }, [prices]);

  useEffect(() => {
    getMetricsMerkleTree();
  }, []);

  useEffect(() => {
    medianFeedsContract.on('MetricUpdated', priceUpdateCallback);

    return () => {
      medianFeedsContract.removeListener('MetricUpdated', priceUpdateCallback);
    };
  }, []);

  useEffect(() => {
    if (!metrics) return;
    if (!quoteMetrics) return;

    const _prices = {} as AssetPricesWithQ112;

    Object.values(assets).forEach((pools) => {
      Object.values(pools).forEach((poolAsset) => {
        const tokenAddress = poolAsset.wrapped.underlying.address;
        const fallbackToken = chainConfig.tokenPriceFallbacks[tokenAddress];

        log('fallbackToken', fallbackToken);

        const metricId = metrics.findIndex((metric) => {
          if (fallbackToken) return metric.flat().join('').includes(fallbackToken);

          if (isAddressesEq(tokenAddress, ZERO_ADDRESS))
            return metric.flat().join('').includes(ORACLE_ETH_ADDRESS);

          return metric.flat().join('').includes(tokenAddress);
        });

        if (metricId === -1) return;

        _prices[poolAsset.address] = quoteMetrics[metricId][0];
      });
    });

    setPrices(_prices);
  }, [assets, metrics, quoteMetrics]);

  const priceUpdateCallback = useImmutableCallback(() => getMetricsMerkleTree());

  async function getMetricsMerkleTree() {
    pricesFetched.current = true;
    const metrics = await medianFeedsContract.getMetrics();

    log('metricssdfsdf', JSON.parse(JSON.stringify(metrics)));

    setMetrics(metrics);

    const quoteMetricsResp = (await medianFeedsContract['quoteMetrics(uint256[])']([
      ...Array(metrics.length).keys(),
    ])) as QuoteMetricsResp;

    const quoteMetrics = quoteMetricsResp.map((metric) => {
      const price = BN(metric[0].toString()).div(Q112);
      return [
        {
          fullPrecision: price.toString(),
          formatted: price.toFixed(4),
          priceQ112: metric[0].toString(),
        },
        metric[1],
      ];
    }) as QuoteMetrics;

    log('quoteMetrics', quoteMetrics);

    setQuoteMetrics(quoteMetrics);

    setLoading(false);

    const signetMerkleTree = await medianFeedsContract.getSignedMerkleTreeRoot();

    log('signetMerkleTree', signetMerkleTree);

    setSignetMerkleTree(signetMerkleTree);

    const merkleWrightBlock = await getLatestBlockBefore(Number(signetMerkleTree[0]) + 15 * 60);

    setMerkleWrightBlock(merkleWrightBlock);

    log('merkleWrightBlock', merkleWrightBlock);

    if (!merkleWrightBlock) {
      console.warn('merkleWrightBlock is null');
      return;
    }

    const medianPricesForMerkleProof = (await getMedianPricesAtBlock(
      merkleWrightBlock.number + 1,
      metrics,
    )) as QuoteMetricsResp;

    const merkleQuoteMetrics = medianPricesForMerkleProof.map((metric) => {
      const price = BN(metric[0].toString()).div(Q112);
      return [
        {
          priceQ112: metric[0].toString(),
          fullPrecision: price.toString(),
          formatted: price.toFixed(4),
        },
        metric[1],
      ];
    }) as QuoteMetrics;

    log('merkleQuoteMetrics', merkleQuoteMetrics);

    setMerkleTreeQuoteMetrics(merkleQuoteMetrics);
  }

  async function getAvgBlockTime(latest: ethers.Block) {
    const backBlock = await medianFeedsRpcProvider.getBlock(latest.number - 100);

    if (!backBlock) {
      console.warn('ERROR IN FETCHING BACK BLOCK');
      return 1;
    }

    const avgBlockTime = Math.max(
      (latest.timestamp - backBlock.timestamp) / (latest.number - backBlock.number),
      1,
    );

    log('avgBlockTime: ', avgBlockTime);

    return Number(avgBlockTime.toFixed(0));
  }

  async function getLatestBlockBefore(timestamp: number) {
    let upper: ethers.Block | null = null;
    const latest = await medianFeedsRpcProvider.getBlock('latest');

    if (!latest) {
      console.warn('BLOCK FETCHING FAULT');
      return null;
    }

    const avgBlockTime = await getAvgBlockTime(latest);

    if (latest.timestamp === timestamp) {
      return latest;
    }

    upper = latest;
    let lower = await medianFeedsRpcProvider.getBlock(Math.round(upper.number / 1.01));

    if (!lower) {
      console.warn('BLOCK FETCHING FAULT');
      return null;
    }

    if (lower.timestamp === timestamp) {
      return lower;
    }

    let jumps = 0;
    let jumpFromUpper = true;

    let closestBlock: ethers.Block | null = null;

    while (!closestBlock) {
      if (upper.number === lower.number + 1) {
        log(
          `getLatestBlockBefore: ${
            latest.timestamp - timestamp
          } seconds): found in ${jumps} jumps ${latest.number - lower.number} blocks back`,
        );
        closestBlock = lower;
        break;
      }

      let middleNum;

      if (jumpFromUpper) {
        middleNum = Math.round(upper.number - (upper.timestamp - timestamp) / avgBlockTime);

        if (middleNum <= lower.number) {
          middleNum = lower.number + 1;
        }
      } else {
        middleNum = Math.round(lower.number + (timestamp - lower.timestamp) / avgBlockTime);

        if (middleNum >= upper.number) {
          middleNum = upper.number - 1;
        }
      }

      jumps += 1;
      const middle = await medianFeedsRpcProvider.getBlock(middleNum);

      if (!middle) {
        console.warn('MIDDLE BLOCK FETCHING FAULT');
        break;
      }

      if (middle.timestamp == timestamp) {
        log(
          `getLatestBlockBefore (-${
            latest.timestamp - timestamp
          } seconds): found in ${jumps} jumps ${latest.number - middle.number} blocks back`,
        );
        closestBlock = middle;
        break;
      } else if (middle.timestamp > timestamp) {
        upper = middle;
        jumpFromUpper = true;
      } else {
        lower = middle;
        jumpFromUpper = false;
      }
    }

    return closestBlock;
  }

  async function getMedianPricesAtBlock(blockNumber: number, metrics: Metrics) {
    if (!metrics) return null;

    const contractInterface = medianFeedsContract.interface;
    const tx = await medianFeedsContract['quoteMetrics(uint256[])'].populateTransaction(
      [...Array(metrics.length).keys()],
      {
        blockTag: blockNumber,
      },
    );
    const resp = await medianFeedsRpcProvider.call(tx);

    return contractInterface.decodeFunctionResult('quoteMetrics(uint256[])', resp)[0];
  }

  return (
    <MedianOracleProviderCtx.Provider
      value={{
        loading,
        metrics,
        quoteMetrics,
        prices,
        signetMerkleTree,
        merkleTreeQuoteMetrics,
        merkleWrightBlock,
        updateMetrics: getMetricsMerkleTree,
      }}
    >
      {children}
    </MedianOracleProviderCtx.Provider>
  );
};

export const useGnosisMedianOracle = () => useContext(MedianOracleProviderCtx);
