import { Typography } from '@mui/material';
import { usePools } from '../../hooks/usePools';
import { useRewards } from '../../providers/RewardsProvider';
import { BN, getDisplayAmount } from '../../utils/bigNumber';

export const SwapRewardsModal = () => {
  const { selectedPoolConfig } = usePools();
  const { swapTokens, tokenInfo } = useRewards();

  const swapPercentValue = BN(tokenInfo.balance.raw).div(tokenInfo.totalSupply);

  if (!selectedPoolConfig || !swapTokens) return null;

  return (
    <>
      <Typography sx={{ mb: 2 }}>After swap you will receive:</Typography>
      {swapTokens[selectedPoolConfig.id].map((el) => (
        <Typography key={el.address} sx={{ mt: 1 }}>
          <b>
            {getDisplayAmount(swapPercentValue.times(el.contractBalance.raw), {
              decimals: el.decimals,
              cut: false,
            })}
          </b>{' '}
          {el.symbol}
        </Typography>
      ))}
    </>
  );
};
