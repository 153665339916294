import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FC, memo, ReactNode } from 'react';
import { useWrongNetworkLabel } from 'src/hooks/useNetworkCheck';

export type ConfirmModalSettings = {
  title?: ReactNode;
  body: ReactNode;
  cancelText?: ReactNode;
  confirmText?: ReactNode;
  onConfirm: (res: boolean) => void;
  onClose?: () => void;
};

export const ConfirmModal: FC<ConfirmModalSettings> = memo(
  ({ title = '', body, cancelText = 'Cancel', confirmText = 'Confirm', onConfirm, onClose }) => {
    const wrongLabel = useWrongNetworkLabel();

    return (
      <Dialog maxWidth="sm" fullWidth open={true} onClose={onClose}>
        <DialogTitle textAlign="center">{title}</DialogTitle>
        <DialogContent sx={{ alignItems: 'center' }}>{body}</DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          {cancelText && (
            <Button
              sx={{
                background: (theme) => theme.palette.primary.main,
                color: '#fff',
              }}
              onClick={onClose}
            >
              {cancelText}
            </Button>
          )}
          <Button
            sx={{
              background: (theme) => theme.palette.primary.main,
              color: '#fff',
              ':disabled': {
                background: (theme) => theme.palette.grey[300],
              },
            }}
            disabled={!!wrongLabel}
            onClick={() => (wrongLabel ? null : onConfirm(true))}
          >
            {wrongLabel || confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
