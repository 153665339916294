import { Stack, Switch, Typography } from '@mui/material';
import debug from 'debug';
import { ethers } from 'ethers';
import { unitrollerAbi } from 'src/abi';
import { UseAsCollateralModal } from 'src/components/modals/UseAsCollateralModal';
import { useBTokenSummary } from 'src/hooks/useBTokenSummary';
import { usePools } from 'src/hooks/usePools';
import { useSetModal } from 'src/providers/ModalsProvider';
import { useSelectedPoolWalletSummary } from 'src/providers/WalletSummaryProvider';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { BToken } from 'src/types/asset';
import { useTransactions } from '../providers/TransactionsProvider';
import { useTokenBalance } from 'src/providers/BalancesProvider';
import { BN } from 'src/utils/bigNumber';
import { SupplyTooltip } from './SupplyTooltip';

const log = debug('components:CollateralSwitch');

export const CollateralSwitch = ({ asset }: { asset: BToken }) => {
  // const {} = useAssets();
  const setModal = useSetModal();
  const { walletProvider } = useWeb3State();
  const { selectedPoolConfig } = usePools();
  const bTokenSummary = useBTokenSummary(asset.address);
  const { accountBorrowingTotalUsd, accountBorrowLimitTotalUsd } = useSelectedPoolWalletSummary();
  const { inMarket, wrapped } = asset;
  const { trackTx, trackError } = useTransactions();
  const { balance: supplyingBalance } = useTokenBalance(asset.address);

  async function handleSwitchClick(event: React.MouseEvent) {
    event.preventDefault();
    const isSupplying = supplyingBalance && BN(supplyingBalance.raw).gt(0);

    if (isSupplying || inMarket) {
      setModal({
        key: 'confirm',
        title: inMarket ? 'Disable as Collateral' : 'Enable as Collateral',
        body: (
          <UseAsCollateralModal
            asset={asset}
            bTokenSummary={bTokenSummary}
            accountBorrowingTotalUsd={accountBorrowingTotalUsd}
            accountBorrowLimitTotalUsd={accountBorrowLimitTotalUsd}
          />
        ),
        confirmText: inMarket
          ? `disable ${wrapped.underlying.symbol} as collateral`
          : `use ${wrapped.underlying.symbol} as collateral`,
        onConfirm: enterOrExitMarket,
        onClose: () => setModal(null),
      });
    } else {
      setModal({
        key: 'info',
        Title: (
          <Stack alignItems="center">
            <Typography textAlign="center" fontWeight="bold" mb="1rem">
              To use this function, you first need to supply the asset.
            </Typography>{' '}
            <Stack direction="row" gap="0.2rem">
              <SupplyTooltip /> <Typography>- required.</Typography>
            </Stack>
          </Stack>
        ),
      });
    }
  }

  async function enterOrExitMarket() {
    let tx;

    try {
      if (!walletProvider) return;

      const signer = await walletProvider.getSigner();
      const contract = new ethers.Contract(
        selectedPoolConfig.contracts.unitroller,
        unitrollerAbi,
        signer,
      );

      setModal({ key: 'loader', title: 'Confirm your transaction in the wallet' });

      tx = inMarket
        ? await contract.exitMarket(asset.address)
        : await contract.enterMarkets([asset.address]);

      trackTx(tx);

      log('tx: ', tx);

      setModal({
        key: 'loader',
        title: inMarket ? 'Disable as collateral' : 'Enable as collateral',
        txHash: tx.txHash,
      });

      await tx.wait();

      setModal(null);
    } catch (err) {
      trackError(err, tx);
      setModal(null);
      console.error('Change collateral status action failed:', err);
      throw err;
    }
  }

  return <Switch checked={inMarket} onClick={handleSwitchClick} />;
};
