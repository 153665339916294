import {
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material';
import customShadows from 'src/theme/customShadows';
import GlobalStyles from 'src/theme/globalStyles';
import { ComponentsOverrides } from 'src/theme/overrides';
import { palette } from 'src/theme/palette';
import { shadows } from 'src/theme/shadows';
import { typography } from 'src/theme/typography';
import { FCC } from 'src/types/FCC';

export const ThemeProvider: FCC = ({ children }) => {
  const themeOptions: ThemeOptions = {
    palette: palette('light'),
    typography,
    shape: { borderRadius: 8 },
    shadows: shadows('light'),
    customShadows: customShadows('light'),
  };

  const theme = createTheme(themeOptions);

  theme.components = ComponentsOverrides(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};
