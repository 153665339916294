import { Link, List, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { NavSectionProps } from 'src/components/nav/types';
import { NavList } from './NavSection/NavList';
import { StyledSubheader } from './NavSection/styles';

export default function NavSectionVertical({ data, sx, ...other }: NavSectionProps) {
  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || (group.items && group.items[0].title);

        const props = group.isExternal && {
          target: '_blanck',
          rel: 'noopener noreferrer',
        };

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && (
              <Link component={RouterLink} to={group?.path || ''} underline="none" {...props}>
                <StyledSubheader disableSticky>{group.subheader}</StyledSubheader>
              </Link>
            )}

            {group.items?.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
