import BigNumber from 'bignumber.js';
import { Call, Contract, Provider } from 'ethcall';
import { ethers } from 'ethers';
import { BN, getDisplayAmount } from 'src/utils/bigNumber';
import { bTokenAbi, erc20Abi, vaultAbi } from '../abi';
import { AssetProvider, BToken, TokenInfoCallsRes } from '../types/asset';
import { getCurrentChainConfig } from './chain';

export function calculateAPY(ratePerBlock: string, blocksPerDay: number) {
  const ratePerDay = BN(ratePerBlock.toString()).times(blocksPerDay).div(1e18);

  return BN(1).plus(ratePerDay).pow(365).minus(1).times(100).toFixed(2);
}

export function getBTokenByAddress(pools: AssetProvider, bTokenAddress: string) {
  const bTokens = Object.values(pools)
    .map((bTokensMap) => Object.values(bTokensMap))
    .flat();

  return bTokens.find((bToken) => bToken.address === bTokenAddress);
}

export function fetchUnwrappedTokensAddresses(
  appRpcProvider: ethers.JsonRpcProvider,
  addresses: string[],
) {
  const chainConfig = getCurrentChainConfig();

  if (!chainConfig) return Promise.resolve([]);
  const ethCallProvider = new Provider(chainConfig.id, appRpcProvider);

  return ethCallProvider.all(
    addresses.map((address) => {
      const contract = new Contract(address, vaultAbi);

      return contract.underlyingToken();
    }),
  ) as Promise<string[]>;
}

export function fetchTokensInfoRaw(
  appRpcProvider: ethers.JsonRpcProvider,
  addresses: string[],
  userAddress?: string | null,
  withTotalSupply = false,
) {
  const chainConfig = getCurrentChainConfig();

  if (!chainConfig) return Promise.resolve([]);

  const ethcallProvider = new Provider(chainConfig.id, appRpcProvider);
  const calls: Call[] = [];

  addresses.forEach((address) => {
    const contract = new Contract(address, erc20Abi);
    calls.push(contract.name());
    calls.push(contract.symbol());
    calls.push(contract.decimals());
    if (userAddress) calls.push(contract.balanceOf(userAddress));
    if (withTotalSupply) calls.push(contract.totalSupply());
  });

  return ethcallProvider.all(calls) as Promise<TokenInfoCallsRes>;
}

export function calcTokenSupplying(asset: BToken, bTokenAmount: BigNumber.Value) {
  if (!asset) return '0';

  return BN(bTokenAmount)
    .times(asset.exchangeRateCurrent.toString())
    .div(BN(10).pow(asset.decimals.toString()))
    .div(BN(10).pow(18 - Number(asset.decimals)))
    .toString();
}

export function calcTokenTotalSupplying(asset: BToken) {
  return BN(asset.totalSupply.toString())
    .times(asset.exchangeRateCurrent.toString())
    .div(BN(10).pow(asset.decimals.toString()))
    .div(BN(10).pow(18 - Number(asset.decimals)));
}

export function calcTokenBorrowLimit(asset: BToken) {
  const supplying = calcTokenTotalSupplying(asset);

  let tokenBorrowLimit: BigNumber;

  if (BN(supplying).gt(asset.borrowCap.toString()))
    tokenBorrowLimit = BN(asset.borrowCap.toString()).minus(asset.totalBorrows.toString());
  else tokenBorrowLimit = BN(supplying).minus(asset.totalBorrows.toString());

  return {
    raw: tokenBorrowLimit.toString(),
    fullPrecision: tokenBorrowLimit.div(BN(10).pow(asset.wrapped.decimals.toString())).toString(),
    formatted: getDisplayAmount(tokenBorrowLimit, { decimals: Number(asset.wrapped.decimals) }),
  };
}

export function fetchAccountBorrowedBalances(
  userAddress: string,
  addresses: string[],
  appRpcProvider: ethers.JsonRpcProvider,
) {
  const calls: Call[] = [];
  const chainConfig = getCurrentChainConfig();

  if (!chainConfig) return Promise.resolve([]);

  const ethcallProvider = new Provider(chainConfig.id, appRpcProvider);

  addresses.forEach((address) => {
    const contract = new Contract(address, bTokenAbi);
    calls.push(contract.borrowBalanceStored(userAddress));
  });

  return ethcallProvider.tryAll(calls) as Promise<[balance: bigint]>;
}

export function fetchTokensBalances(
  userAddress: string,
  addresses: string[],
  appRpcProvider: ethers.JsonRpcProvider,
) {
  const calls: Call[] = [];
  const chainConfig = getCurrentChainConfig();

  if (!chainConfig) return Promise.resolve([]);

  const ethcallProvider = new Provider(chainConfig.id, appRpcProvider);

  addresses.forEach((address) => {
    const contract = new Contract(address, erc20Abi);
    calls.push(contract.balanceOf(userAddress));
  });

  return ethcallProvider.tryAll(calls) as Promise<[balance: bigint]>;
}
