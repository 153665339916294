import BigNumber from 'bignumber.js';
import debug from 'debug';
import { useSelectedPoolWalletSummary } from 'src/providers/WalletSummaryProvider';
import { BToken } from 'src/types/asset';
import { BN } from 'src/utils/bigNumber';
import { calcTokenBorrowLimit } from 'src/utils/token';
import { useBTokenPrice } from './useBTokenPrice';

const log = debug('hooks:useMaxBorrow');

export const useMaxBorrow = (asset?: BToken) => {
  const price = useBTokenPrice(asset);

  const { accountBorrowingTotalUsd, accountBorrowLimitTotalUsd } = useSelectedPoolWalletSummary();

  const accountMaxBorrowUsd = BN(accountBorrowLimitTotalUsd).minus(accountBorrowingTotalUsd);

  const tokenBorrowLimit = asset ? calcTokenBorrowLimit(asset) : { raw: '0', fullPrecision: '0' };

  const accountMaxBorrow =
    asset && price && BN(accountMaxBorrowUsd).gt(0)
      ? BN(accountMaxBorrowUsd).div(price.fullPrecision).toFixed(Number(asset.wrapped.decimals))
      : 0;

  log('accountBorrowLimitTotalUsd', accountBorrowLimitTotalUsd);
  log('accountMaxBorrowUsd', accountMaxBorrowUsd.toString());
  log('accountMaxBorrow', accountMaxBorrow);

  return BigNumber.minimum(accountMaxBorrow, tokenBorrowLimit.fullPrecision).toString();
};
