import { Stack, Typography } from '@mui/material';

export const WithdrawHelpMessage = () => (
  <Stack
    sx={{
      padding: '1rem 1.5rem',
      background: (theme) => theme.palette.grey[300],
      borderRadius: '8px',
      mt: '20px',
    }}
  >
    <Typography mb="1rem">
      To access your original asset, simply follow the "unwrap" and then "claim" steps. If
      necessary, withdraw your collateral in the designated "Withdraw" section.
    </Typography>
    <Typography>
      Note: The unwrapping process includes a mandatory waiting period, giving the protocol
      sufficient time to detect and respond to any unusual activity or potential exploitation.{' '}
      <a
        href="https://docs.booster.foundation/universal-vault"
        target="_blank"
        rel="noreferrer"
        style={{ color: 'inherit' }}
      >
        Read our Docs for details.
      </a>
    </Typography>
  </Stack>
);
