import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { UiParam } from 'src/components/ui/UiParam';
import { useSelectedPoolAssets } from 'src/providers/AssetsProvider';
import { BN } from 'src/utils/bigNumber';
import { useBoosterPrices } from '../providers/BoosterOraclePricesProvider';
import { useGnosisMedianOracle } from '../providers/GnosisMedianOracleProvider';
import { AssetPrice } from '../types/prices';

export const PoolStats = () => {
  const { assets } = useSelectedPoolAssets();
  const { prices: gnosisPrices } = useGnosisMedianOracle();
  const { prices: boosterPrices } = useBoosterPrices();

  const stats = useMemo(() => {
    let totalSupply = '0';
    let totalBorrow = '0';

    if (!assets || !gnosisPrices || !boosterPrices) return { totalSupply, totalBorrow };

    Object.values(assets)
      .filter((asset) => !asset.mintPaused && !asset.borrowPaused)
      .forEach((asset) => {
        const { address } = asset;
        let price: AssetPrice;

        if (boosterPrices[address] && BN(boosterPrices[address].fullPrecision).gt(0)) {
          price = boosterPrices[address];
        } else {
          price = gnosisPrices[address];
        }

        if (!price) return;

        const tokenTotalSupply = BN(asset.totalSupply)
          .times(price.fullPrecision)
          .times(asset.exchangeRateCurrent)
          .div(BN(10).pow(18 - Number(asset.decimals)))
          .div(BN(10).pow(Number(asset.wrapped.decimals)))
          .div(BN(10).pow(Number(asset.decimals)));
        totalSupply = tokenTotalSupply.plus(totalSupply).toString();
        const tokenTotalBorrow = BN(asset.totalBorrows)
          .times(price.fullPrecision)
          .div(BN(10).pow(Number(asset.wrapped.decimals)));

        totalBorrow = tokenTotalBorrow.plus(totalBorrow).toString();
      });

    return { totalSupply, totalBorrow };
  }, [assets, gnosisPrices, boosterPrices]);

  return (
    <>
      <Grid item xs={12} md={3}>
        <UiParam name="TVL" value={`${BN(stats.totalSupply).toFixed(2)}$`} />
      </Grid>
      <Grid item xs={12} md={3}>
        <UiParam name="Pool Total Borrow:" value={`${BN(stats.totalBorrow).toFixed(2)}$`} />
      </Grid>
    </>
  );
};
