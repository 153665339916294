import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useAppChain } from '../providers/AppChainProvider';
import { useEffect } from 'react';
import debug from 'debug';

const log = debug('components:UrlPoolIdManager');

export const UrlPoolIdManager = () => {
  const { chainUrlRepresentation, poolId } = useParams();
  const [{ chainConfig }, setAppChain] = useAppChain();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isPoolSupported()) {
      log('invalid pool id:', poolId);
      const newUrl = `/${chainUrlRepresentation}/${Object.keys(chainConfig.pools)[0]}`;
      log('redirect to:', newUrl);
      navigate(newUrl, { replace: true });
    }
  }, [chainConfig, poolId, navigate, setAppChain, chainUrlRepresentation]);

  function isPoolSupported() {
    return Object.keys(chainConfig.pools).some((el) => el === poolId);
  }

  return <Outlet />;
};
