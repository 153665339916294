import { Button } from '@mui/material';
import { FC } from 'react';
import { useChainStatus } from 'src/hooks/useChainStatus';

type Props = {
  executeLabel: string;
  onClick: () => void;
  disabled?: boolean;
  needApproval?: boolean;
};

export const UiExecuteButton: FC<Props> = ({
  executeLabel,
  onClick,
  disabled = false,
  needApproval = false,
}) => {
  const { isWalletConnected, isWalletChainSupported, isChainsIdsAreEq } = useChainStatus();
  const isWrongNetwork = !isWalletChainSupported || !isChainsIdsAreEq;

  const getExecuteBtnLabel = () => {
    if (!isWalletConnected) return 'Connect Your Wallet';
    if (isWrongNetwork) return 'Wrong Netwrork';
    if (needApproval) return 'Enable';
    return executeLabel;
  };

  return (
    <Button
      sx={{
        background: (theme) => theme.palette.primary.main,
        color: '#fff',
        ':disabled': {
          background: (theme) => theme.palette.grey[300],
        },
        textTransform: 'none',
      }}
      disabled={!isWalletConnected || isWrongNetwork || disabled}
      onClick={onClick}
      fullWidth
    >
      {getExecuteBtnLabel()}
    </Button>
  );
};
