import { CHAIN_ID } from '../constants/chains';
import { ValueOf } from '../types/objectHelpers';

export type Pools = ValueOf<typeof POOL_CONFIGS>;
export type PoolId = keyof Pools;
export type Pool = ValueOf<Pools>;

export const POOL_CONFIGS = {
  [CHAIN_ID.ARBITRUM]: {
    main: {
      id: 'main',
      name: 'Main',
      icon: '/images/pools/main.svg',
      pricesFromChainLink: [
        '0x5475C64B8030fb33dd1564dEAaEf2Bc6C65c58b8',
        '0x554Dc99527CB6629e03dcCa3a7F6b7ac8338EE1b',
        '0xeb1F76d2e18b99a3A473880C8905E90041c783b4',
      ].map((el) => el.toLowerCase()) as string[],
      vaultsWithoutApprove: ['0x5ef960a545d9f445a8945f3718b6cb7a83942792'].map((el) =>
        el.toLowerCase(),
      ) as string[],
      claimRewardsTokens: [
        '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
        '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
        '0xff692344f03d2feeaa3307eabcdd541e1178f155',
      ],
      contracts: {
        IRM_ETH_Updateable: '0xA259e7422871df7c842543E5B2A691cadA372318',
        IRM_USDT_Updateable: '0x7fAb6f336123c8Fe73d2160D6835838134Eb2e8c',
        compoundLens: '0xAF95D2185dEcfDbB826800Ef65e5a04AFc70b63c',
        comptroller: '0xF99a92EFc83B6c860351B6b6c2fDD0d0f1b48112',
        unitroller: '0xE4585064531FC0DdAC7eDCa41a3FC5AB92F95Cc4',
        bETH: '0xf6ecb5877d834f070c7e88802a66e1ea1922e7b5',
        vETH: '0x170144de36af1545ae786a1d7673156e3b49944f',
        rewards: '0xc5999EA86723fe61d7063c11Aa3DFB91fEe84717',
      },
    },
  },
  [CHAIN_ID.X1TEST]: {
    main: {
      id: 'main',
      name: 'Main',
      icon: '/images/pools/okc.svg',
      pricesFromChainLink: [] as string[],
      vaultsWithoutApprove: [] as string[],
      claimRewardsTokens: [
        '0xcBa55016Cb67D8cEcC69bb36e900dA9ab22b982A',
        '0x9c03D0abaDbe85d47DC40F1bFe13Dc1afb01EeDc',
        '0xfC83D3F77516FF18BF88Ff20A4ab0a614bD31c7C',
        '0xb199583e0F8AaB1ecF3360916CA8bbaA59943109',
        '0x5aa10029d4B60261f2D4999D0cB9030545fD6E10',
      ],
      contracts: {
        IRM_ETH_Updateable: '0x334aeaF09416dF0a09103724a85aaeDB04d46261',
        IRM_USDT_Updateable: '0x0b4ef90f1E3fA94a96fc588cd833A69cdd309508',
        compoundLens: '0x7fAb6f336123c8Fe73d2160D6835838134Eb2e8c',
        comptroller: '0xf6ecB5877d834F070C7E88802A66E1Ea1922E7B5',
        unitroller: '0xc531d0b0E24908b3e3A54A5660b610696588D03f',
        bETH: '0x1cf8E808B8933dd93cA9A320Abf5DC833065A26E',
        vETH: '0x9cbd25ba53547fca41886de20e59eb527ee77094',
        rewards: '0x27d58f623fAd4F4C2E1B2bBC6e6e0da8e7bb0AF4',
      },
    },
  },
} as const;
