import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'src/providers/ThemeProvider';
import { Web3CtxProvider } from 'src/providers/Web3CtxProvider';
import { router } from 'src/router';
import { AppChainProvider } from 'src/providers/AppChainProvider';
import { TransactionsToasts } from './components/transactionsToasts/TransactionsToasts';

const rootEl = document.getElementById('root') as HTMLElement;

ReactDOM.createRoot(rootEl).render(
  <React.StrictMode>
    <Web3CtxProvider>
      <ThemeProvider>
        <AppChainProvider>
          <RouterProvider router={router} />
          <TransactionsToasts />
        </AppChainProvider>
      </ThemeProvider>
    </Web3CtxProvider>
  </React.StrictMode>,
);
