import { Button } from '@mui/material';
import { shortenHash } from 'src/utils/ui';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { ChainSelect } from './ChainSelect';
import { ConnectBtn } from './ConnectBtn';
import { TransactionStatus } from './TransactionStatus';

export const HeaderElements = () => {
  const { userAddress } = useWeb3State();

  return (
    <>
      <ChainSelect />
      <TransactionStatus />
      {userAddress && (
        <Button variant="soft" color="inherit" disabled>
          {shortenHash(userAddress)}
        </Button>
      )}
      <ConnectBtn />
    </>
  );
};
