import { Stack, Typography } from '@mui/material';

export const RefreshBrowserNotice = ({ chainName }: { chainName: string }) => {
  return (
    <Stack
      sx={{
        width: '100%',
        background: (theme) => theme.palette.background.neutral,
      }}
    >
      <Typography
        sx={{
          textAlign: 'center',
          color: '#000',
          fontWeight: 'bold',
          fontSize: { md: '1rem', xs: '0.825rem' },
        }}
      >
        Note: Balances on {chainName} require a manual page refresh to display the latest
        information. Please refresh after each action.
      </Typography>
    </Stack>
  );
};
