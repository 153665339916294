import { Box, Grid, Stack, Typography } from '@mui/material';
import BigNumber from 'bignumber.js';
import debug from 'debug';
import { ethers } from 'ethers';
import { useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Iconify } from 'src/components/Iconify';
import { SliderConnectedWithInput } from 'src/components/SliderConnectedWithInput';
import { UiCard } from 'src/components/ui/UiCard';
import { UiExecuteButton } from 'src/components/ui/UiExecuteButton';
import { UiTextFieldWithMax } from 'src/components/ui/UiTextFieldWithMax';
import { MAX_UINT } from 'src/constants/eth';
import { useAllowance } from 'src/hooks/useAllowance';
import { useApprove } from 'src/hooks/useApprove';
import { useBTokenPrice } from 'src/hooks/useBTokenPrice';
import { useCheckProofOracles } from 'src/hooks/useCheckProofOracles';
import { AssetParam } from 'src/pages/[assetAddress]/components/AssetParam';
import { useAppChain } from 'src/providers/AppChainProvider';
import { useTokenBalance, useTokenBorrowedBalance } from 'src/providers/BalancesProvider';
import { useSetModal } from 'src/providers/ModalsProvider';
import { useTransactions } from 'src/providers/TransactionsProvider';
import { useSelectedPoolWalletSummary } from 'src/providers/WalletSummaryProvider';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { BToken } from 'src/types/asset';
import { BN, getAtomicAmount } from 'src/utils/bigNumber';
import { calculateAPY } from 'src/utils/token';
import { bTokenAbi, maximillonAbi } from '../../../abi';
import { WrapUnwrap } from '../components/WrapUnwrap';
import { RepayHelpMessage } from './RepayHelpMessage';

const log = debug('components:Repay');

export const Repay = () => {
  const { asset } = useOutletContext<{ asset: BToken }>();
  const [{ chainConfig }] = useAppChain();
  const { userAddress, walletProvider } = useWeb3State();
  const setModal = useSetModal();
  const borrowedBalance = useTokenBorrowedBalance(asset.address);
  const [tokenAllowance, reloadAllowance] = useAllowance(
    asset.underlyingAssetAddress,
    userAddress,
    asset.address,
  );
  const tokenApprove = useApprove(asset.underlyingAssetAddress, asset.address, 'approve');
  const { accountBorrowingTotalUsd, accountBorrowLimitTotalUsd } = useSelectedPoolWalletSummary();
  const price = useBTokenPrice(asset);
  const { balance: tokenBalance } = useTokenBalance(asset.wrapped.address);
  const checkProofOracles = useCheckProofOracles();
  const { trackTx, trackError } = useTransactions();

  const maxValue =
    tokenBalance && borrowedBalance
      ? BigNumber.minimum(tokenBalance.fullPrecision, borrowedBalance.fullPrecision).toString()
      : '0';

  const [amount, setAmount] = useState('0');

  const errorMessage = BN(amount).gt(maxValue) ? 'No Funds Available' : '';

  const borrowLimitUsed = BN(accountBorrowLimitTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd).div(accountBorrowLimitTotalUsd).times(100).toFixed(2)
    : 100;

  const featureBorrowLimit = BN(accountBorrowingTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd)
        .minus(BN(amount).times(price?.fullPrecision || '0'))
        .div(accountBorrowLimitTotalUsd)
        .times(100)
        .toFixed(2)
    : 0;

  const needApproval = BN(tokenAllowance).lt(
    getAtomicAmount(amount, Number(asset.wrapped.decimals)),
  );

  const approveToSpendTokens = () => {
    return tokenApprove(MAX_UINT).finally(reloadAllowance);
  };

  async function repay() {
    if (!walletProvider) return;

    await checkProofOracles();

    log('call repay', getAtomicAmount(amount, Number(asset.wrapped.decimals)));

    let tx;

    try {
      const repayValue = getAtomicAmount(amount, Number(asset.wrapped.decimals));
      const signer = await walletProvider.getSigner();

      setModal({ key: 'loader', title: 'Confirm your transaction in the wallet' });

      if (asset.wrapped.isEth) {
        const maximillonContract = new ethers.Contract(
          chainConfig.contracts.maximillion,
          maximillonAbi,
          signer,
        );
        tx = await maximillonContract.repayBehalf(userAddress, { value: repayValue });
      } else {
        const bTokenContract = new ethers.Contract(asset.address, bTokenAbi, signer);
        tx = await bTokenContract.repayBorrow(repayValue);
      }

      setModal({
        key: 'loader',
        title: `Repaying ${amount} ${asset.wrapped.symbol}...`,
        txHash: tx.txHash,
      });

      trackTx(tx);
      log('tx', tx);
      await tx.wait();
      setModal(null);

      log(`${repayValue} ${asset.wrapped.symbol} successfully repaid.`);
    } catch (err) {
      trackError(err, tx);
      setModal(null);
      console.error('Repay action failed:', err);
      throw err;
    }
  }

  const balances = useMemo(
    () => [
      {
        label: 'Balance',
        value: {
          raw: maxValue,
          fullPrecision: maxValue,
          formatted: BN(maxValue).toFixed(4),
        },
        symbol: asset.wrapped.symbol,
        clickable: true,
      },
    ],
    [asset.wrapped.symbol, maxValue],
  );

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ padding: { xs: 1, md: 3 } }}>
          <RepayHelpMessage />
        </Box>
        <WrapUnwrap label="Wrap for repay" isWrap />
      </Grid>
      <Grid item xs={12}>
        <UiCard>
          <Stack spacing={2}>
            <Typography variant="h4">Repay</Typography>
            <UiTextFieldWithMax
              balances={balances}
              maxValue={maxValue}
              onValueChange={setAmount}
              value={amount}
            />
            <Box sx={{ marginBottom: '1rem' }}>
              <SliderConnectedWithInput
                decimals={asset.wrapped.decimals}
                value={amount}
                maxValue={maxValue}
                setValue={setAmount}
              />
            </Box>
            <Box>
              <Stack direction={'column'} flexWrap={'wrap'}>
                <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
                  <AssetParam
                    name="Borrow APY"
                    value={`${calculateAPY(asset.borrowRatePerBlock, chainConfig.blocksPerDay)} %`}
                  />
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
                  <AssetParam
                    name="Borrow Limit Used"
                    value={
                      <Stack direction="row" alignItems="center">
                        {borrowLimitUsed} %
                        {BN(accountBorrowLimitTotalUsd).gt(0) && (
                          <>
                            <Iconify
                              icon="material-symbols:arrow-forward-rounded"
                              width={24}
                              sx={{ mx: 2 }}
                            />
                            {featureBorrowLimit} %
                          </>
                        )}
                      </Stack>
                    }
                  />
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
                  <AssetParam
                    name="Currently Borrowing"
                    value={`${borrowedBalance ? borrowedBalance.formatted : '0'} ${
                      asset.wrapped.symbol
                    }`}
                  />
                </Stack>
              </Stack>
            </Box>
            <UiExecuteButton
              executeLabel={errorMessage || 'Repay'}
              onClick={needApproval ? approveToSpendTokens : repay}
              needApproval={needApproval}
              disabled={Boolean(errorMessage) || !BN(amount).gt(0)}
            />
          </Stack>
        </UiCard>
      </Grid>
    </>
  );
};
