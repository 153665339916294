import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { CHAIN_LIST } from '../../configs/chains.config';
import { isAddressesEq } from '../../utils/compareAddresses';
import { isAddress } from 'ethers';

export const LiquidationsRedirect = () => {
  const [searchParams] = useSearchParams({ unitroller: '', owner: '' });
  const navigate = useNavigate();

  useEffect(() => {
    const unitroller = searchParams.get('unitroller');
    const owner = searchParams.get('owner');

    if (!unitroller || !owner) return;
    if (!isAddress(unitroller) || !isAddress(owner)) return;

    let chainUrlRepresentation: string | null = null;
    let poolId: string | null = null;

    CHAIN_LIST.forEach((chainConf) => {
      if (chainUrlRepresentation && poolId) return;

      const liquidationPool = Object.values(chainConf.pools).find((pool) =>
        isAddressesEq(pool.contracts.unitroller, unitroller),
      );

      if (!liquidationPool) return;

      poolId = liquidationPool.id;
      chainUrlRepresentation = chainConf.urlRepresentation;
    });

    if (!chainUrlRepresentation || !poolId) {
      navigate('/', { replace: true });
      return;
    }

    navigate(
      `/${chainUrlRepresentation}/${poolId}/liquidations?${createSearchParams(searchParams)}`,
      { replace: true },
    );
  }, [searchParams]);

  const unitroller = searchParams.get('unitroller');
  const owner = searchParams.get('owner');

  if (!unitroller || !owner)
    return <h3>Owner address or unitroller contract address is not defined</h3>;

  if (!isAddress(unitroller) || !isAddress(owner))
    return <h3>Owner address or unitroller contract address is corrupted</h3>;

  return null;
};
