import { Tooltip } from '@mui/material';
import { Label } from './label/Label';

export const SupplyTooltip = () => {
  return (
    <Tooltip title="Supplying" placement="top">
      <Label color="secondary" variant="filled" />
    </Tooltip>
  );
};
