import { FC, ReactElement } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useSetModal } from 'src/providers/ModalsProvider';

export const InfoModal: FC<{ Title: ReactElement }> = ({ Title }) => {
  const setModal = useSetModal();

  return (
    <Dialog
      open={true}
      onClose={() => setModal(null)}
      maxWidth="xs"
      fullWidth
      sx={{
        '.MuiPaper-root': {
          alignItems: 'center',
        },
      }}
    >
      <DialogTitle textAlign="center" sx={{ paddingBottom: 0 }}>
        {Title}
      </DialogTitle>
      <DialogActions>
        <Button variant="soft" color="inherit" onClick={() => setModal(null)}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
