import { Stack, Typography } from '@mui/material';

export const SupplyHelpMessage = () => (
  <Stack
    sx={{
      padding: '1rem 1.5rem',
      background: (theme) => theme.palette.grey[300],
      borderRadius: '8px',
      mt: '20px',
    }}
  >
    <Typography mb="1rem">For added peace of mind, wrap before supplying.</Typography>
    <Typography>
      Wrapping provides an extra step of security for your assets before they're active in the
      protocol.{' '}
      <a
        href="https://docs.booster.foundation/universal-vault"
        target="_blank"
        rel="noreferrer"
        style={{ color: 'inherit' }}
      >
        Find out why this matters in our docs.
      </a>
    </Typography>
  </Stack>
);
