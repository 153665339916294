import { Grid, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { UiCard } from 'src/components/ui/UiCard';
import { UiParam } from 'src/components/ui/UiParam';
import tooltipsDic from 'src/configs/tooltips.json';
import { useBTokenPrice } from 'src/hooks/useBTokenPrice';
import { useAppChain } from 'src/providers/AppChainProvider';
import { useTokenBalance, useTokenBorrowedBalance } from 'src/providers/BalancesProvider';
import { BToken } from 'src/types/asset';
import { BN, getDisplayAmount, shortenNumber } from 'src/utils/bigNumber';
import { calcTokenBorrowLimit, calcTokenSupplying, calculateAPY } from 'src/utils/token';

export const Parameters = ({
  asset,
  underlyingAsset,
}: {
  asset: BToken;
  underlyingAsset?: BToken;
}) => {
  const [{ chainConfig }] = useAppChain();
  const bTokenPrice = useBTokenPrice(asset);
  const { balance } = useTokenBalance(asset.wrapped.underlying.address);
  const borrowedBalance = useTokenBorrowedBalance(asset.address);
  const { balance: tokenBalance } = useTokenBalance(asset?.address || '');

  const collateralFactor = `${BN(asset.collateralFactorMantissa.toString())
    .times(100)
    .div(1e18)
    .toString()} %`;

  const borrowLimit = calcTokenBorrowLimit(asset);

  const tokenSupplying = calcTokenSupplying(asset, tokenBalance.raw.toString());

  const oraclePrice = useMemo(() => {
    const bTokenPriceUsd = `$${bTokenPrice?.formatted || '0'}`;

    if (!underlyingAsset) return bTokenPriceUsd;

    const exchangeRate = BN(underlyingAsset.exchangeRateCurrent)
      .div(BN(10).pow(18 - Number(underlyingAsset.decimals)))
      .div(BN(10).pow(Number(underlyingAsset.wrapped.decimals)))
      .toFixed(4)
      .toString();

    return (
      <Stack
        direction="column"
        ml={{ xs: 'auto', md: 'unset' }}
        textAlign={{ xs: 'right', md: 'left' }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { xs: '1.2rem', sm: '1.375rem' },
            wordWrap: 'break-word',
            wordBreak: 'break-all',
          }}
        >
          {bTokenPriceUsd}
        </Typography>
        <Typography color={'text.secondary'} variant="subtitle2" lineHeight="0.8">
          {exchangeRate} {underlyingAsset.wrapped.underlying.symbol}
        </Typography>
      </Stack>
    );
  }, [asset.address, bTokenPrice, underlyingAsset]);

  return (
    <UiCard>
      <Grid container spacing={2}>
        {/*<Grid item xs={12} md={3}>*/}
        {/*  <UiParam name="Pool" value={poolName} />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12} md={3}>*/}
        {/*  <UiParam name="Asset" value={asset.wrapped.underlying.symbol} />*/}
        {/*</Grid>*/}
        <Grid item xs={12} md={3}>
          <UiParam
            name="Supplying"
            value={`${shortenNumber(
              getDisplayAmount(tokenSupplying, {
                decimals: Number(asset.wrapped.decimals),
              }),
            )} ${asset.wrapped.symbol}`}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <UiParam
            name="Borrowing"
            value={`${borrowedBalance ? borrowedBalance.formatted : '0'} ${asset.wrapped.symbol}`}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <UiParam name="Oracle Price" value={oraclePrice} />
        </Grid>
        <Grid item xs={12} md={3}>
          <UiParam
            name="Collateral Factor"
            value={collateralFactor}
            tooltipMessage={tooltipsDic.collateralFactor}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <UiParam
            name="Supply APY"
            value={`${calculateAPY(asset.supplyRatePerBlock, chainConfig.blocksPerDay)} %`}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <UiParam
            name="Borrow APY"
            value={`${calculateAPY(asset.borrowRatePerBlock, chainConfig.blocksPerDay)} %`}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <UiParam
            name="Borrow Limit"
            value={`${shortenNumber(borrowLimit.formatted)} ${asset.wrapped.underlying.symbol}`}
            tooltipMessage={tooltipsDic.borrowLimitUsed}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <UiParam
            name="Wallet balance"
            value={`${shortenNumber(balance.formatted)} ${asset.wrapped.underlying.symbol}`}
          />
        </Grid>
      </Grid>
    </UiCard>
  );
};
