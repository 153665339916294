import { Outlet } from 'react-router-dom';
import { AssetsProvider } from './AssetsProvider';
import { BalancesProvider } from './BalancesProvider';
import { BoosterOraclePricesProvider } from './BoosterOraclePricesProvider';
import { BoostTokenProvider } from './BoostTokenProvider';
import { WalletSummaryProvider } from './WalletSummaryProvider';
import { ModalsProvider } from './ModalsProvider';
import { Modals } from 'src/components/modals/Modals';
import { GnosisMedianOracleProvider } from './GnosisMedianOracleProvider';
import { RewardsProvider } from './RewardsProvider';
import { TransactionsProvider } from './TransactionsProvider';

export const RouterDependedProviders = () => {
  return (
    <BoostTokenProvider>
      <AssetsProvider>
        <BalancesProvider>
          <BoosterOraclePricesProvider>
            <GnosisMedianOracleProvider>
              <WalletSummaryProvider>
                <TransactionsProvider>
                  <ModalsProvider>
                    <RewardsProvider>
                      <Outlet />
                      <Modals />
                    </RewardsProvider>
                  </ModalsProvider>
                </TransactionsProvider>
              </WalletSummaryProvider>
            </GnosisMedianOracleProvider>
          </BoosterOraclePricesProvider>
        </BalancesProvider>
      </AssetsProvider>
    </BoostTokenProvider>
  );
};
