import { Stack, Typography } from '@mui/material';

export const BorrowHelpMessage = () => (
  <Stack
    sx={{
      padding: '1rem 1.5rem',
      background: (theme) => theme.palette.grey[300],
      borderRadius: '8px',
      mt: '20px',
    }}
  >
    <Typography mb="1rem">
      This protocol exclusively operates with wrapped assets for enhanced security. When you borrow
      assets, they'll be in this secure wrapped form. To access the underlying assets, simply unwrap
      them after borrowing.{' '}
      <a
        href="https://docs.booster.foundation/universal-vault"
        target="_blank"
        rel="noreferrer"
        style={{ color: 'inherit' }}
      >
        Read our Docs for details.
      </a>
    </Typography>
    <Typography>
      Note: The unwrapping process includes a mandatory waiting period, giving the protocol
      sufficient time to detect and respond to any unusual activity or potential exploitation.
    </Typography>
  </Stack>
);
