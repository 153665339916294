import React from 'react';
import { useEffect, useState } from 'react';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { Chip, CircularProgress } from '@mui/material';
import { useTransactions } from 'src/providers/TransactionsProvider';
import { TxStatus } from 'src/types/transactions';

export const TransactionStatus: React.FC = React.memo(() => {
  const { userAddress, walletProvider } = useWeb3State();
  const { transactions, getTransactionsFromStorage, resetTransactionsStore, trackTx } =
    useTransactions();
  const [amount, setAmount] = useState(0);

  const getPendingTx = () => {
    return userAddress
      ? Object.values(getTransactionsFromStorage()[userAddress] || {}).filter(
          (tx) => tx.status === TxStatus.Pending,
        )
      : [];
  };

  useEffect(() => {
    resetTransactionsStore();
    if (!userAddress) {
      setAmount(0);
      return;
    }

    const promises = getPendingTx().map(
      async (tx) => await walletProvider?.getTransaction(tx.hash),
    );
    Promise.all(promises)
      .then((transactions) => {
        transactions.forEach((res) => {
          if (res) {
            trackTx(res);
          }
        });
      })
      .catch((e: any) => {
        console.error('Failed to get Transaction:', e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAddress]);

  useEffect(() => {
    setAmount(getPendingTx().length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);

  return amount ? (
    <Chip
      icon={<CircularProgress size={'1.5rem'} />}
      label={`${amount} pending`}
      sx={{ position: { xs: 'absolute', sm: 'relative' }, top: { xs: '4.5rem', sm: 0 } }}
    />
  ) : null;
});
