import { StandardMerkleTree } from '@openzeppelin/merkle-tree';
import debug from 'debug';
import { ethers } from 'ethers';
import copy from 'fast-copy';
import { proofOracleAbi } from '../abi';
import { useAppChain } from '../providers/AppChainProvider';
import { useSelectedPoolAssets } from '../providers/AssetsProvider';
import { useBoosterPrices } from '../providers/BoosterOraclePricesProvider';
import { useGnosisMedianOracle } from '../providers/GnosisMedianOracleProvider';
import { useWeb3State } from '../providers/Web3CtxProvider';
import { BN } from '../utils/bigNumber';

const log = debug('hooks:useCheckProofOracles');

export const useCheckProofOracles = () => {
  const {
    prices,
    updateMetrics,
    metrics,
    merkleTreeQuoteMetrics,
    merkleWrightBlock,
    signetMerkleTree: medianSignetMerkleTree,
  } = useGnosisMedianOracle();
  const { walletProvider } = useWeb3State();
  const { assets } = useSelectedPoolAssets();
  const { prices: boosterOraclePrice } = useBoosterPrices();
  const [{ chainConfig }] = useAppChain();

  if (!chainConfig.contracts.proofOracle) return () => null;

  async function checkProofOracles() {
    await updateMetrics();

    if (!metrics) return;
    if (!medianSignetMerkleTree) return;
    if (!merkleTreeQuoteMetrics) return;
    if (!merkleWrightBlock) return;
    if (!walletProvider) return;

    const poolTokens = Object.values(assets).filter(
      (asset) => !asset.mintPaused && !asset.borrowPaused,
    );

    const pricesToUpdate: string[] = [];

    log('prices', prices);
    log('boosterOraclePrice', boosterOraclePrice);

    poolTokens.forEach((bToken) => {
      // if (!prices[address]) return;
      const address = bToken.address;
      const underlyingAddress = bToken.wrapped.underlying.address;
      if (BN(boosterOraclePrice?.[address]?.fullPrecision || '0').gt(0)) return;

      const metric = metrics.find((metric) =>
        metric[3].find((el) => el.includes(underlyingAddress)),
      );

      if (!metric) return;

      pricesToUpdate.push(metric[0]);
    });

    // if nothing to update exit
    if (pricesToUpdate.length === 0) {
      log('nothing to update');
      return;
    }

    log('pricesToUpdate', pricesToUpdate);

    const treeValues: [number, string, string, number][] = [];

    const epoch = medianSignetMerkleTree[0];
    const medianMerkleTreeRoot = medianSignetMerkleTree[4];

    merkleTreeQuoteMetrics.forEach((quoteMetric, i) => {
      const [{ priceQ112 }, ts] = quoteMetric;

      treeValues.push([Number(epoch), metrics[i][0], priceQ112.toString(), Number(ts)]);
    });

    const currentMerkleTree = StandardMerkleTree.of(treeValues, [
      'uint32',
      'string',
      'uint256',
      'uint32',
    ]);
    const currentMerkleTreeRoot = currentMerkleTree.root;

    log('medianMerkleTreeRoot', medianMerkleTreeRoot);
    log('currentMerkleTreeRoot', currentMerkleTreeRoot);

    if (currentMerkleTreeRoot !== medianMerkleTreeRoot) {
      console.warn('Merkle tree roots are not equal!');
      return;
    }

    const dataForSetValue: [string[], [string, string, number]][] = [];

    for (const [i, leaf] of currentMerkleTree.entries()) {
      log('i', i);
      log('leaf', leaf[1]);

      if (!pricesToUpdate.includes(leaf[1])) continue;

      const proof = currentMerkleTree.getProof(i);

      log('proof', proof);

      dataForSetValue.push([copy(proof), [copy(leaf)[1], copy(leaf)[2], copy(leaf)[3]]]);
    }

    log('proofsToWright', dataForSetValue);

    const signer = await walletProvider.getSigner();

    const proofOracleContract = new ethers.Contract(
      chainConfig.contracts.proofOracle,
      proofOracleAbi,
      signer,
    );

    log('dataForSetValue', dataForSetValue);

    await proofOracleContract.setValues(medianSignetMerkleTree.map(String), dataForSetValue);

    await updateMetrics();
  }

  return checkProofOracles;
};
