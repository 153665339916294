import { useMemo } from 'react';
import { useBalances } from '../providers/BalancesProvider';
import { useSelectedPoolAssets } from '../providers/AssetsProvider';
import { useBTokenPrice } from './useBTokenPrice';

export type BTokenSummary = ReturnType<typeof useBTokenSummary>;

export const useBTokenSummary = (address: string | null | undefined) => {
  const { assets } = useSelectedPoolAssets();
  const { balances } = useBalances();

  const bToken = useMemo(() => {
    if (!address) return null;
    if (Object.keys(assets).length === 0) return null;

    return assets[address] || null;
  }, [assets, address]);

  const price = useBTokenPrice(bToken);

  const balance = useMemo(() => {
    if (!balances) return null;
    if (!address) return null;

    return balances[address];
  }, [balances, address]);

  return bToken
    ? {
        price,
        balance,
        metadata: bToken,
      }
    : null;
};
