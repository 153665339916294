import { Paper, Stack, Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';

const TABS = [
  {
    name: 'Supply',
    link: 'supply',
  },
  {
    name: 'Borrow',
    link: 'borrow',
  },
  {
    name: 'Repay',
    link: 'repay',
  },
  {
    name: 'Withdraw',
    link: 'withdraw',
  },
] as const;

const styleOverrides = {
  container: ({ theme }: { theme: Theme }) => ({
    overflow: 'scroll',
    scrollbarHeight: 0,
    scrollbarWidth: 0,
    paddingBottom: theme.spacing(1),
    marginLeft: `-${theme.spacing(3)}`,
    width: `calc(100% + ${theme.spacing(6)})`,
    '&::-webkit-scrollbar': {
      height: 0,
      width: 0,
    },
    'a:first-of-type > *': {
      borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
    },
    'a:last-child > *': {
      borderTopRightRadius: Number(theme.shape.borderRadius) * 2,
    },
  }),
  paper: ({
    selected,
    disabled,
    theme,
  }: {
    selected: boolean;
    disabled?: boolean;
    theme: Theme;
  }) => ({
    background: selected ? theme.palette.grey[300] : theme.palette.background.neutral,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    minWidth: '8rem',
    height: '100%',
    display: 'grid',
    gridGap: '0.5rem',
    justifyItems: 'center',
    alignItems: 'center',
    p: 2,
    color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
    borderRadius: 0,
    '& svg': {
      width: 'auto',
      maxWidth: '100%',
      height: '3rem',
      fill: 'transparent',
    },
    '&:hover': {
      background: theme.palette.grey[300],
      border: 'none',
    },
  }),
};

export const AssetActionsTabs = () => {
  const theme = useTheme();
  const { pathname } = useLocation();

  return (
    <Stack direction="row" sx={styleOverrides.container({ theme })}>
      {TABS.map((tab) => (
        <Link
          key={tab.name}
          to={tab.link}
          style={{
            textDecoration: 'none',
            pointerEvents: 'auto',
            flexGrow: 1,
          }}
        >
          <Paper
            sx={styleOverrides.paper({
              selected: pathname.includes(tab.link),
              theme,
              disabled: pathname.includes(tab.link),
            })}
          >
            <Typography variant="subtitle1">{tab.name}</Typography>
          </Paper>
        </Link>
      ))}
    </Stack>
  );
};
