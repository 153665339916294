import { ethers } from 'ethers';
import { CHAIN_ID, CHAIN_URL_REPRESENTATION } from '../constants/chains';
import { POOL_CONFIGS } from './pools.config';

const DEFAULT_CHAIN = CHAIN_ID.ARBITRUM;
export const DEFAULT_CHAIN_URL = CHAIN_URL_REPRESENTATION[DEFAULT_CHAIN];
export const DEFAULT_POOL_URL = Object.keys(POOL_CONFIGS[DEFAULT_CHAIN])[0];

export type ChainConfig = (typeof CHAIN_LIST)[number];

export const CHAIN_LIST = [
  {
    id: CHAIN_ID.ARBITRUM,
    urlRepresentation: CHAIN_URL_REPRESENTATION[CHAIN_ID.ARBITRUM],
    name: 'Arbitrum',
    explorer: 'https://arbiscan.io/',
    wsProviderUrl:
      import.meta.env.MODE === 'development'
        ? 'wss://wiser-cool-dust.arbitrum-mainnet.discover.quiknode.pro/3aad02b8f6284baab69381acd8eb5d5934855f9b/'
        : 'wss://rpc.ankr.com/arbitrum/ws/1b5b228411a377e29f3590d64cc0d936b5bfda08351650ed6f482e6017693b98',
    rpcProviderUrl: 'https://arb1.arbitrum.io/rpc',
    paramsForAdding: {
      rpcProvider: 'https://arb1.arbitrum.io/rpc',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      hexId: ethers.toBeHex(CHAIN_ID.ARBITRUM),
    },
    pools: POOL_CONFIGS[CHAIN_ID.ARBITRUM],
    blocksPerDay: 86400 / 12,
    ethBalanceBlockReqDelay: 100,
    contracts: {
      vaultFactory: '0xb3f15c38861C288c81156B9Bab2f26E9870C4BBa',
      oracle: '0xcA032dD06766453a53804d017D12d1A93c17B9CD',
      compoundLens: '0xAF95D2185dEcfDbB826800Ef65e5a04AFc70b63c',
      maximillion: '0x6f6D0771d3d69B0643da3300d83B0767d03c1929',
      proofOracle: '0x205B97fD6c3C3b833dD0207E6cB0f85cdD3D62Ff',
      boost: '0xff692344F03d2FEeAa3307EABCdd541e1178f155',
      lensMini: '0xF841a3cA1d871d2C6e0129A6aF2604075cBaF44C',
    },
    tokenPriceFallbacks: {
      '0xaf88d065e77c8cc2239327c5edb3a432268e5831': '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    } as Record<string, string>,
    tokensSymbolsReplacement: {
      '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8': 'USDC.e',
    } as Record<string, string>,
  },
  {
    id: CHAIN_ID.X1TEST,
    urlRepresentation: CHAIN_URL_REPRESENTATION[CHAIN_ID.X1TEST],
    name: 'X1 Testnet',
    explorer: 'https://www.oklink.com/xlayer-test',
    wsProviderUrl:
      import.meta.env.MODE === 'development'
        ? 'wss://wiser-cool-dust.arbitrum-mainnet.discover.quiknode.pro/3aad02b8f6284baab69381acd8eb5d5934855f9b/'
        : 'wss://rpc.ankr.com/arbitrum/ws/1b5b228411a377e29f3590d64cc0d936b5bfda08351650ed6f482e6017693b98',
    rpcProviderUrl: 'https://x1-testnet.blockpi.network/v1/rpc/public',
    paramsForAdding: {
      rpcProvider: 'https://x1-testnet.blockpi.network/v1/rpc/public',
      nativeCurrency: {
        name: 'OKB',
        symbol: 'OKB',
        decimals: 18,
      },
      hexId: ethers.toBeHex(CHAIN_ID.X1TEST),
    },
    pools: POOL_CONFIGS[CHAIN_ID.X1TEST],
    blocksPerDay: 86400 / 12,
    ethBalanceBlockReqDelay: 100,
    contracts: {
      vaultFactory: '0x963902c3D15355B0B44d7Af4eee5d55a55169dD6',
      oracle: '0x455025AEA70749b5AC1FB3d6f792eEf0C0D764e7',
      compoundLens: '0x7fAb6f336123c8Fe73d2160D6835838134Eb2e8c',
      maximillion: '0x6f6D0771d3d69B0643da3300d83B0767d03c1929',
      lensMini: '0x4Bb56c904bB39Fd895539b3f88a39e5f30AD67a8',
      proofOracle: '',
    },
    tokensSymbolsReplacement: {} as Record<string, string>,
    tokenPriceFallbacks: {} as Record<string, string>,
  },
];
