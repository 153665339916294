import { Button, Grid, Stack, Typography } from '@mui/material';
import { UiParam } from 'src/components/ui/UiParam';
import { useSelectedPoolWalletSummary } from 'src/providers/WalletSummaryProvider';
import { BN } from 'src/utils/bigNumber';
import { useSetModal } from '../providers/ModalsProvider';
import { useRewards } from '../providers/RewardsProvider';
import { ClaimPoolRewardsModal } from './modals/ClaimPoolRewardsModal';
import { SwapRewardsModal } from './modals/SwapRewardsModal';

export const WalletStats = () => {
  const { allocated, tokenInfo, claim, swap } = useRewards();
  const { accountBorrowLimitTotalUsd, accountBorrowingTotalUsd, accountSupplyingTotalUsd } =
    useSelectedPoolWalletSummary();
  const setModal = useSetModal();

  function claimHandler() {
    setModal({
      key: 'confirm',
      title: 'Claim pool rewards',
      body: <ClaimPoolRewardsModal />,
      confirmText: 'Claim',
      onConfirm: claim,
      onClose: () => setModal(null),
    });
  }

  function swapHandler() {
    setModal({
      key: 'confirm',
      title: 'Swap pool rewards',
      body: <SwapRewardsModal />,
      confirmText: 'Swap',
      onConfirm: swap,
      onClose: () => setModal(null),
    });
  }

  return (
    <>
      <Grid item xs={12} md={3}>
        <UiParam
          name="Collateral Provided"
          value={`${Number(accountSupplyingTotalUsd).toFixed(2)}$`}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <UiParam name="Borrowed" value={`${Number(accountBorrowingTotalUsd).toFixed(2)}$`} />
      </Grid>
      <Grid item xs={12} md={3}>
        <UiParam name="Loan Limit" value={`${Number(accountBorrowLimitTotalUsd).toFixed(2)}$`} />
      </Grid>
      <Grid item xs={12} md={3}>
        <UiParam
          name="Points"
          value={
            <Stack direction="row" alignItems="center">
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: { xs: '1.2rem', sm: '1.375rem' },
                  wordWrap: 'break-word',
                  wordBreak: 'break-all',
                }}
              >
                {allocated.formatted}
              </Typography>
              <Button
                sx={{ ml: 2 }}
                size="small"
                variant="soft"
                color="inherit"
                disabled={!BN(allocated.raw).gt(0)}
                onClick={claimHandler}
              >
                Claim
              </Button>
            </Stack>
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <UiParam
          name="Rewards"
          value={
            <Stack direction="row" alignItems="center">
              <Typography
                variant="subtitle2"
                sx={{
                  fontSize: { xs: '1.2rem', sm: '1.375rem' },
                  wordWrap: 'break-word',
                  wordBreak: 'break-all',
                }}
              >
                {tokenInfo.balance.formatted}
              </Typography>
              <Button
                sx={{ ml: 2 }}
                size="small"
                variant="soft"
                color="inherit"
                disabled={BN(tokenInfo.balance.raw).lte(0)}
                onClick={swapHandler}
              >
                Swap
              </Button>
            </Stack>
          }
        />
      </Grid>
    </>
  );
};
