import debug from 'debug';
import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { DEFAULT_CHAIN_URL } from 'src/configs/chains.config';
import { getUpdatedAppChain, useAppChain } from 'src/providers/AppChainProvider';
import { isChainUrlSupported } from 'src/utils/chain';
import { ChainUrl } from '../constants/chains';

const log = debug('components:UrlChainManager');

export const UrlChainManager = () => {
  const { chainUrlRepresentation } = useParams() as { chainUrlRepresentation: ChainUrl };
  const [{ chainConfig }, setAppChain] = useAppChain();
  const navigate = useNavigate();

  useEffect(() => {
    // In order to be able to set a fallback chain when it is not set in URL,
    // the UrlChainManager must be outside the main /:chainUrlRepresentation route,
    // so we don't have access to the react-router params here.
    log(chainUrlRepresentation, !isChainUrlSupported(chainUrlRepresentation));
    if (!isChainUrlSupported(chainUrlRepresentation)) {
      // update url with chainUrlRepresentation value from store
      log('invalid chain name:', chainUrlRepresentation);
      log('redirectiong to:', `/${DEFAULT_CHAIN_URL}`);
      navigate(`/${DEFAULT_CHAIN_URL}`);
      return;
    }
    if (chainConfig.urlRepresentation !== chainUrlRepresentation) {
      // update AppChain store with chainUrl value from url
      log(
        `url chainUrlRepresentation updated: ${chainConfig.urlRepresentation} -> ${chainUrlRepresentation}`,
      );
      setAppChain(getUpdatedAppChain(chainUrlRepresentation));
    }
  }, [chainConfig, chainUrlRepresentation, navigate, setAppChain]);

  return <Outlet />;
};
