export const getEveryNth = <T>(arr: T[], nth: number, start = 0) =>
  arr.filter((e, i) => (i + start) % nth === nth - 1);

export const createMatrix = <T>(arr: T[], rowLength: number) => {
  const matrix = [];

  for (let i = 0; i < arr.length; i += rowLength) {
    const row = arr.slice(i, i + rowLength);
    matrix.push(row);
  }

  return matrix;
};
