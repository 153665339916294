import { Paper, Stack, Theme, Typography, useTheme } from '@mui/material';
import { NavLink, useParams } from 'react-router-dom';
import { usePools } from '../../../hooks/usePools';

const styleOverrides = {
  container: {
    overflow: 'scroll',
    scrollbarHeight: 0,
    scrollbarWidth: 0,
    paddingBottom: '1rem',
    '&::-webkit-scrollbar': {
      height: 0,
      width: 0,
    },
  },
  paper: ({
    selected,
    disabled,
    theme,
  }: {
    selected: boolean;
    disabled?: boolean;
    theme: Theme;
  }) => ({
    background: selected ? theme.palette.grey[300] : theme.palette.background.neutral,
    minWidth: '8rem',
    height: '100%',
    display: 'grid',
    gridGap: '0.5rem',
    justifyItems: 'center',
    alignItems: 'center',
    p: 2,
    color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
    '& img': {
      width: 'auto',
      maxWidth: '100%',
      height: '3rem',
      fill: 'transparent',
    },
    '&:hover': {
      background: theme.palette.grey[300],
      border: 'none',
    },
  }),
};

export const PoolSelector = () => {
  const theme = useTheme();
  const { poolsList } = usePools();
  const { chainUrlRepresentation, poolId } = useParams();

  return (
    <Stack direction="row" spacing={2} sx={styleOverrides.container}>
      {poolsList.map((pool) => (
        <NavLink
          key={pool.id}
          to={`/${chainUrlRepresentation}/${pool.id}`}
          style={({ isActive }) => ({
            textDecoration: 'none',
            pointerEvents: isActive ? 'none' : 'auto',
          })}
        >
          <Paper
            sx={styleOverrides.paper({
              selected: pool.id === poolId,
              theme,
            })}
          >
            {pool.icon ? <img src={pool.icon} alt={pool.id} /> : null}
            <Typography variant="caption">{pool?.name}</Typography>
          </Paper>
        </NavLink>
      ))}
    </Stack>
  );
};
