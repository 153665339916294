import { Grid } from '@mui/material';
import { PoolStats } from 'src/components/PoolStats';
import { UiCard } from 'src/components/ui/UiCard';
import { WalletStats } from 'src/components/WalletStats';
import { useChainStatus } from 'src/hooks/useChainStatus';
import { PoolSelector } from 'src/pages/home/components/PoolSelector';
import { PoolAssetsList } from './components/PoolAssetsList';

export const Home = () => {
  const { isWalletConnected } = useChainStatus();

  return (
    <>
      <PoolSelector />
      <UiCard sx={{ p: { xs: 3, md: 3 } }}>
        <Grid container spacing={2}>
          <PoolStats />
          {isWalletConnected && <WalletStats />}
        </Grid>
      </UiCard>
      <PoolAssetsList />
    </>
  );
};
