import debug from 'debug';
import { createContext, useContext, useEffect, useState } from 'react';
import { CHAIN_LIST } from 'src/configs/chains.config';
import { Pool, PoolId } from 'src/configs/pools.config';
import { usePools } from 'src/hooks/usePools';
import { useAppChain } from 'src/providers/AppChainProvider';
import { useBalances } from 'src/providers/BalancesProvider';
import { FCC } from 'src/types/FCC';
import { BN } from 'src/utils/bigNumber';
import { AssetPrice } from '../types/prices';
import { useSelectedPoolAssets } from './AssetsProvider';
import { useBoosterPrices } from './BoosterOraclePricesProvider';
import { useGnosisMedianOracle } from './GnosisMedianOracleProvider';
import { useRewards } from './RewardsProvider';
import { useWeb3State } from './Web3CtxProvider';

const log = debug('components:AccountsProvider');

type PoolWalletSummary = {
  accountSupplyingTotalUsd: string;
  accountBorrowingTotalUsd: string;
  accountBorrowLimitTotalUsd: string;
  accountDebtUsd: string;
  accountTotalRewards: string;
};

type WalletSummaryCtx = Record<PoolId, PoolWalletSummary>;

const initWalletSummary = {} as WalletSummaryCtx;

CHAIN_LIST.forEach((chain) => {
  Object.values(chain.pools).forEach(
    (pool: Pool) =>
      (initWalletSummary[pool.id] = {
        accountSupplyingTotalUsd: '0',
        accountBorrowingTotalUsd: '0',
        accountBorrowLimitTotalUsd: '0',
        accountDebtUsd: '0',
        accountTotalRewards: '0',
      }),
  );
});

const AccountsProviderCtx = createContext({ walletSummary: initWalletSummary });

export const WalletSummaryProvider: FCC = ({ children }) => {
  const [{ chainConfig }] = useAppChain();
  const { userAddress } = useWeb3State();
  const { selectedPoolConfig } = usePools();
  const { assets } = useSelectedPoolAssets();
  const { prices: gnosisPrices } = useGnosisMedianOracle();
  const { prices: boosterPrices } = useBoosterPrices();
  const { balances } = useBalances();
  const { allocated: accountTotalRewards } = useRewards();

  const [walletSummary, setWalletSummary] = useState(initWalletSummary);

  useEffect(() => {
    if (!userAddress) clearStats();
  }, [userAddress]);

  useEffect(() => {
    if (!selectedPoolConfig || Object.keys(assets).length === 0) return;

    if (!walletSummary[selectedPoolConfig.id]) updateWalletSummary();
  }, [selectedPoolConfig]);

  useEffect(() => {
    clearStats();
    updateWalletSummary();
  }, [chainConfig]);

  useEffect(() => {
    if (!gnosisPrices || !boosterPrices) return;

    updateWalletSummary();
  }, [balances, gnosisPrices, boosterPrices, assets, accountTotalRewards]);

  function updateWalletSummary() {
    const { supplyingInUsd, borrowLimitInUsd } = calcTotalsBorrowLimitAndSupplying();
    const borrowingInUsd = calcBorrowingUsd();

    const accountDebtUsd = BN(borrowingInUsd).minus(borrowLimitInUsd);

    const summary = {
      accountSupplyingTotalUsd: supplyingInUsd,
      accountBorrowingTotalUsd: borrowingInUsd,
      accountBorrowLimitTotalUsd: borrowLimitInUsd,
      accountDebtUsd: accountDebtUsd.gt(0) ? accountDebtUsd.toString() : '0',
      accountTotalRewards: accountTotalRewards.formatted,
    };

    log(`summary for pool ${selectedPoolConfig.id}`, summary);

    setPoolWalletSummary(selectedPoolConfig.id, summary);
  }

  function calcTotalsBorrowLimitAndSupplying() {
    let supplyingInUsd = '0';
    let borrowLimitInUsd = '0';

    if (!assets || !gnosisPrices || !boosterPrices) return { supplyingInUsd, borrowLimitInUsd };

    Object.values(assets).forEach((asset) => {
      const address = asset.address;

      let price: AssetPrice;

      if (boosterPrices[address] && BN(boosterPrices[address].fullPrecision).gt(0)) {
        price = boosterPrices[address];
      } else {
        price = gnosisPrices[address];
      }

      if (!price) return;

      const tokenSupplyingInUsd = balances?.[address]?.wallet
        ? BN(balances[address].wallet.raw.toString())
            .times(asset.exchangeRateCurrent.toString())
            .times(price.fullPrecision)
            .div(BN(10).pow(18 - Number(asset.decimals)))
            .div(BN(10).pow(Number(asset.wrapped.decimals)))
            .div(BN(10).pow(Number(asset.decimals)))
        : BN(0);

      supplyingInUsd = tokenSupplyingInUsd.plus(supplyingInUsd).toString();

      if (asset.inMarket) {
        borrowLimitInUsd = tokenSupplyingInUsd
          .times(asset.collateralFactorMantissa.toString())
          .div(1e18)
          .plus(borrowLimitInUsd)
          .toString();
      }
    });

    return { supplyingInUsd, borrowLimitInUsd };
  }

  function calcBorrowingUsd() {
    let borrowing = '0';

    if (!assets || !gnosisPrices || !boosterPrices) return borrowing;

    Object.values(assets).forEach((asset) => {
      const address = asset.address;
      let price: AssetPrice;

      if (boosterPrices[address] && BN(boosterPrices[address].fullPrecision).gt(0)) {
        price = boosterPrices[address];
      } else {
        price = gnosisPrices[address];
      }

      const balance = balances[address];

      if (!price || BN(balance?.borrowing.fullPrecision).isZero()) return;

      borrowing = BN(borrowing)
        .plus(
          BN((balance?.borrowing.raw || 0).toString())
            .times(price.fullPrecision)
            .div(BN(10).pow(Number(assets[address].wrapped.decimals))),
        )
        .toString();
    });

    return borrowing;
  }

  function clearStats() {
    setWalletSummary({} as WalletSummaryCtx);
  }

  function setPoolWalletSummary(poolId: PoolId, summary: PoolWalletSummary) {
    setWalletSummary((prev) => ({
      ...prev,
      [poolId]: summary,
    }));
  }

  return (
    <AccountsProviderCtx.Provider value={{ walletSummary }}>
      {children}
    </AccountsProviderCtx.Provider>
  );
};

export const useWalletSummary = () => useContext(AccountsProviderCtx);

export const useSelectedPoolWalletSummary = () => {
  const { walletSummary } = useWalletSummary();
  const { selectedPoolConfig } = usePools();

  return walletSummary[selectedPoolConfig.id];
};
