import { Box, Typography } from '@mui/material';
import { useRewards } from 'src/providers/RewardsProvider';

export const ClaimPoolRewardsModal = () => {
  const { allocated, tokenInfo } = useRewards();
  return (
    <Box>
      <Typography>Amount to claim:</Typography>
      <Typography variant="h6">
        {allocated.formatted} {tokenInfo.symbol}
      </Typography>
    </Box>
  );
};
