import debug from 'debug';
import { ethers } from 'ethers';
import copy from 'fast-copy';
import { createContext, useContext, useEffect, useState } from 'react';
import { FCC } from 'src/types/FCC';
import { compoundLensAbi, erc20Abi, rewardsAbi, unitrollerAbi } from '../abi';
import { PoolId } from '../configs/pools.config';
import { ZERO_ADDRESS } from '../constants/eth';
import { useImmutableCallback } from '../hooks/useActualRef';
import { usePools } from '../hooks/usePools';
import { Asset } from '../types/asset';
import { createMatrix } from '../utils/arrayHelpers';
import { getDisplayAmount } from '../utils/bigNumber';
import { fetchTokensInfoRaw } from '../utils/token';
import { useAppChain } from './AppChainProvider';
import { useSetModal } from './ModalsProvider';
import { useTransactions } from './TransactionsProvider';
import { useWeb3State } from './Web3CtxProvider';

const log = debug('providers:RewardsProvider');

export type SwapToken = Asset & {
  contractBalance: {
    raw: string;
    formatted: string;
    fullPrecision: string;
  };
};

const INITIAL_TOKEN_INFO = {
  name: 'N/A',
  symbol: 'N/A',
  decimals: 18,
  balance: {
    raw: '0',
    formatted: '0',
    fullPrecision: '0',
  },
  totalSupply: '0',
};

const INITIAL_REWARDS_STATE = {
  balance: '0',
  votes: '0',
  delegate: ZERO_ADDRESS,
  allocated: {
    raw: '0',
    fullPrecision: '0',
    formatted: '0',
  },
};

const RewardsInitContext = {
  tokenInfo: INITIAL_TOKEN_INFO,
  ...INITIAL_REWARDS_STATE,
  swapTokens: {} as Record<PoolId, SwapToken[]> | undefined,
  claim: () => {},
  swap: () => {},
};

const RewardCtx = createContext(RewardsInitContext);

let contractsSubEvents: ethers.Contract[] = [];

export const RewardsProvider: FCC = ({ children }) => {
  const [{ appWsProvider, appRpcProvider, chainConfig }] = useAppChain();
  const { selectedPoolConfig } = usePools();
  const { userAddress } = useWeb3State();
  const setModal = useSetModal();
  const { walletProvider } = useWeb3State();
  const { trackTx, trackError } = useTransactions();

  const [rewardState, setRewardState] = useState(INITIAL_REWARDS_STATE);
  const [tokenInfo, setTokenInfo] = useState(INITIAL_TOKEN_INFO);
  const [swapTokens, setSwapTokens] = useState<Record<PoolId, SwapToken[]>>();

  useEffect(() => {
    getRewardTokenInfo();
  }, []);

  useEffect(() => {
    clearTokensTransfers();
  }, [chainConfig]);

  useEffect(() => {
    if (!userAddress) return;
    if (!appRpcProvider) return;
    if (!selectedPoolConfig) return;

    getAddressRewardsInfo();
  }, [appRpcProvider, chainConfig, selectedPoolConfig, userAddress]);

  useEffect(() => {
    getRewardTokenInfo();
  }, [userAddress]);

  useEffect(() => {
    if (!selectedPoolConfig) return;

    getRewardTokenInfo();
    getSwapTokensInfo();
  }, [selectedPoolConfig]);

  async function getAddressRewardsInfo() {
    const compoundLensContract = new ethers.Contract(
      chainConfig.contracts.compoundLens,
      compoundLensAbi,
      appRpcProvider,
    );

    const info: [bigint, bigint, string, bigint] =
      await compoundLensContract.getCompBalanceMetadataExt(
        selectedPoolConfig.contracts.rewards,
        selectedPoolConfig.contracts.unitroller,
        userAddress,
      );

    const allocated = String(info[3]);
    const decimals = 18;

    setRewardState({
      balance: String(info[0]),
      votes: String(info[1]),
      delegate: info[2],
      allocated: {
        raw: allocated,
        fullPrecision: getDisplayAmount(allocated, { decimals, cut: false }),
        formatted: getDisplayAmount(allocated, { decimals }),
      },
    });
  }

  async function getRewardTokenInfo() {
    if (!selectedPoolConfig)
      return {
        name: 'N/A',
        symbol: 'N/A',
        decimals: 18,
      };

    const tokenInfoRaw = await fetchTokensInfoRaw(
      appRpcProvider,
      [selectedPoolConfig.contracts.rewards],
      userAddress,
      true,
    );

    const balance =
      userAddress && tokenInfoRaw[3]
        ? {
            raw: String(tokenInfoRaw[3]),
            formatted: getDisplayAmount(String(tokenInfoRaw[3]), { decimals: 18 }),
            fullPrecision: getDisplayAmount(String(tokenInfoRaw[3]), { decimals: 18, cut: false }),
          }
        : INITIAL_TOKEN_INFO.balance;

    setTokenInfo({
      name: tokenInfoRaw[0],
      symbol: tokenInfoRaw[1],
      decimals: tokenInfoRaw[2],
      balance,
      totalSupply: String(tokenInfoRaw[4]),
    });

    startTrackRewardsBalance();
  }

  function startTrackRewardsBalance() {
    const isTokenHaveSub = contractsSubEvents.some(
      (contract) =>
        String(contract.target).toLowerCase() ===
        selectedPoolConfig.contracts.rewards.toLowerCase(),
    );

    if (!isTokenHaveSub) {
      const contract = new ethers.Contract(
        selectedPoolConfig.contracts.rewards,
        erc20Abi,
        appWsProvider,
      );
      contract.on('Transfer', handleTokenTransfer);
      contractsSubEvents.push();
    }
  }

  const handleTokenTransfer = useImmutableCallback((from: string, to: string) => {
    if (
      userAddress?.toLowerCase() === to?.toLowerCase() ||
      userAddress?.toLowerCase() === from?.toLowerCase()
    ) {
      log('handleTokenTransfer');
      log('userAddress: ', userAddress);
      log('from: ', from);
      log('to: ', to);
      updateTokenBalance();
      getAddressRewardsInfo();
    }
  });

  async function updateTokenBalance() {
    log('updateTokenBalance start');
    const contract = new ethers.Contract(
      selectedPoolConfig.contracts.rewards,
      erc20Abi,
      appRpcProvider,
    );
    const balance = await contract.balanceOf(userAddress);

    log('updateTokenBalance set balance', balance);
    setTokenInfo((prev) => ({
      ...prev,
      balance: {
        raw: String(balance),
        formatted: getDisplayAmount(String(balance), { decimals: 18 }),
        fullPrecision: getDisplayAmount(String(balance), { decimals: 18, cut: false }),
      },
    }));
  }

  function clearTokensTransfers() {
    contractsSubEvents.forEach((contract) => {
      contract.removeAllListeners('Transfer');
    });
    contractsSubEvents = [];
  }

  async function getSwapTokensInfo() {
    if (!selectedPoolConfig) return;

    const addresses = selectedPoolConfig.claimRewardsTokens.map(String);

    const tokensInfoRaw = await fetchTokensInfoRaw(
      appRpcProvider,
      addresses,
      selectedPoolConfig.contracts.rewards,
    );

    const tokensInfoSplitted = createMatrix(tokensInfoRaw, 4);

    const tokensInfoFormatted = tokensInfoSplitted.map((token, i) => {
      const replacedSymbol =
        chainConfig.tokensSymbolsReplacement[addresses[i].toLowerCase()] ||
        chainConfig.tokensSymbolsReplacement[addresses[i]];

      return {
        name: token[0],
        symbol: replacedSymbol || token[1],
        decimals: Number(token[2]),
        contractBalance: {
          raw: String(token[3]),
          fullPrecision: getDisplayAmount(String(token[3]), {
            decimals: Number(token[2]),
            cut: false,
          }),
          formatted: getDisplayAmount(String(token[3]), { decimals: Number(token[2]) }),
        },
        address: addresses[i].toLowerCase(),
      } as SwapToken;
    });

    const tokens = swapTokens ? copy(swapTokens) : { [selectedPoolConfig.id]: [] };

    if (!tokens[selectedPoolConfig.id]) tokens[selectedPoolConfig.id] = [];

    tokensInfoFormatted.forEach((token) => {
      if (tokens[selectedPoolConfig.id].find((el) => el.address === token.address)) return;

      startTrackContractSwapTokenBalance(token);
      tokens[selectedPoolConfig.id].push(token);
    });

    setSwapTokens(tokens);
  }

  function startTrackContractSwapTokenBalance(token: SwapToken) {
    const isTokenHaveSub = contractsSubEvents.some(
      (contract) =>
        String(contract.target).toLowerCase() ===
        selectedPoolConfig.contracts.rewards.toLowerCase(),
    );

    if (!isTokenHaveSub) {
      const contract = new ethers.Contract(token.address, erc20Abi, appWsProvider);
      contract.on('Transfer', handleSwapTokenTransfer(token));
      contractsSubEvents.push();
    }
  }

  const handleSwapTokenTransfer = useImmutableCallback(
    (token: SwapToken) => (from: string, to: string) => {
      if (
        selectedPoolConfig?.contracts.rewards.toLowerCase() === to?.toLowerCase() ||
        selectedPoolConfig?.contracts.rewards.toLowerCase() === from?.toLowerCase()
      ) {
        log('handleTokenTransfer');
        log('userAddress: ', userAddress);
        log('from: ', from);
        log('to: ', to);
        updateSwapTokenBalance(token);
      }
    },
  );

  async function updateSwapTokenBalance(token: SwapToken) {
    log('updateSwapTokenBalance start');
    const contract = new ethers.Contract(token.address, erc20Abi, appRpcProvider);
    const balance = await contract.balanceOf(selectedPoolConfig.contracts.rewards);

    log('updateSwapTokenBalance set balance', balance);
    setSwapTokens((prev) => {
      if (!prev) return;

      const poolSwapTokens = copy(prev[selectedPoolConfig.id]);
      const swapTokenIndex = poolSwapTokens.findIndex((el) => el.address === token.address);

      if (swapTokenIndex === -1) return;

      poolSwapTokens[swapTokenIndex].contractBalance = {
        raw: String(balance),
        fullPrecision: getDisplayAmount(String(balance), { decimals: token.decimals, cut: false }),
        formatted: getDisplayAmount(String(balance), { decimals: token.decimals }),
      };

      return {
        ...prev,
        [selectedPoolConfig.id]: poolSwapTokens,
      };
    });
  }

  async function claim() {
    let tx;

    try {
      if (!walletProvider) return;
      if (!selectedPoolConfig) return;
      if (!userAddress) return;

      const signer = await walletProvider.getSigner();
      const contract = new ethers.Contract(
        selectedPoolConfig.contracts.unitroller,
        unitrollerAbi,
        signer,
      );

      setModal({ key: 'loader', title: 'Confirm your transaction in the wallet' });

      tx = await contract.claimComp(userAddress);

      trackTx(tx, () => {
        updateTokenBalance();
        getAddressRewardsInfo();
      });

      log('tx: ', tx);

      setModal({
        key: 'loader',
        title: 'Claiming rewards',
        txHash: tx.txHash,
      });

      await tx.wait();

      setModal(null);
    } catch (err) {
      trackError(err, tx);
      setModal(null);
      console.error('Claiming rewards action failed:', err);
      throw err;
    }
  }

  async function swap() {
    let tx;

    try {
      if (!walletProvider) return;
      if (!selectedPoolConfig) return;
      if (!userAddress) return;

      const signer = await walletProvider.getSigner();
      const contract = new ethers.Contract(
        selectedPoolConfig.contracts.rewards,
        rewardsAbi,
        signer,
      );

      setModal({ key: 'loader', title: 'Confirm your transaction in the wallet' });

      tx = await contract.claim(selectedPoolConfig.claimRewardsTokens);

      trackTx(tx, () => {
        updateTokenBalance();
        getAddressRewardsInfo();
      });

      log('tx: ', tx);

      setModal({
        key: 'loader',
        title: 'Swapping rewards',
        txHash: tx.txHash,
      });

      await tx.wait();

      setModal(null);
    } catch (err) {
      trackError(err, tx);
      setModal(null);
      console.error('Swapping rewards action failed:', err);
      throw err;
    }
  }

  return (
    <RewardCtx.Provider value={{ ...rewardState, tokenInfo, swapTokens, claim, swap }}>
      {children}
    </RewardCtx.Provider>
  );
};

export const useRewards = () => useContext(RewardCtx);
