import { ValueOf } from 'src/types/objectHelpers';

export type ChainUrl = ValueOf<typeof CHAIN_URL_REPRESENTATION>;

export type ChainId = ValueOf<typeof CHAIN_ID>;

export const CHAIN_ID = {
  ARBITRUM: 42161,
  X1TEST: 195,
} as const;

export const CHAIN_URL_REPRESENTATION = {
  [CHAIN_ID.ARBITRUM]: 'arbitrum',
  [CHAIN_ID.X1TEST]: 'x1test',
} as const;

export const CHAIN_WITH_BROWSER_REFRESH_NOTICE = CHAIN_ID.X1TEST;

export const MEDIAN_FEEDS_CHAIN_RPC = 'https://rpc.chunk.limo/';
export const MEDIAN_FEEDS_ADDRESS = '0x205B97fD6c3C3b833dD0207E6cB0f85cdD3D62Ff';
