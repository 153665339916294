import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';

import erc20Abi from 'src/abi/erc20.json';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { MAX_UINT } from 'src/constants/eth';
import { useSetModal } from 'src/providers/ModalsProvider';

type UseApprove = (
  tokenAddress: string | null,
  spender: string,
  modalText?: string,
) => (value: BigNumber.Value) => Promise<void>;

export const useApprove: UseApprove = (tokenAddress, spender, modalText) => {
  const { walletProvider } = useWeb3State();
  const setModal = useSetModal();

  return async (value: BigNumber.Value = MAX_UINT) => {
    if (!tokenAddress || !walletProvider) return;

    const contract = new ethers.Contract(tokenAddress, erc20Abi, await walletProvider.getSigner());

    try {
      setModal({ key: 'loader', title: 'Confirm your transaction in the wallet' });

      const tx: ethers.ContractTransactionResponse = await contract.approve(spender, value);

      setModal({
        key: 'loader',
        title: modalText ? String(modalText) : 'loading',
        txHash: tx.hash,
      });

      await tx.wait();

      setModal(null);
    } catch (err: any) {
      setModal(null);
      console.error('useApprove failed', err);
      throw err;
    }
  };
};
