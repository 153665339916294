import { BrowserProvider, ethers } from 'ethers';
import Web3Modal from 'web3modal';
import debug from 'debug';
import { EthereumProviderOptions } from '@walletconnect/ethereum-provider/dist/types/EthereumProvider';
import { EthereumProvider } from '@walletconnect/ethereum-provider';

import { isWalletLocked } from 'src/utils/wallet';
import { EventEmitter } from 'src/utils/EventEmitter';
import { NotNullValues } from 'src/types/objectHelpers';
import { CHAIN_LIST } from 'src/configs/chains.config';

const log = debug('utils:web3Controller');

const ethereumProviderOptions: EthereumProviderOptions = {
  projectId: '0f23acfb130ecdad88eae4f65292c9d8',
  chains: CHAIN_LIST.map((network) => network.id) as [number],
  showQrModal: true,
  qrModalOptions: {
    explorerExcludedWalletIds: 'ALL',
  },
  events: ['connect', 'disconnect', 'chainChanged', 'accountsChanged'],
  methods: [
    'eth_sendTransaction',
    'eth_signTransaction',
    'eth_sign',
    'personal_sign',
    'eth_signTypedData',
  ],
};

export type Web3ControllerState = {
  walletProvider: null | BrowserProvider;
  userAddress: null | string;
  isConnecting: boolean;
  chainId: null | number;
  version: number;
};

export type ConnectedWeb3ControllerState = NotNullValues<Web3ControllerState>;

export type Web3Controller = ReturnType<typeof getWeb3Controller>;

/**
 * This controller is responsible for connecting/disconnecting wallet account,
 * storing active account address and (un)subscribing to wallet events.
 */
export const getWeb3Controller = (initialState: Web3ControllerState, web3Modal: Web3Modal) => {
  let state = initialState;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let unsubscribe = () => {};
  let walletconnectProvider: Awaited<ReturnType<typeof EthereumProvider.init>>;

  const emitter = new EventEmitter<Web3ControllerState>();

  const getState = () => state;
  const setState = (newState: Partial<Web3ControllerState>) => {
    state = { ...state, ...newState, version: state.version + 1 };
    emitter.emit(state);
  };

  const init = async () => {
    walletconnectProvider = await EthereumProvider.init(ethereumProviderOptions);

    if (walletconnectProvider && walletconnectProvider.session) {
      return connect('walletconnect');
    }

    if (web3Modal.cachedProvider && !isWalletLocked()) {
      return connect();
    }
  };

  const connect = async (walletId?: string) => {
    try {
      log('connect start');
      setState({ isConnecting: true });

      let newWalletProvider: BrowserProvider;

      if (walletId === 'walletconnect') {
        if (!walletconnectProvider.session) await walletconnectProvider.connect();
        newWalletProvider = new ethers.BrowserProvider(walletconnectProvider);
      } else {
        const instance = await (walletId ? web3Modal.connectTo(walletId) : web3Modal.connect());
        newWalletProvider = new ethers.BrowserProvider(instance);
      }

      const [accounts, network] = await Promise.all([
        newWalletProvider.listAccounts(),
        newWalletProvider.getNetwork(),
      ]);
      log('connect', accounts, network);

      subscribe();
      setState({
        isConnecting: false,
        chainId: Number(network.chainId),
        userAddress: accounts[0].address || '',
        walletProvider: newWalletProvider,
      });
    } catch (err) {
      console.error('components:Web3CtxProvider caught error:', err);
      setState({ isConnecting: false });
      disconnect();
    }
  };

  const subscribe = () => {
    log('subscribe ', window.ethereum);

    unsubscribe();

    const onChainChange = (chainId: string) => {
      log('chainChanged', chainId);
      setState({ chainId: Number(chainId) });
    };

    const onAccountsChange = (accounts: string[]) => {
      log('accountChanged', accounts);
      if (accounts.length > 0) {
        setState({ userAddress: accounts[0] });
      } else {
        disconnect();
      }
    };

    if (walletconnectProvider && walletconnectProvider.session) {
      walletconnectProvider.on('disconnect', disconnect);
      walletconnectProvider.on('accountsChanged', onAccountsChange);
      walletconnectProvider.on('chainChanged', onChainChange);
    } else {
      window.ethereum.on('close', disconnect);
      window.ethereum.on('disconnect', disconnect);
      window.ethereum.on('accountsChanged', onAccountsChange);
      window.ethereum.on('chainChanged', onChainChange);
    }

    unsubscribe = () => {
      log('unsubscribe');

      if (walletconnectProvider && walletconnectProvider.session) {
        walletconnectProvider.removeListener('disconnect', disconnect);
        walletconnectProvider.removeListener('accountsChanged', onAccountsChange);
        walletconnectProvider.removeListener('chainChanged', onChainChange);
      } else {
        window.ethereum.removeListener('close', disconnect);
        window.ethereum.removeListener('disconnect', disconnect);
        window.ethereum.removeListener('accountsChanged', onAccountsChange);
        window.ethereum.removeListener('chainChanged', onChainChange);
      }
    };
  };

  const disconnect = (error?: any) => {
    // @DEV: see https://github.com/MetaMask/metamask-extension/issues/13375
    // 1013 indicates that MetaMask is attempting to reestablish the connection
    // https://github.com/MetaMask/providers/releases/tag/v8.0.0
    if (error?.code === 1013) {
      console.warn('MetaMask logged connection error 1013: "Try again later"');
      return;
    }
    log('disconnect');

    unsubscribe();
    web3Modal.clearCachedProvider();
    if (walletconnectProvider && walletconnectProvider.session) {
      walletconnectProvider.disconnect();
    }
    // may work only in walletconnect
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.ethereum.provider?.close?.();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.ethereum.provider?.disconnect?.();
    setState({ ...initialState, userAddress: '' });
  };

  return {
    getState,
    init,
    connect,
    disconnect,
    onUpdate: emitter.listen,
  };
};
