import { useState } from 'react';
import { AppBar, Button, Stack, Toolbar, useTheme } from '@mui/material';
import { Logo } from 'src/components/Logo';
import { HEADER } from 'src/theme/layout-constants';
import { bgBlur } from 'src/utils/cssStyles';
import { NavHorizontal } from '../nav/NavHorizontal/NavHorizontal';
import NavVertical from '../nav/NavVertical';
import { Iconify } from '../Iconify';
import { useResponsive } from 'src/hooks/useResponsive';
import { RefreshBrowserNotice } from '../RefreshBrowserNotice';
import { useAppChain } from 'src/providers/AppChainProvider';
import { CHAIN_WITH_BROWSER_REFRESH_NOTICE } from 'src/constants/chains';

export const Header = () => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');
  const [{ chainConfig }] = useAppChain();

  const [open, setOpen] = useState(false);

  const isBrowserRefreshNoticeNeeded = chainConfig.id === CHAIN_WITH_BROWSER_REFRESH_NOTICE;

  return (
    <AppBar
      sx={{
        padding: isBrowserRefreshNoticeNeeded ? '0' : '1rem 0',
        boxShadow: 'none',
        minHeight: HEADER.H_MOBILE,
        justifyContent: 'center',
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
      }}
    >
      {isBrowserRefreshNoticeNeeded && <RefreshBrowserNotice chainName={chainConfig.name} />}
      <Toolbar
        sx={{
          px: { lg: 4 },
        }}
      >
        <Stack
          flexGrow={1}
          direction="row"
          alignItems="center"
          justifyContent={{ xs: 'space-between', md: 'center' }}
          spacing={1.5}
        >
          <Logo />
          {isDesktop ? (
            <Stack
              direction="row"
              flexGrow={1}
              justifyContent="flex-end"
              width={'100%'}
              alignItems="center"
              spacing={{ xs: 0.5, md: 1.5 }}
            >
              <NavHorizontal />
            </Stack>
          ) : (
            <>
              <NavVertical openNav={open} onCloseNav={() => setOpen(false)} />
              <Button sx={{ marginLeft: 'auto' }} onClick={() => setOpen(true)}>
                <Iconify
                  icon="iconamoon:menu-burger-horizontal-bold"
                  color={'#000'}
                  width={'2rem'}
                />
              </Button>
            </>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
