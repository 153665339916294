import { Stack, Typography } from '@mui/material';
import { terms } from './terms';

export const TermsPage = () => {
  const { title, paragraphs } = terms;
  return (
    <>
      <Typography variant="h2">{title}</Typography>
      <Stack direction="column" spacing={1} mt={4}>
        {paragraphs.map((paragraph, index) => (
          <Typography key={index} fontSize={'1.2rem'}>
            {paragraph}
          </Typography>
        ))}
      </Stack>
    </>
  );
};
