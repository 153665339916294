import { Box, styled } from '@mui/material';

export const StyledNetworkAttention = styled(Box)(({ theme }) => ({
  position: 'fixed',
  zIndex: '1000',
  background: theme.palette.common.black,
  color: theme.palette.common.white,
  bottom: '2.5rem',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '1rem 2rem',
  width: 'calc(100% - 5rem)',
  textAlign: 'center',
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
}));
