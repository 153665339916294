import { useAppChain } from 'src/providers/AppChainProvider';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { checkWeb3Network } from 'src/utils/chain';

export const useNetworkCheck = () => {
  const [chain] = useAppChain();
  return checkWeb3Network(useWeb3State(), chain);
};

export const useWrongNetworkLabel = (
  options = {
    /**
     * readOnly = true means that the write transaction aren't planned,
     * so the active chain in the wallet doesn't affect anything
     * and we can skip the corresponding checks.
     */
    readOnly: false,
  },
) => {
  const { isConnected, isSupported, networksMatch } = useNetworkCheck();
  if (!isConnected) return 'Wallet is not connected';
  if (options.readOnly) return null;
  if (!isSupported) return 'Unsupported network';
  if (!networksMatch) return 'Switch network in your wallet';
  return null;
};
