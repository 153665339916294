import { useBoosterPrices } from '../providers/BoosterOraclePricesProvider';
import { useGnosisMedianOracle } from '../providers/GnosisMedianOracleProvider';
import { BToken } from '../types/asset';
import { BN } from '../utils/bigNumber';

export const useBTokenPrice = (bToken: BToken | null | undefined) => {
  const { prices: gnosisPrices, loading: gnosisLoading } = useGnosisMedianOracle();
  const { prices: boosterPrices, loading: boosterLoading } = useBoosterPrices();

  if (!gnosisPrices) return null;
  if (!boosterPrices) return null;
  if (gnosisLoading || boosterLoading) return null;
  if (!bToken) return null;

  const { address } = bToken;

  if (boosterPrices[address] && BN(boosterPrices[address].fullPrecision).gt(0)) {
    return boosterPrices[address];
  }

  return gnosisPrices[address] || null;
};
