import React from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';

import { Link, Stack, useTheme } from '@mui/material';
import { Iconify } from 'src/components/Iconify';
import { CloseButton, IconContainer, ToastContainer } from './styled';

const CloseIcon = <Iconify icon="eva:close-fill" />;

export const TransactionsToasts: React.FC = () => {
  const theme = useTheme();
  function onCloseToast(id: string) {
    toast.dismiss(id);
  }

  return (
    <Toaster
      position="top-right"
      containerStyle={{
        top: '5rem',
      }}
      toastOptions={{
        duration: 5000,
        style: {
          maxWidth: 'unset',
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
          padding: '1rem',
          borderRadius: '1rem',
        },
        success: {
          icon: (
            <IconContainer>
              <Iconify
                icon="material-symbols:check-circle-rounded"
                color={theme.palette.success.main}
                width={'100%'}
                height={'100%'}
              />
            </IconContainer>
          ),
        },
        error: {
          icon: (
            <IconContainer>
              <Iconify
                icon="material-symbols:error-rounded"
                color={theme.palette.error.main}
                width={'100%'}
                height={'100%'}
              />
            </IconContainer>
          ),
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon }) => (
            <ToastContainer>
              <CloseButton onClick={() => onCloseToast(t.id)}>{CloseIcon}</CloseButton>
              {icon}
              <Stack direction="column" mr={5}>
                {t.message?.toString()}
                {t.id.includes('/') && (
                  <Link href={t.id} target="_blank" rel="noopener noreferrer">
                    View on explorer
                  </Link>
                )}
              </Stack>
            </ToastContainer>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};
