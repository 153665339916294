import debug from 'debug';
import { Call, Contract, Provider } from 'ethcall';
import { ethers } from 'ethers';
import copy from 'fast-copy';
import { createContext, useContext, useEffect, useState } from 'react';
import { ChainId } from 'src/constants/chains';
import { ZERO_ADDRESS } from 'src/constants/eth';
import { useImmutableCallback } from 'src/hooks/useActualRef';
import { createMatrix, getEveryNth } from 'src/utils/arrayHelpers';
import { compoundLensAbi, unitrollerAbi } from '../abi';
import { usePools } from '../hooks/usePools';
import { Asset, AssetProvider, BToken, BTokenMetadata, BTokenMetadataRaw } from '../types/asset';
import { FCC } from '../types/FCC';
import { isBETH } from '../utils/eth';
import { fetchTokensInfoRaw, fetchUnwrappedTokensAddresses } from '../utils/token';
import { useAppChain } from './AppChainProvider';
import { useWeb3State } from './Web3CtxProvider';

const log = debug('providers:AssetsProvider');

const assetsStructure = {} as AssetProvider;

const AssetsProviderCtx = createContext({ assets: assetsStructure, fetchingPoolAssets: true });

export const AssetsProvider: FCC = ({ children }) => {
  const { userAddress } = useWeb3State();
  const [{ appRpcProvider, appWsProvider, chainConfig }] = useAppChain();
  const { selectedPoolConfig } = usePools();

  const [fetchingPoolAssets, setFetchingPoolAssets] = useState(true);
  const [assets, setAssets] = useState(assetsStructure);
  const [assetsChainId, setAssetsChainId] = useState<ChainId | null>(null);

  useEffect(() => {
    log('lskdjfak', assets);
  }, [assets]);

  const marketEnteredListener = useImmutableCallback(
    (tokenAddress: string, walletAddress: string) => {
      if (!userAddress) return;
      if (!selectedPoolConfig) return;

      if (userAddress.toLowerCase() === walletAddress.toLowerCase()) {
        const copyAssets = copy(assets);
        copyAssets[selectedPoolConfig.id][tokenAddress.toLowerCase()].inMarket = true;
        log('setAssets', copyAssets);
        setAssets(copyAssets);
      }
    },
  );

  const marketExitedListener = useImmutableCallback(
    (tokenAddress: string, walletAddress: string) => {
      if (!userAddress) return;
      if (!selectedPoolConfig) return;

      if (userAddress.toLowerCase() === walletAddress.toLowerCase()) {
        const copyAssets = copy(assets);
        copyAssets[selectedPoolConfig.id][tokenAddress.toLowerCase()].inMarket = false;
        log('setAssets', copyAssets);
        setAssets(copyAssets);
      }
    },
  );

  useEffect(() => {
    fetchPoolAssetsInfo();
  }, [selectedPoolConfig]);

  useEffect(() => {
    if (!userAddress) setAssets((prevState) => clearInMarket(prevState));
  }, [userAddress]);

  useEffect(() => {
    if (!userAddress) return;
    if (fetchingPoolAssets) return;
    if (!selectedPoolConfig) return;
    if (!Object.keys(assets[selectedPoolConfig.id] || {}).length) return;

    const unitrollerContract = new ethers.Contract(
      selectedPoolConfig.contracts.unitroller,
      unitrollerAbi,
      appRpcProvider,
    );

    unitrollerContract.getAssetsIn(userAddress).then((resp: string[]) => {
      const assetsWithIn = copy(clearInMarket(assets));

      Object.keys(assetsWithIn[selectedPoolConfig.id]).forEach((asset) => {
        assetsWithIn[selectedPoolConfig.id][asset].inMarket = false;
      });

      resp.forEach((address) => {
        if (!assetsWithIn[selectedPoolConfig.id][address.toLowerCase()]) return;
        assetsWithIn[selectedPoolConfig.id][address.toLowerCase()].inMarket = true;
      });
      log('setAssets assetsWithIn', assetsWithIn);

      setAssets(assetsWithIn);
    });
  }, [userAddress, fetchingPoolAssets, selectedPoolConfig]);

  async function fetchPoolAssetsInfo() {
    const unitrollerContract = new ethers.Contract(
      selectedPoolConfig.contracts.unitroller,
      unitrollerAbi,
      appRpcProvider,
    );
    const selectedPool = selectedPoolConfig;

    if (!unitrollerContract) return;
    if (!selectedPool) return;

    if (Object.keys(assets[selectedPool.id] || {}).length && assetsChainId === chainConfig.id)
      return;

    log('fetching pool assets info');
    setAssetsChainId(chainConfig.id);

    const unitrollerWsContract = new ethers.Contract(
      selectedPool.contracts.unitroller,
      unitrollerAbi,
      appWsProvider,
    );

    unitrollerWsContract.on('MarketEntered', marketEnteredListener);
    unitrollerWsContract.on('MarketExited', marketExitedListener);

    setFetchingPoolAssets(true);

    const compoundLensContract = new ethers.Contract(
      chainConfig.contracts.compoundLens,
      compoundLensAbi,
      appRpcProvider,
    );

    let bETH: BToken | undefined;
    const allMarkets = (await unitrollerContract.getAllMarkets()) as string[];

    const unitrollerEthCallContract = new Contract(
      selectedPool.contracts.unitroller,
      unitrollerAbi,
    );

    const guardianPausedCalls: Call[] = [];

    allMarkets.forEach((market) => {
      guardianPausedCalls.push(unitrollerEthCallContract.mintGuardianPaused(market));
      guardianPausedCalls.push(unitrollerEthCallContract.borrowGuardianPaused(market));
    });

    const ethcallProvider = new Provider(chainConfig.id, appRpcProvider);

    const [marketsDataResp, guardianPausedResp] = await Promise.all([
      compoundLensContract.cTokenMetadataAll([...allMarkets]),
      ethcallProvider.all(guardianPausedCalls) as Promise<boolean[]>,
    ]);

    const splitedGuardianPausedCalls = createMatrix(guardianPausedResp, 2);

    const guardianPausedMap = {} as Record<string, boolean[]>;

    allMarkets.forEach((market, i) => {
      guardianPausedMap[market.toLowerCase()] = splitedGuardianPausedCalls[i];
    });

    const marketsMetadata: BTokenMetadata[] = marketsDataResp
      .map(processBTokenMetadata)
      .filter((bToken: BTokenMetadata) => {
        if (isBETH(selectedPool, bToken.address)) {
          bETH = {
            ...bToken,
            address: selectedPool.contracts.bETH.toLowerCase(),
            symbol: `bv${chainConfig.paramsForAdding.nativeCurrency.symbol}`,
            name: `Booster vaulted ${chainConfig.paramsForAdding.nativeCurrency.symbol}`,
            decimals: 8,
            isChainLink: false,
            wrapped: {
              address: selectedPool.contracts.vETH.toLowerCase(),
              symbol: `v${chainConfig.paramsForAdding.nativeCurrency.symbol}`,
              name: `Vaulted ${chainConfig.paramsForAdding.nativeCurrency.symbol}`,
              decimals: 18,
              underlying: {
                address: ZERO_ADDRESS,
                decimals: 18,
                name: chainConfig.paramsForAdding.nativeCurrency.name,
                symbol: chainConfig.paramsForAdding.nativeCurrency.symbol,
                isEth: true,
              },
            },
          };
          return false;
        }
        return true;
      });

    const unwrappedTokens = await fetchUnwrappedTokensAddresses(
      appRpcProvider,
      marketsMetadata.map((el) => el.underlyingAssetAddress),
    );
    const tokens = marketsMetadata
      .map((el, i) => [el.address, el.underlyingAssetAddress, unwrappedTokens[i]])
      .flat();

    const tokensInfoRaw = await fetchTokensInfoRaw(appRpcProvider, tokens);

    const tokensInfoSplitted = createMatrix(tokensInfoRaw, 3);

    const tokensInfoFormatted = tokensInfoSplitted.map((token, i) => {
      const replacedSymbol =
        chainConfig.tokensSymbolsReplacement[tokens[i].toLowerCase()] ||
        chainConfig.tokensSymbolsReplacement[tokens[i]];

      return {
        name: token[0],
        symbol: replacedSymbol || token[1],
        decimals: Number(token[2]),
        address: tokens[i].toLowerCase(),
      } as Asset;
    });

    const bTokens: Record<string, BToken> = {};
    const bTokensInfo = getEveryNth(tokensInfoFormatted, 3, 2);
    const wrappedTokensInfo = getEveryNth(tokensInfoFormatted, 3, 1);
    const underlyingTokensInfo = getEveryNth(tokensInfoFormatted, 3, 0);
    const { pricesFromChainLink } = selectedPool;

    marketsMetadata.forEach((metadata, i) => {
      const [mintPaused, borrowPaused] = guardianPausedMap[metadata.address];

      bTokens[metadata.address] = {
        ...metadata,
        ...bTokensInfo[i],
        mintPaused,
        borrowPaused,
        isChainLink: pricesFromChainLink.includes(metadata.address),
        wrapped: {
          ...wrappedTokensInfo[i],
          underlying: underlyingTokensInfo[i],
        },
      };
    });

    if (bETH) bTokens[bETH.address] = bETH;

    setAssets((prevState) => {
      const assets = { ...prevState, [selectedPool.id]: bTokens };
      log('setAssets', assets);
      log('prevState', prevState);
      return assets;
    });
    setFetchingPoolAssets(false);
  }

  function processBTokenMetadata(
    bToken: BTokenMetadataRaw,
  ): Omit<BTokenMetadata, 'borrowPaused' | 'mintPaused'> {
    return {
      address: bToken[0].toLowerCase(),
      borrowCap: bToken[16].toString(),
      borrowRatePerBlock: bToken[3].toString(),
      collateralFactorMantissa: bToken[10].toString(),
      boostBorrowSpeedPerBlock: bToken[15].toString(),
      boostSupplySpeedPerBlock: bToken[14].toString(),
      exchangeRateCurrent: bToken[1].toString(),
      isListed: bToken[9],
      reserveFactorMantissa: bToken[4].toString(),
      supplyRatePerBlock: bToken[2].toString(),
      totalBorrows: bToken[5].toString(),
      totalCash: bToken[8].toString(),
      totalReserves: bToken[6].toString(),
      totalSupply: bToken[7].toString(),
      underlyingAssetAddress: bToken[11].toLowerCase(),
      inMarket: false,
    };
  }

  function clearInMarket(assets: AssetProvider) {
    log('clearInMarket assets', assets);
    const tokens = {} as AssetProvider;
    log('clearInMarket tokens', assets);
    Object.keys(assets).forEach((pool) => {
      Object.keys(assets[pool]).forEach((asset) => {
        if (!tokens[pool]) tokens[pool] = {};
        tokens[pool][asset] = {
          ...assets[pool][asset],
          inMarket: false,
        };
      });
    });
    return tokens;
  }

  return (
    <AssetsProviderCtx.Provider value={{ assets, fetchingPoolAssets }}>
      {children}
    </AssetsProviderCtx.Provider>
  );
};

export const useAssets = () => useContext(AssetsProviderCtx);

export const useSelectedPoolAssets = () => {
  const { assets, fetchingPoolAssets } = useAssets();
  const { selectedPoolConfig } = usePools();

  return {
    assets: assets[selectedPoolConfig?.id] || {},
    fetchingPoolAssets,
  };
};
