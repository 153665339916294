import { Box, Grid, Stack, Typography } from '@mui/material';
import debug from 'debug';
import { ethers } from 'ethers';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Iconify } from 'src/components/Iconify';
import { SliderConnectedWithInput } from 'src/components/SliderConnectedWithInput';
import { UiCard } from 'src/components/ui/UiCard';
import { UiExecuteButton } from 'src/components/ui/UiExecuteButton';
import { UiTextFieldWithMax } from 'src/components/ui/UiTextFieldWithMax';
import { useBTokenPrice } from 'src/hooks/useBTokenPrice';
import { useCheckProofOracles } from 'src/hooks/useCheckProofOracles';
import { useMaxBorrow } from 'src/hooks/useMaxBorrow';
import { AssetParam } from 'src/pages/[assetAddress]/components/AssetParam';
import { useAppChain } from 'src/providers/AppChainProvider';
import { useTokenBorrowedBalance } from 'src/providers/BalancesProvider';
import { useSetModal } from 'src/providers/ModalsProvider';
import { useTransactions } from 'src/providers/TransactionsProvider';
import { useSelectedPoolWalletSummary } from 'src/providers/WalletSummaryProvider';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { BToken } from 'src/types/asset';
import { BN, getAtomicAmount } from 'src/utils/bigNumber';
import { calculateAPY } from 'src/utils/token';
import { bTokenAbi } from '../../../abi';
import { WrapUnwrap } from '../components/WrapUnwrap';
import { BorrowHelpMessage } from './BorrowHelpMessage';

const log = debug('components:Borrow');

export const Borrow = () => {
  const { asset } = useOutletContext<{ asset: BToken }>();
  const maxValue = useMaxBorrow(asset);
  const [{ chainConfig }] = useAppChain();
  const { walletProvider } = useWeb3State();
  const setModal = useSetModal();
  const borrowedBalance = useTokenBorrowedBalance(asset.address);
  const { accountBorrowingTotalUsd, accountBorrowLimitTotalUsd } = useSelectedPoolWalletSummary();
  const price = useBTokenPrice(asset);
  const checkProofOracles = useCheckProofOracles();
  const { trackTx, trackError } = useTransactions();

  const [amount, setAmount] = useState('0');

  const borrowLimitUsed = BN(accountBorrowLimitTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd).div(accountBorrowLimitTotalUsd).times(100).toFixed(2)
    : 100;

  const featureBorrowLimit = BN(accountBorrowingTotalUsd)
    .plus(BN(amount).times(price?.fullPrecision || '0'))
    .div(accountBorrowLimitTotalUsd)
    .times(100)
    .toFixed(2);

  const errorMessage = BN(amount).gt(maxValue) ? 'Borrowing Limit Reached' : '';

  async function borrow() {
    if (!walletProvider) return;

    await checkProofOracles();

    log('call borrow', getAtomicAmount(amount, Number(asset.wrapped.decimals)));

    setModal({ key: 'loader', title: 'Confirm your transaction in the wallet' });

    let tx;

    try {
      const valueWei = getAtomicAmount(amount, Number(asset.wrapped.decimals));
      const signer = await walletProvider.getSigner();
      const bTokenContract = new ethers.Contract(asset.address, bTokenAbi, signer);

      tx = await bTokenContract.borrow(valueWei);

      setModal({
        key: 'loader',
        title: `Borrowing ${amount} ${asset.wrapped.symbol}...`,
        txHash: tx.txHash,
      });

      log('tx', tx);
      trackTx(tx);
      await tx.wait();
      setModal(null);

      log(`${valueWei} ${asset.wrapped.symbol} successfully borrowed.`);
    } catch (err) {
      trackError(err, tx);
      setModal(null);
      console.error('Borrow action failed:', err);
      throw err;
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ padding: { xs: 1, md: 3 } }}>
          <BorrowHelpMessage />
        </Box>
        <UiCard>
          <Stack spacing={2}>
            <Typography variant="h4">Borrow</Typography>
            <UiTextFieldWithMax maxValue={maxValue} onValueChange={setAmount} value={amount} />
            <Box sx={{ marginBottom: '1rem' }}>
              <SliderConnectedWithInput
                decimals={asset.wrapped.decimals}
                value={amount}
                maxValue={maxValue}
                setValue={setAmount}
              />
            </Box>
            <Box>
              <Stack direction={'column'} flexWrap={'wrap'}>
                <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
                  <AssetParam
                    name="Borrow APY"
                    value={`${calculateAPY(asset.borrowRatePerBlock, chainConfig.blocksPerDay)} %`}
                  />
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
                  <AssetParam
                    name="Borrow Limit Used"
                    value={
                      <Stack direction="row" alignItems="center">
                        {borrowLimitUsed} %
                        {BN(accountBorrowLimitTotalUsd).gt(0) && (
                          <>
                            <Iconify
                              icon="material-symbols:arrow-forward-rounded"
                              width={24}
                              sx={{ mx: 2 }}
                            />
                            {featureBorrowLimit} %
                          </>
                        )}
                      </Stack>
                    }
                  />
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
                  <AssetParam
                    name="Currently Borrowing"
                    value={`${borrowedBalance ? borrowedBalance.formatted : '0'} ${
                      asset.wrapped.symbol
                    }`}
                  />
                </Stack>
              </Stack>
            </Box>
            <UiExecuteButton
              executeLabel={errorMessage || 'Borrow'}
              onClick={borrow}
              disabled={Boolean(errorMessage) || !BN(amount).gt(0)}
            />
          </Stack>
        </UiCard>
      </Grid>
      <Grid item xs={12}>
        <WrapUnwrap label="Unwrap" />
      </Grid>
    </>
  );
};
