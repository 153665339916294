import { ChangeEvent, FC } from 'react';
import { Button, OutlinedInput, Stack, StackProps, Typography } from '@mui/material';
import { AssetBalance } from 'src/types/asset';
import { BN } from '../../utils/bigNumber';
import { numberInputReg } from '../../utils/input';

type AssetBalanceWithLabel = {
  label: string;
  symbol: string;
  value: AssetBalance;
  clickable?: boolean;
};

type Props = {
  maxValue?: string;
  balances?: AssetBalanceWithLabel[];
  value: string;
  onValueChange: (val: string) => void;
};

export const UiTextFieldWithMax: FC<Props & StackProps> = ({
  maxValue,
  value,
  onValueChange,
  balances,
  sx,
  ...props
}) => {
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    let value = numberInputReg(e.target.value);

    if (maxValue && BN(value).gt(maxValue)) {
      value = maxValue;
    }

    onValueChange(value);
  }

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      spacing={1}
      sx={{ position: 'relative', display: 'flex', ...sx }}
      {...props}
    >
      {balances &&
        balances.length > 0 &&
        balances.map((balance) => (
          <Typography
            key={balance.label}
            sx={
              balance.clickable
                ? { cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }
                : undefined
            }
            onClick={
              balance.clickable ? () => onValueChange(balance.value.fullPrecision) : undefined
            }
          >
            {balance.label}: {balance.value.formatted} {balance.symbol}
          </Typography>
        ))}
      <OutlinedInput
        fullWidth
        value={value}
        onChange={handleChange}
        endAdornment={
          maxValue ? (
            <Button color="inherit" onClick={() => onValueChange(maxValue)}>
              Max
            </Button>
          ) : null
        }
      />
    </Stack>
  );
};
