import debug from 'debug';
import { ethers } from 'ethers';
import copy from 'fast-copy';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { bTokenAbi, erc20Abi, vaultAbi } from 'src/abi';
import { ZERO_ADDRESS } from 'src/constants/eth';
import { AssetBalance, AssetBalancesMap, BToken } from 'src/types/asset';
import { FCC } from 'src/types/FCC';
import { getEveryNth } from 'src/utils/arrayHelpers';
import { getDisplayAmount } from 'src/utils/bigNumber';
import { fetchAccountBorrowedBalances, fetchTokensBalances } from 'src/utils/token';
import { INITIAL_BALANCE } from '../constants/initialSchemas';
import { isAddressesEq } from '../utils/compareAddresses';
import { useAppChain } from './AppChainProvider';
import { useAssets } from './AssetsProvider';
import { useWeb3State } from './Web3CtxProvider';

const log = debug('components:BalancesProvider');

const balancesProviderInitCtx: {
  balances: AssetBalancesMap;
  ethBalance: AssetBalance;
  initializing: boolean;
} = {
  initializing: false,
  balances: {},
  ethBalance: INITIAL_BALANCE,
};

const BalancesProviderCtx = createContext(balancesProviderInitCtx);
let contractsSubEvents: ethers.Contract[] = [];

export const BalancesProvider: FCC = ({ children }) => {
  const { userAddress } = useWeb3State();
  const [{ appWsProvider, appRpcProvider, chainConfig }] = useAppChain();
  const { assets, fetchingPoolAssets } = useAssets();

  const [initializingBalances, setInitializingBalances] = useState(true);
  const [balances, setBalances] = useState(balancesProviderInitCtx.balances);
  const [ethBalance, setEthBalance] = useState(balancesProviderInitCtx.ethBalance);

  const newAssets = useMemo(
    () =>
      Object.values(assets)
        .map((el) => Object.values(el))
        .flat()
        .filter((el) => !Object.keys(balances).includes(el.address.toLowerCase())),
    [assets, balances],
  );

  useEffect(() => {
    if (fetchingPoolAssets) return;
    if (!userAddress) return;

    updateTokensBalances(newAssets);
    fetchEthBalance();
  }, [assets, fetchingPoolAssets]);

  useEffect(() => {
    clearBalances();
    clearTokensTransfers();
  }, [chainConfig]);

  useEffect(() => {
    if (!userAddress) {
      clearBalances();
      setInitializingBalances(true);
    }

    clearTokensTransfers();

    if (userAddress) {
      subTokensTransfers();
      updateTokensBalances();
    }
  }, [userAddress]);

  useEffect(() => {
    if (newAssets.length === 0) return;
    subTokensTransfers(newAssets);
  }, [newAssets]);

  function subTokensTransfers(targetAssets?: BToken[]) {
    const assetsToUpdate =
      targetAssets ||
      Object.values(assets)
        .map((el) => Object.values(el))
        .flat();

    const handleTokenTransfer = (from: string, to: string) => {
      if (
        userAddress?.toLowerCase() === to?.toLowerCase() ||
        userAddress?.toLowerCase() === from?.toLowerCase()
      ) {
        log('handleTokenTransfer');
        log('userAddress: ', userAddress);
        log('from: ', from);
        log('to: ', to);
        updateTokensBalances();
        fetchEthBalance();
      }
    };

    assetsToUpdate.forEach((bToken) => {
      const bTokenAddress = bToken.address;
      const wrappedAddress = bToken.wrapped.address;
      const underlyingAddress = bToken.wrapped.underlying.address;
      const bTokenContract = new ethers.Contract(bTokenAddress, bTokenAbi, appWsProvider);
      const wrappedContract = new ethers.Contract(wrappedAddress, vaultAbi, appWsProvider);
      const underlyingContract = new ethers.Contract(underlyingAddress, erc20Abi, appWsProvider);

      const isBContractHaveSub = contractsSubEvents.some(
        (contract) => String(contract.target).toLowerCase() === bTokenAddress.toLowerCase(),
      );
      const isWrappedContractHaveSub = contractsSubEvents.some(
        (contract) => String(contract.target).toLowerCase() === wrappedAddress.toLowerCase(),
      );
      const isUnderlyingContractHaveSub = contractsSubEvents.some(
        (contract) => String(contract.target).toLowerCase() === underlyingAddress.toLowerCase(),
      );

      if (!isBContractHaveSub) {
        bTokenContract.on('Transfer', handleTokenTransfer);
        contractsSubEvents.push(bTokenContract);
      }
      if (!isWrappedContractHaveSub) {
        wrappedContract.on('Transfer', handleTokenTransfer);
        contractsSubEvents.push(wrappedContract);
      }
      if (!isUnderlyingContractHaveSub) {
        underlyingContract.on('Transfer', handleTokenTransfer);
        contractsSubEvents.push(underlyingContract);
      }
    });
  }

  function clearTokensTransfers() {
    contractsSubEvents.forEach((contract) => {
      contract.removeAllListeners('Transfer');
    });
    contractsSubEvents = [];
  }

  async function updateTokensBalances(targetAssets?: BToken[]) {
    if (!userAddress) return;

    const assetsToUpdate =
      targetAssets ||
      Object.values(assets)
        .map((el) => Object.values(el))
        .flat();

    if (assetsToUpdate.length === 0) return;

    let ethIndex = -1;

    const tokens = assetsToUpdate
      .map((el, i) => {
        if (isAddressesEq(el.wrapped.underlying.address, ZERO_ADDRESS)) {
          ethIndex = i;
          return [el.address.toLowerCase(), el.wrapped.address.toLowerCase()];
        }
        return [
          el.address.toLowerCase(),
          el.wrapped.address.toLowerCase(),
          el.wrapped.underlying.address,
        ];
      })
      .flat();
    const tokensDecimals = assetsToUpdate
      .map((el) => {
        if (isAddressesEq(el.wrapped.underlying.address, ZERO_ADDRESS)) {
          return [el.decimals, el.wrapped.decimals];
        }

        return [el.decimals, el.wrapped.decimals, el.wrapped.underlying.decimals];
      })
      .flat();
    const bTokens = getEveryNth(
      ethIndex !== -1
        ? [
            ...tokens.slice(0, (ethIndex + 1) * 3),
            ZERO_ADDRESS,
            ...tokens.slice((ethIndex + 1) * 3),
          ]
        : tokens,
      3,
      2,
    );

    log('bTokens', bTokens);

    const [fetchedTokensBalances, fetchedAccountTokensBorrowed] = await Promise.all([
      fetchTokensBalances(userAddress, tokens, appRpcProvider),
      fetchAccountBorrowedBalances(userAddress, bTokens, appRpcProvider),
    ]);
    const newBalances: AssetBalancesMap = {};

    log('fetchedTokensBalances', fetchedTokensBalances);

    fetchedTokensBalances.forEach((tokenBalance, i) => {
      const raw = tokenBalance?.toString() || '0';
      const decimals = Number(tokensDecimals[i]);
      const fullPrecision = getDisplayAmount(raw.toString(), { decimals, cut: false });
      const formatted = getDisplayAmount(raw.toString(), { decimals });

      if (!newBalances[tokens[i]])
        newBalances[tokens[i]] = {
          wallet: INITIAL_BALANCE,
          borrowing: balances?.[tokens[i]]?.borrowing || INITIAL_BALANCE,
        };

      newBalances[tokens[i]].wallet = {
        raw,
        fullPrecision,
        formatted,
      };
    });

    fetchedAccountTokensBorrowed.forEach((tokenBalance, i) => {
      const raw = tokenBalance.toString() || '0';
      const decimals = Number(
        assetsToUpdate.find((el) => isAddressesEq(el.address, bTokens[i]))?.wrapped.decimals || '0',
      );
      const fullPrecision = getDisplayAmount(raw.toString(), { decimals, cut: false });
      const formatted = getDisplayAmount(raw.toString(), { decimals });

      if (!newBalances[bTokens[i]])
        newBalances[bTokens[i]] = {
          wallet: INITIAL_BALANCE,
          borrowing: INITIAL_BALANCE,
        };

      newBalances[bTokens[i]].borrowing = {
        raw,
        fullPrecision,
        formatted,
      };
    });

    log('newBalances', newBalances);

    setBalances((prevBalances) => ({ ...prevBalances, ...newBalances }));
    setTimeout(() => {
      setInitializingBalances(false);
    }, 3000);
  }

  async function fetchEthBalance() {
    if (!userAddress) return;

    const raw = (await appRpcProvider.getBalance(userAddress)).toString();
    const formatted = getDisplayAmount(raw.toString(), { decimals: 18 });
    const fullPrecision = getDisplayAmount(raw.toString(), { decimals: 18, cut: false });
    const newBalance = { raw, fullPrecision, formatted };

    setEthBalance((prevBalance) => {
      if (prevBalance.raw === newBalance.raw) return prevBalance;

      return newBalance;
    });
  }

  function clearBalances() {
    const newBalances = copy(balances);

    Object.values(newBalances).forEach((el) => {
      el.wallet = INITIAL_BALANCE;
      el.borrowing = INITIAL_BALANCE;
    });

    setEthBalance(INITIAL_BALANCE);
    setBalances(newBalances);
  }

  return (
    <BalancesProviderCtx.Provider
      value={{ balances, ethBalance, initializing: initializingBalances }}
    >
      {children}
    </BalancesProviderCtx.Provider>
  );
};

export const useBalances = () => useContext(BalancesProviderCtx);

export const useTokenBalance = (tokenAddress?: string) => {
  const { balances, ethBalance, initializing } = useBalances();

  if (!tokenAddress || initializing) {
    console.warn('CANT GET TOKEN BALANCE', tokenAddress);
    return { balance: INITIAL_BALANCE, initializing };
  }
  if (tokenAddress === ZERO_ADDRESS) return { balance: ethBalance, initializing };

  return {
    balance: balances[tokenAddress]?.wallet || INITIAL_BALANCE,
    initializing,
  };
};

export const useTokenBorrowedBalance = (tokenAddress: string): AssetBalance | undefined => {
  const { balances } = useBalances();
  return balances[tokenAddress]?.borrowing;
};
