import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import { DEFAULT_CHAIN_URL, DEFAULT_POOL_URL } from 'src/configs/chains.config';
import { AssetPage } from 'src/pages/[assetAddress]/AssetPage';
import { Home } from 'src/pages/home/Home';
import { Liquidations } from 'src/pages/liquidations/Liquidations';
import { TermsPage } from 'src/pages/terms/TermsPage';
import { UrlChainManager } from './components/UrlChainManager';
import { UrlPoolIdManager } from './components/UrlPoolIdManager';
import { MainLayout } from './layout/MainLayout';
import { Borrow } from './pages/[assetAddress]/borrow/Borrow';
import { Repay } from './pages/[assetAddress]/repay/Repay';
import { Supply } from './pages/[assetAddress]/supply/Supply';
import { Withdraw } from './pages/[assetAddress]/withdraw/Withdraw';
import { LiquidationsRedirect } from './pages/liquidationsRedirect/LiquidationsRedirect';
import { RouterDependedProviders } from './providers/RouterDependedProviders';

const routes: RouteObject[] = [
  {
    element: <RouterDependedProviders />,
    children: [
      {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            index: true,
            element: <Navigate to={DEFAULT_CHAIN_URL} replace />,
          },
          {
            path: 'liquidations',
            element: <LiquidationsRedirect />,
          },
          {
            path: ':chainUrlRepresentation',
            element: <UrlChainManager />,
            children: [
              {
                index: true,
                element: <Navigate to={DEFAULT_POOL_URL} replace />,
              },
              {
                path: ':poolId',
                element: <UrlPoolIdManager />,
                children: [
                  {
                    index: true,
                    element: <Home />,
                  },
                  { path: 'liquidations', element: <Liquidations /> },
                  { path: ':assetAddress', element: <AssetPage /> },
                  {
                    path: ':assetAddress',
                    element: <AssetPage />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to="supply" replace />,
                      },
                      {
                        path: 'supply',
                        element: <Supply />,
                      },
                      {
                        path: 'repay',
                        element: <Repay />,
                      },
                      {
                        path: 'withdraw',
                        element: <Withdraw />,
                      },
                      {
                        path: 'borrow',
                        element: <Borrow />,
                      },
                    ],
                  },
                  {
                    path: 'terms',
                    element: <TermsPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
