// @mui
import { Box, Divider, Drawer, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Logo } from 'src/components/Logo';
// config
import navConfig from 'src/components/nav/NavSection/config-navigation';
// components
import NavSectionVertical from 'src/components/nav/NavSectionVertical';
import { Scrollbar } from 'src/components/scrollbar/Scrollbar';
// hooks
import { useResponsive } from 'src/hooks/useResponsive';
import { NAV } from 'src/theme/layout-constants';
import { HeaderElements } from '../header/HeaderElements';
// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <Logo />
      </Stack>

      <NavSectionVertical data={navConfig} />

      <Divider />

      <Stack
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
        padding={'1.5rem'}
        gap={'1rem'}
      >
        <HeaderElements />
      </Stack>

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
