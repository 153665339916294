import { Grid, Typography } from '@mui/material';
import debug from 'debug';
import { ethers } from 'ethers';
import { useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { UiCard } from 'src/components/ui/UiCard';
import { UiExecuteButton } from 'src/components/ui/UiExecuteButton';
import { UiTextFieldWithMax } from 'src/components/ui/UiTextFieldWithMax';
import { MAX_UINT } from 'src/constants/eth';
import { useAllowance } from 'src/hooks/useAllowance';
import { useApprove } from 'src/hooks/useApprove';
import { useTokenBalance } from 'src/providers/BalancesProvider';
import { useSetModal } from 'src/providers/ModalsProvider';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { BToken } from 'src/types/asset';
import { BN, getAtomicAmount } from 'src/utils/bigNumber';
import { vaultAbi } from '../../../abi';
import { useTransactions } from '../../../providers/TransactionsProvider';

const log = debug('components:WrapUnwrap');

export const WrapUnwrap = ({ label, isWrap = false }: { label: string; isWrap?: boolean }) => {
  const { asset } = useOutletContext<{ asset: BToken }>();
  const { userAddress, walletProvider } = useWeb3State();
  const tokenApprove = useApprove(
    asset.wrapped.underlying.address,
    asset.wrapped.address,
    'approve',
  );
  const [tokenAllowance, reloadAllowance] = useAllowance(
    asset.wrapped.underlying.address,
    userAddress,
    asset.wrapped.address,
  );
  const setModal = useSetModal();
  const { balance: wrappedBalance } = useTokenBalance(asset.wrapped.address);
  const { balance: tokenBalance } = useTokenBalance(asset.wrapped.underlying.address);
  const { trackTx, trackError } = useTransactions();

  const [amount, setAmount] = useState('0');

  const needApproval =
    !asset.wrapped.underlying.isEth &&
    BN(tokenAllowance).lt(getAtomicAmount(amount, Number(asset.wrapped.underlying.decimals)));

  const errorMessage =
    isWrap && BN(amount).gt(tokenBalance.fullPrecision)
      ? 'Low balance...'
      : !isWrap && BN(amount).gt(wrappedBalance.fullPrecision)
      ? 'Low balance...'
      : '';

  function approveToSpendTokens() {
    return tokenApprove(MAX_UINT).finally(reloadAllowance);
  }

  const executeActionHandler = async () => {
    if (needApproval) {
      approveToSpendTokens();
      return;
    }
    if (!walletProvider) return;

    let tx;

    try {
      const signer = await walletProvider.getSigner();
      const vaultContract = new ethers.Contract(asset.wrapped.address, vaultAbi, signer);
      const amountWei = getAtomicAmount(
        amount,
        Number(isWrap ? asset.wrapped.underlying.decimals : asset.wrapped.decimals),
      );

      setModal({ key: 'loader', title: 'Confirm your transaction in the wallet' });

      if (isWrap && asset.wrapped.underlying.isEth) {
        tx = await vaultContract.wrapEther({ value: amountWei });
      } else if (isWrap) {
        tx = await vaultContract.wrap(amountWei);
      } else {
        tx = await vaultContract.unwrap(amountWei);
      }

      setModal({
        key: 'loader',
        title: `${isWrap ? 'Wrapping' : 'Unwrapping'} ${amount} ${
          isWrap ? asset.wrapped.underlying.symbol : asset.wrapped.symbol
        }...`,
        txHash: tx.txHash,
      });

      trackTx(tx);
      log('tx', tx);
      await tx.wait();
      setModal(null);

      log(
        `${amountWei} ${
          isWrap ? asset.wrapped.underlying.symbol : asset.wrapped.symbol
        } successfully supplied.`,
      );
    } catch (e) {
      trackError(e, tx);
      setModal(null);
      console.error(`${isWrap ? 'Wrapping' : 'Unwrapping'} action failed:`, e);
      throw e;
    }
  };

  const balances = useMemo(
    () =>
      isWrap
        ? [
            { label: 'Wrapped', value: wrappedBalance, symbol: asset.wrapped.symbol },
            {
              label: 'Can wrap',
              value: tokenBalance,
              symbol: asset.wrapped.underlying.symbol,
              clickable: true,
            },
          ]
        : [
            {
              label: 'Wrapped',
              value: wrappedBalance,
              symbol: asset.wrapped.symbol,
              clickable: true,
            },
          ],
    [isWrap, asset.wrapped.symbol, asset.wrapped.underlying.symbol, tokenBalance, wrappedBalance],
  );

  return (
    <UiCard>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h4" mb={2}>
            {isWrap ? 'Wrap' : 'Unwrap'}
          </Typography>
          <UiTextFieldWithMax
            balances={balances}
            maxValue={isWrap ? tokenBalance.fullPrecision : wrappedBalance.fullPrecision}
            onValueChange={setAmount}
            value={amount}
            mb={4}
          />
          <UiExecuteButton
            executeLabel={errorMessage || label}
            onClick={executeActionHandler}
            needApproval={needApproval}
            disabled={(!needApproval && !!errorMessage) || !BN(amount).gt(0)}
          />
        </Grid>
      </Grid>
    </UiCard>
  );
};
