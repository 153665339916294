import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import debug from 'debug';
import { ethers } from 'ethers';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { AssetParam } from 'src/pages/[assetAddress]/components/AssetParam';
import { bTokenAbi } from '../../../abi';
import { Iconify } from '../../../components/Iconify';
import { SliderConnectedWithInput } from '../../../components/SliderConnectedWithInput';
import { UiCard } from '../../../components/ui/UiCard';
import { UiExecuteButton } from '../../../components/ui/UiExecuteButton';
import { UiTextFieldWithMax } from '../../../components/ui/UiTextFieldWithMax';
import tooltipsDic from '../../../configs/tooltips.json';
import { useBTokenPrice } from '../../../hooks/useBTokenPrice';
import { useTokenMaxWithdraw } from '../../../hooks/useTokenMaxWithdraw';
import { useAppChain } from '../../../providers/AppChainProvider';
import { useTokenBalance } from '../../../providers/BalancesProvider';
import { useSetModal } from '../../../providers/ModalsProvider';
import { useTransactions } from '../../../providers/TransactionsProvider';
import { useSelectedPoolWalletSummary } from '../../../providers/WalletSummaryProvider';
import { useWeb3State } from '../../../providers/Web3CtxProvider';
import { BToken } from '../../../types/asset';
import { BN, getAtomicAmount, getDisplayAmount } from '../../../utils/bigNumber';
import { calcTokenSupplying, calculateAPY } from '../../../utils/token';
import { WrapUnwrap } from '../components/WrapUnwrap';
import { WithdrawHelpMessage } from './WithdrawHelpMessage';

const log = debug('components:Withdraw');

export const Withdraw = () => {
  const { asset } = useOutletContext<{ asset: BToken }>();
  const maxValue = useTokenMaxWithdraw(asset);
  const { walletProvider } = useWeb3State();
  const [{ chainConfig }] = useAppChain();
  const setModal = useSetModal();
  const { trackTx, trackError } = useTransactions();

  const { balance: tokenBalance } = useTokenBalance(asset?.address || '');
  const { accountBorrowingTotalUsd, accountBorrowLimitTotalUsd } = useSelectedPoolWalletSummary();
  const price = useBTokenPrice(asset);
  const isMobile = useMediaQuery('(max-width:425px)');

  const [amount, setAmount] = useState('0');

  const borrowLimitUsed = BN(accountBorrowingTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd).div(accountBorrowLimitTotalUsd).times(100).toFixed(2)
    : 100;

  const featureBorrowLimit = BN(accountBorrowingTotalUsd).gt(0)
    ? BN(accountBorrowingTotalUsd)
        .div(
          BN(accountBorrowLimitTotalUsd).minus(
            BN(amount)
              .times(BN(asset.collateralFactorMantissa.toString()).div(1e18))
              .times(price?.fullPrecision || '0'),
          ),
        )
        .times(100)
        .toFixed(2)
    : 0;

  const tokenSupplying = calcTokenSupplying(asset, tokenBalance.raw.toString());

  const errorMessage = BN(amount).gt(maxValue) ? 'No Balance to Withdraw' : '';

  async function redeem() {
    if (!walletProvider) return;

    const mantissa = BN(18).plus(asset.wrapped.decimals.toString());
    const oneCTokenInUnderlying = BN(asset.exchangeRateCurrent.toString()).div(
      BN(10).pow(mantissa.toString()),
    );
    const withdrawMaxBTokenValue = tokenBalance.raw;

    log('call redeem', {
      withdrawBTokenValue: withdrawMaxBTokenValue.toString(),
      oneCTokenInUnderlying: oneCTokenInUnderlying.toString(),
      exchangeRateCurrent: asset.exchangeRateCurrent.toString(),
      asset,
      tokenBalance,
      amount,
      valueEqMax: amount === maxValue,
    });

    let tx;

    try {
      const signer = await walletProvider.getSigner();
      const bTokenContract = new ethers.Contract(asset.address, bTokenAbi, signer);

      setModal({ key: 'loader', title: 'Confirm your transaction in the wallet' });

      if (amount === maxValue) {
        tx = await bTokenContract.redeem(withdrawMaxBTokenValue.toString());
      } else {
        tx = await bTokenContract.redeemUnderlying(
          getAtomicAmount(amount, Number(asset.wrapped.decimals)),
        );
      }

      setModal({
        key: 'loader',
        title: `Withdrawing ${amount} ${asset.wrapped.symbol}...`,
        txHash: tx.txHash,
      });

      trackTx(tx);
      log('tx', tx);
      await tx.wait();
      setModal(null);

      log(`${withdrawMaxBTokenValue} ${asset.wrapped.symbol} successfully withdrawed.`);
    } catch (err) {
      trackError(err, tx);
      setModal(null);
      console.error('Withdraw action failed:', err);
      throw err;
    }
  }
  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ padding: { xs: 1, md: 3 } }}>
          <WithdrawHelpMessage />
        </Box>
        <WrapUnwrap label="Unwrap" />
      </Grid>
      <Grid item xs={12}>
        <UiCard>
          <Stack spacing={2}>
            <Typography variant="h4">Withdraw</Typography>
            <UiTextFieldWithMax maxValue={maxValue} onValueChange={setAmount} value={amount} />
            <Box sx={{ marginBottom: '1rem' }}>
              <SliderConnectedWithInput
                decimals={asset.wrapped.decimals}
                value={amount}
                maxValue={maxValue}
                setValue={setAmount}
              />
            </Box>
            <Box>
              <Stack direction={'column'} flexWrap={'wrap'}>
                <Stack
                  direction={isMobile ? 'column' : 'row'}
                  justifyContent={'space-between'}
                  marginBottom={'1.5rem'}
                >
                  <AssetParam
                    name="Supply APY"
                    sx={{ marginBottom: isMobile ? '1.5rem' : '0' }}
                    value={`${calculateAPY(asset.supplyRatePerBlock, chainConfig.blocksPerDay)} %`}
                  />
                  <AssetParam
                    name="Distribution APY"
                    value="-%"
                    tooltipMessage={tooltipsDic.distributionAPY}
                  />
                </Stack>
                <Stack
                  direction={isMobile ? 'column' : 'row'}
                  justifyContent={'space-between'}
                  marginBottom={'1.5rem'}
                >
                  <AssetParam
                    name="Borrow Limit Used"
                    value={
                      <Stack direction="row" alignItems="center">
                        {borrowLimitUsed} %
                        {BN(accountBorrowingTotalUsd).gt(0) && (
                          <>
                            <Iconify
                              icon="material-symbols:arrow-forward-rounded"
                              width={24}
                              sx={{ mx: 2 }}
                            />
                            {featureBorrowLimit} %
                          </>
                        )}
                      </Stack>
                    }
                  />
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'} marginBottom={'1.5rem'}>
                  <AssetParam
                    name="Currently Supplying"
                    value={`${getDisplayAmount(tokenSupplying, {
                      decimals: Number(asset.wrapped.decimals),
                    })} ${asset.wrapped.symbol}`}
                  />
                </Stack>
              </Stack>
            </Box>
            <UiExecuteButton
              executeLabel={errorMessage || 'Withdraw'}
              onClick={redeem}
              disabled={Boolean(errorMessage) || !BN(amount).gt(0)}
            />
          </Stack>
        </UiCard>
      </Grid>
    </>
  );
};
