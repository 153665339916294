/** @jsxImportSource @emotion/react */
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import { useSetModal } from 'src/providers/ModalsProvider';
import { useWeb3Controller } from 'src/providers/Web3CtxProvider';
import { Iconify } from 'src/components/Iconify';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';

const termsAcceptedFromLS = JSON.parse(localStorage.getItem('termsAccepted') || 'false');
const citizenConfirmFromLS = JSON.parse(localStorage.getItem('citizenConfirm') || 'false');

export const ConnectWalletModal = () => {
  const [termsAccepted, setTermAccepted] = useState(termsAcceptedFromLS);
  const [citizenConfirm, setCitizenConfirm] = useState(citizenConfirmFromLS);
  const setModal = useSetModal();
  const { connect } = useWeb3Controller();
  const { chainUrlRepresentation, poolId } = useParams();

  const handleClose = () => setModal(null);

  const makeConnectHandler = (walletId: string) => () => {
    connect(walletId);
    handleClose();
  };

  function termOfUseClickAction() {
    localStorage.setItem('termsAccepted', (!termsAccepted).toString());
    setTermAccepted(!termsAccepted);
  }

  function citizenConfirmClickAction() {
    localStorage.setItem('citizenConfirm', (!citizenConfirm).toString());
    setCitizenConfirm(!citizenConfirm);
  }

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Connect your wallet
        <IconButton onClick={handleClose}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ pb: 3 }}>
        <Stack direction="column" spacing={2} mb={3}>
          <FormControlLabel
            sx={{ alignItems: 'flex-start' }}
            control={
              <Checkbox
                sx={{
                  marginTop: -1,
                }}
                onChange={termOfUseClickAction}
                checked={termsAccepted}
              />
            }
            label={
              <span>
                I have read and accept the{' '}
                <Link
                  to={`${chainUrlRepresentation}/${poolId}/terms`}
                  onClick={() => setModal(null)}
                  style={{ color: '#212B36' }}
                >
                  Terms of Service
                </Link>
                .
              </span>
            }
          />
          <FormControlLabel
            sx={{ alignItems: 'flex-start' }}
            control={
              <Checkbox
                sx={{
                  marginTop: -1,
                }}
                checked={citizenConfirm}
                onChange={citizenConfirmClickAction}
              />
            }
            label="I hereby confirm that I am not a citizen or resident of the following countries: Afghanistan, American Samoa, U.S. Virgin Islands, Guam, Iran, Iraq, Yemen, Libya, Pakistan, State of Palestine, Puerto Rico, Somalia, North Korea, The Northern Mariana Islands, The United States of America, Syria, Russian Federation, Republic of Belarus, Transnistria, Turkish Republic of Northern Cyprus, Western Sahara, Federal Republic of Ambazonia, Kosovo, Ukraine, Sudan, Venezuela, Democratic Republic of the Congo, Singapore, Cuba, Canada, China, Austria, Belgium, Bulgaria, Croatia, Republic of Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain and Sweden."
          />
          <Button
            variant="soft"
            color="inherit"
            size="large"
            onClick={makeConnectHandler('injected')}
            sx={{ justifyContent: 'space-between' }}
            disabled={!termsAccepted || !citizenConfirm}
          >
            <img
              alt="MetaMask icon"
              src="/images/icons/ic-metamask.svg"
              style={{ height: '1.8rem' }}
            />
            <Typography width="100%" variant="subtitle1">
              MetaMask
            </Typography>
          </Button>
          <Button
            variant="soft"
            color="inherit"
            size="large"
            onClick={makeConnectHandler('walletconnect')}
            sx={{ justifyContent: 'space-between' }}
            disabled={!termsAccepted || !citizenConfirm}
          >
            <img alt="WalletConnect icon" src="/images/icons/ic-walletconnect.svg" />
            <Typography width="100%" variant="subtitle1">
              WalletConnect
            </Typography>
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
