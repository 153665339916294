import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import BigNumber from 'bignumber.js';
import debug from 'debug';
import { ethers } from 'ethers';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SliderConnectedWithInput } from 'src/components/SliderConnectedWithInput';
import { UiCard } from 'src/components/ui/UiCard';
import { UiExecuteButton } from 'src/components/ui/UiExecuteButton';
import { UiParam } from 'src/components/ui/UiParam';
import { UiTextFieldWithMax } from 'src/components/ui/UiTextFieldWithMax';
import { useBTokenPrice } from 'src/hooks/useBTokenPrice';
import { usePools } from 'src/hooks/usePools';
import { useAppChain } from 'src/providers/AppChainProvider';
import { useSelectedPoolAssets } from 'src/providers/AssetsProvider';
import { useTokenBalance } from 'src/providers/BalancesProvider';
import { useBoosterPrices } from 'src/providers/BoosterOraclePricesProvider';
import { useGnosisMedianOracle } from 'src/providers/GnosisMedianOracleProvider';
import { BToken } from 'src/types/asset';
import { BN, getDisplayAmount } from 'src/utils/bigNumber';
import {
  calcTokenSupplying,
  fetchAccountBorrowedBalances,
  fetchTokensBalances,
} from 'src/utils/token';
import { lensMiniAbi } from '../../abi';

const log = debug('pages:LiquidationsPage');

type OwnerMarketsState = BToken & {
  supplying: string;
  borrowing: string;
};

export const Liquidations = () => {
  const [searchParams, setSearchParams] = useSearchParams({ unitroller: '', owner: '' });
  const [{ appRpcProvider, chainConfig }] = useAppChain();
  const { selectedPoolConfig } = usePools();
  const { assets: poolAssets } = useSelectedPoolAssets();
  const { fetchingPoolAssets } = useSelectedPoolAssets();
  const { loading: fetchingGnosisPrices } = useGnosisMedianOracle();
  const { loading: fetchingBoosterPrices } = useBoosterPrices();
  const { prices: gnosisPrices, loading: gnosisLoading } = useGnosisMedianOracle();
  const { prices: boosterPrices, loading: boosterLoading } = useBoosterPrices();
  const [ownerMarketsStates, setOwnerMarketsStates] = useState<OwnerMarketsState[]>([]);

  const [assetForLiquidation, setAssetForLiquidation] = useState('');
  const [assetForRepay, setAssetForRepay] = useState('');
  const [repayAmount, setRepayAmount] = useState('0');

  const assetForLiquidateParams = poolAssets[assetForLiquidation];
  const assetForRepayParams = poolAssets[assetForRepay];

  const assetForLiquidatePrice = useBTokenPrice(assetForLiquidateParams);
  const assetForRepayPrice = useBTokenPrice(assetForRepayParams);

  const { balance: liquidatorWrappedBalance, initializing: initializingWrappedBalance } =
    useTokenBalance(assetForRepayParams?.wrapped.address);
  const { balance: liquidatorTokenBalance, initializing: initializingTokenBalance } =
    useTokenBalance(assetForRepayParams?.wrapped.underlying.address);
  const timeout = useRef<NodeJS.Timeout>();

  // get borrower params
  useEffect(() => {
    fetchOwnerCurrentParams();
  }, [searchParams, poolAssets]);

  useEffect(() => {
    clearTimeout(timeout.current);

    timeout.current = setTimeout(checkIfLiquidationIsPossible, 200);
  }, [repayAmount]);

  async function fetchOwnerCurrentParams() {
    const owner = searchParams.get('owner');
    const unitroller = searchParams.get('unitroller');

    if (!owner || !unitroller) return;

    const [ownerSupplying, ownerBorrowed] = await Promise.all([
      fetchTokensBalances(owner, Object.keys(poolAssets), appRpcProvider),
      fetchAccountBorrowedBalances(owner, Object.keys(poolAssets), appRpcProvider),
    ]);

    log('poolAssets', { poolAssets });
    log('ownerBorrowed', { ownerBorrowed });
    log('ownerCTokens', { ownerSupplying });

    const ownerCurrentBorrows = Object.values(poolAssets)
      .map((bToken, i) => {
        if (BN(ownerBorrowed[i].toString()).lte(0)) return null;

        return {
          ...bToken,
          supplying: calcTokenSupplying(bToken, ownerSupplying[i].toString()),
          borrowing: ownerBorrowed[i].toString(),
        };
      })
      .filter(Boolean);

    log('ownerCurrentBorrows', ownerCurrentBorrows);

    setOwnerMarketsStates(ownerCurrentBorrows);
  }

  useEffect(() => {
    if (!gnosisPrices) return;
    if (!boosterPrices) return;
    if (gnosisLoading || boosterLoading) return;

    ownerMarketsStates.map((el) => {
      const { address, supplying, borrowing } = el;
      let price;
      if (boosterPrices[address] && BN(boosterPrices[address].fullPrecision).gt(0)) {
        price = boosterPrices[address];
      } else {
        price = gnosisPrices[address];
      }

      if (!price) {
        console.log('fkasdjfds', { boosterPrices, gnosisPrices, address });
        return;
      }

      const supplyingInUsd = BN(supplying)
        .times(price.fullPrecision)
        .div(BN(10).pow(el.wrapped.decimals))
        .toString();
      const borrowingInUsd = BN(borrowing)
        .times(price.fullPrecision)
        .div(BN(10).pow(el.wrapped.decimals))
        .toString();

      const market = {
        address,
        supplyingInUsd,
        borrowingInUsd,
      };

      log('market', market);
    });
  }, [ownerMarketsStates, gnosisPrices, boosterPrices]);

  const ownerCurrentBorrowsFormatted = useMemo(() => {
    if (!ownerMarketsStates) return [];
    if (Object.keys(poolAssets).length === 0) return [];

    return ownerMarketsStates.map((el) => {
      const borrowing = {
        raw: el.borrowing,
        fullPrecision: getDisplayAmount(el.borrowing.toString(), {
          decimals: Number(poolAssets[el.address].wrapped.decimals),
          cut: false,
        }),
        formatted: getDisplayAmount(el.borrowing.toString(), {
          decimals: Number(poolAssets[el.address].wrapped.decimals),
        }),
      };

      const maxToLiquidateRaw = BN(borrowing.raw).times(0.9).toString();

      const maxToLiquidate = {
        raw: maxToLiquidateRaw,
        fullPrecision: getDisplayAmount(maxToLiquidateRaw, {
          decimals: Number(poolAssets[el.address].wrapped.decimals),
          cut: false,
        }),
        formatted: getDisplayAmount(maxToLiquidateRaw, {
          decimals: Number(poolAssets[el.address].wrapped.decimals),
        }),
      };

      return {
        ...el,
        ...poolAssets[el.address.toLowerCase()],
        maxToLiquidate,
        borrowing,
      };
    });
  }, [ownerMarketsStates, poolAssets]);

  const selectedAssetBorrowing = ownerCurrentBorrowsFormatted.find(
    (el) => el.address === assetForLiquidation,
  );

  const summaryLiquidatorBalance = {
    raw: BN(liquidatorWrappedBalance.raw).plus(liquidatorTokenBalance.raw).toString(),
    fullPrecision: BN(liquidatorWrappedBalance.fullPrecision)
      .plus(liquidatorTokenBalance.fullPrecision)
      .toString(),
    formatted: BN(liquidatorWrappedBalance.formatted)
      .plus(liquidatorTokenBalance.formatted)
      .toString(),
  };

  const maxValueForLiquidation = BN(selectedAssetBorrowing?.borrowing.fullPrecision || '0')
    .times(0.5)
    .toString();

  const k = BN(assetForLiquidatePrice?.fullPrecision || 0)
    .div(assetForRepayPrice?.fullPrecision || 1)
    .toString();

  const amountOfRepayAssetNeeded = BN(maxValueForLiquidation || '0')
    .times(k)
    .toFixed(Number(assetForRepayParams?.wrapped.decimals) || 18);

  const debtInUSD = BN(maxValueForLiquidation)
    .times(assetForLiquidatePrice?.fullPrecision || 0)
    .toFixed(2);

  const summaryLiquidatorBalanceInUSD = BN(summaryLiquidatorBalance.fullPrecision)
    .times(assetForRepayPrice?.fullPrecision || 0)
    .toFixed(2);

  const amountLiquidatorCanRepay = BigNumber.min(
    amountOfRepayAssetNeeded,
    summaryLiquidatorBalance.fullPrecision,
  ).toString();

  const errorMessage =
    initializingTokenBalance || initializingWrappedBalance
      ? 'Loading balances...'
      : BN(summaryLiquidatorBalance.fullPrecision).lte(0)
      ? 'Low balance'
      : '';

  async function checkIfLiquidationIsPossible() {
    if (!assetForRepay) return;
    if (!assetForRepayParams) return;
    if (!assetForLiquidation) return;
    if (!assetForLiquidateParams) return;

    const comptroller = selectedPoolConfig.contracts.unitroller;
    const lensMini = chainConfig.contracts.lensMini;
    const contract = new ethers.Contract(lensMini, lensMiniAbi, appRpcProvider);
    const pricesCTokens = Object.keys(poolAssets);
    const cTokenBorrowed = assetForLiquidateParams.address;
    const cTokenCollateral = assetForRepayParams.address;
    const borrower = searchParams.get('owner');
    const amount = BN(repayAmount)
      .times(BN(10).pow(assetForRepayParams.wrapped.underlying.decimals))
      .toString();
    const params = [
      comptroller,
      [], //TODO: add pricesCTokens
      [], //TODO: add prices Q112
      cTokenBorrowed,
      cTokenCollateral,
      borrower,
      amount,
    ];

    log('params', params);

    const isLiquidationPossible = await contract.liquidateBorrowAllowed(...params);

    log('isLiquidationPossible', isLiquidationPossible);
  }

  const handleInputChange =
    (param: 'unitroller' | 'owner') => (e: ChangeEvent<HTMLInputElement>) => {
      searchParams.set(param, e.target.value);
      setSearchParams(searchParams);
    };

  const liquidate = () => {};

  if (fetchingPoolAssets)
    return (
      <Stack spacing={2} alignItems="center" mt={4}>
        <CircularProgress />
        <h2>Loading assets...</h2>
      </Stack>
    );

  if (fetchingGnosisPrices || fetchingBoosterPrices)
    return (
      <Stack spacing={2} alignItems="center" mt={4}>
        <CircularProgress />
        <h2>Loading prices...</h2>
      </Stack>
    );

  return (
    <Stack sx={{ maxWidth: '40rem', margin: '0 auto' }}>
      <Typography variant="h3" textAlign="center" mb="2rem" fontSize="2rem">
        Observe current liquidations
      </Typography>
      <Box sx={{ mb: 2 }}>
        <UiCard>
          <TextField
            onInput={handleInputChange('unitroller')}
            value={searchParams.get('unitroller') || ''}
            label="Unitroller address"
            fullWidth
          />
        </UiCard>
      </Box>
      <Box sx={{ mb: 2 }}>
        <UiCard>
          <TextField
            value={searchParams.get('owner') || ''}
            onInput={handleInputChange('owner')}
            label="Position owner address"
            fullWidth
          />
        </UiCard>
      </Box>
      <Typography mt={4} mb={3} variant="h4">
        Liquidate
      </Typography>
      <UiCard>
        <Stack spacing={4}>
          <FormControl fullWidth>
            <InputLabel id="for-liquidate">Asset for liquidate</InputLabel>
            <Select
              labelId="for-liquidate"
              value={assetForLiquidation}
              onChange={(e) => setAssetForLiquidation(e.target.value)}
              label="Asset for liquidate"
            >
              {ownerCurrentBorrowsFormatted.map((el) => (
                <MenuItem value={el.address}>
                  Liquidate: {el.wrapped.underlying.symbol} (max: {el.maxToLiquidate.formatted})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="for-repay">Asset for repay</InputLabel>
            <Select
              value={assetForRepay}
              onChange={(e) => setAssetForRepay(e.target.value)}
              label="Asset for repay"
              labelId="for-repay"
            >
              {Object.values(poolAssets).map((asset) => (
                <MenuItem value={asset.address}>{asset.wrapped.underlying.symbol}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {assetForLiquidateParams && (
            <>
              <Box>asset: {assetForLiquidateParams.wrapped.address}</Box>
              <Box>asset: {assetForLiquidateParams.wrapped.symbol}</Box>
              <Box>owner borrowing: {selectedAssetBorrowing?.borrowing.raw}</Box>
              <Box>owner borrowing: {selectedAssetBorrowing?.borrowing.formatted}</Box>
            </>
          )}
          {assetForRepayParams && (
            <>
              <Box>
                cTokenCollateral underlying: {assetForRepayParams.wrapped.underlying.address}
              </Box>
              <Box>liquidator balance wrapped raw: {liquidatorWrappedBalance.raw}</Box>
              <Box>liquidator balance wrapped formatted: {liquidatorWrappedBalance.formatted}</Box>
              <Box>liquidator balance token raw: {liquidatorTokenBalance.raw}</Box>
              <Box>liquidator balance token formatted: {liquidatorTokenBalance.formatted}</Box>
              <Box>liquidator summ balance raw: {summaryLiquidatorBalance.raw}</Box>
              <Box>liquidator summ balance formatted: {summaryLiquidatorBalance.formatted}</Box>
              <Box>liquidator summ balance in usd: {summaryLiquidatorBalanceInUSD}</Box>
              <Box>max value for liquidation: {maxValueForLiquidation}</Box>
              <Box>max value for liquidation in usd: {debtInUSD}</Box>
              <Box>k: {k}</Box>
              <Box>amountOfRepayAssetNeeded: {amountOfRepayAssetNeeded}</Box>
              <Box>amountLiquidatorCanRepay: {amountLiquidatorCanRepay}</Box>
            </>
          )}

          {assetForRepayParams && (
            <Grid container>
              <Grid item xs={12} md={4}>
                <UiParam
                  name={`${assetForRepayParams.wrapped.underlying.symbol} balance`}
                  value={liquidatorTokenBalance.formatted}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <UiParam
                  name={`${assetForRepayParams.wrapped.symbol} balance`}
                  value={liquidatorWrappedBalance.formatted}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <UiParam name={'Summary balance'} value={summaryLiquidatorBalance.formatted} />
              </Grid>
            </Grid>
          )}

          <Box>
            <UiTextFieldWithMax
              value={repayAmount}
              maxValue={amountLiquidatorCanRepay}
              onValueChange={setRepayAmount}
            />
            <SliderConnectedWithInput
              value={repayAmount}
              decimals={assetForRepayParams?.wrapped.decimals || 18}
              maxValue={amountLiquidatorCanRepay}
              setValue={setRepayAmount}
            />
          </Box>
          <UiExecuteButton
            disabled={!!errorMessage}
            executeLabel={errorMessage || 'Liquidate'}
            onClick={liquidate}
          />
        </Stack>
      </UiCard>
    </Stack>
  );
};
