import { Box, Grid, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { UiCard } from 'src/components/ui/UiCard';
import { PoolId } from 'src/configs/pools.config';
import { UnwrapPending } from 'src/pages/[assetAddress]/components/UnwrapPending';
import { useAppChain } from 'src/providers/AppChainProvider';
import { useAssets, useSelectedPoolAssets } from 'src/providers/AssetsProvider';
import { useBoosterPrices } from 'src/providers/BoosterOraclePricesProvider';
import { useGnosisMedianOracle } from 'src/providers/GnosisMedianOracleProvider';
import { getBTokenByAddress } from 'src/utils/token';
import { AssetActionsTabs } from './components/AssetActionsTabs';
import { Parameters } from './components/Parameters';

export const AssetPage = () => {
  const [{ chainConfig }] = useAppChain();
  const { poolId } = useParams();
  const { fetchingPoolAssets } = useSelectedPoolAssets();
  const { loading: fetchingGnosisPrices } = useGnosisMedianOracle();
  const { loading: fetchingBoosterPrices } = useBoosterPrices();
  const { assets } = useAssets();
  const { assetAddress } = useParams();

  const poolName = poolId ? chainConfig.pools[poolId as PoolId].name : '';

  const bToken = useMemo(() => {
    if (!assetAddress) return;

    return getBTokenByAddress(assets, assetAddress);
  }, [assetAddress, assets]);

  const underlyingAsset = useMemo(() => {
    if (!bToken?.underlyingAssetAddress) return;

    return getBTokenByAddress(assets, bToken?.underlyingAssetAddress);
  }, [assets, bToken?.underlyingAssetAddress]);

  if (fetchingPoolAssets)
    return (
      <Stack spacing={2} alignItems="center" mt={4}>
        <CircularProgress />
        <h2>Loading assets...</h2>
      </Stack>
    );

  if (fetchingGnosisPrices || fetchingBoosterPrices)
    return (
      <Stack spacing={2} alignItems="center" mt={4}>
        <CircularProgress />
        <h2>Loading prices...</h2>
      </Stack>
    );

  return bToken ? (
    <>
      <Stack alignItems="center" mb={6}>
        <Typography variant="h2">
          {poolName} pool {bToken.wrapped.underlying.symbol}
        </Typography>
      </Stack>
      <UiCard sx={{ maxWidth: '80rem', margin: '0 auto' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} mt={{ xs: -1, md: -3 }}>
            <AssetActionsTabs />
          </Grid>
          <Grid item xs={12} mb={-6}>
            <Parameters asset={bToken} underlyingAsset={underlyingAsset} />
          </Grid>
          <UnwrapPending asset={bToken} />
          <Outlet context={{ asset: bToken }} />
        </Grid>
      </UiCard>
    </>
  ) : (
    <Box>
      <Typography>Asset not found</Typography>
    </Box>
  );
};
