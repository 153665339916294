import debug from 'debug';
import { ethers } from 'ethers';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { vaultAbi, vaultsFactoryAbi } from 'src/abi';
import { useAppChain } from 'src/providers/AppChainProvider';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { BToken } from 'src/types/asset';
import { BN } from 'src/utils/bigNumber';
import { isAddressesEq } from '../utils/compareAddresses';
import { useImmutableCallback } from './useActualRef';

const log = debug('hooks:usePendingUnwrap');

const INIT_PENDING_UNWRAP = { pendingAmount: '0', startTS: 0 };

type PendingUnwrap = {
  pendingAmount: string;
  startTS: number;
};

export const usePendingUnwrap = (asset: BToken | undefined) => {
  const [{ chainConfig, appRpcProvider, appWsProvider }] = useAppChain();
  const { userAddress } = useWeb3State();

  const [loading, setLoading] = useState(true);
  const [delay, setDelay] = useState(0);
  const [pendingWrap, setPendingWrap] = useState<PendingUnwrap>(INIT_PENDING_UNWRAP);
  const [claimTS, setClaimTS] = useState(0);

  useEffect(() => {
    if (claimTS === 0) return;

    const interval = window.setInterval(() => {
      if (claimTS < Date.now()) {
        clearInterval(interval);
        return;
      }

      fetchPendingUnwraps();
    }, 1000);

    return () => clearInterval(interval);
  }, [claimTS]);

  useEffect(() => {
    if (!userAddress) return;
    if (!asset) return;

    const vaultContract = new ethers.Contract(asset.wrapped.address, vaultAbi, appWsProvider);

    vaultContract.on('UnwrapRequested', unwrapRequestedListener);
    vaultContract.on('Claimed', unwrapRequestedListener);

    return () => {
      vaultContract.off('UnwrapRequested', unwrapRequestedListener);
      vaultContract.off('Claimed', unwrapRequestedListener);
    };
  }, [asset, userAddress]);

  useEffect(() => {
    if (userAddress) return;

    setPendingWrap(INIT_PENDING_UNWRAP);
    setClaimTS(0);
    setDelay(0);
  }, [userAddress]);

  useEffect(() => {
    const vaultFactoryContract = new ethers.Contract(
      chainConfig.contracts.vaultFactory,
      vaultsFactoryAbi,
      appRpcProvider,
    );
    vaultFactoryContract.unwrapDelay().then((resp: bigint) => {
      setDelay(Number(resp));
    });
  }, [chainConfig]);

  useEffect(() => {
    if (!userAddress) return;

    fetchPendingUnwraps();
  }, [userAddress, asset, appRpcProvider]);

  useEffect(() => {
    if (BN(pendingWrap.pendingAmount).eq(0)) {
      setClaimTS(0);
      return;
    }

    setClaimTS((Number(delay) + Number(pendingWrap.startTS)) * 1000);
  }, [delay, pendingWrap]);

  const fetchPendingUnwraps = () => {
    if (!asset) return;

    setLoading(true);

    const vaultContract = new ethers.Contract(asset.wrapped.address, vaultAbi, appRpcProvider);

    vaultContract
      .pendingUnwraps(userAddress)
      .then((resp: [bigint, bigint]) => {
        setPendingWrap({
          pendingAmount: String(resp[0]),
          startTS: Number(resp[1]),
        });
        setLoading(false);
      })
      .catch(() => {
        setPendingWrap(INIT_PENDING_UNWRAP);
      });
  };

  const unwrapRequestedListener = useImmutableCallback((walletAddress: string) => {
    if (!isAddressesEq(walletAddress, userAddress as string)) return;

    log('unwrapRequestedListener walletAddress', walletAddress);

    fetchPendingUnwraps();
  });

  return {
    loading,
    claimTS,
    ...pendingWrap,
    claimable: moment().isAfter(claimTS),
    fetchPendingUnwraps,
  };
};
