
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { isChainSupported } from '../utils/chain';
import { useAppChain } from 'src/providers/AppChainProvider';

export const useChainStatus = () => {
  const { userAddress, chainId: walletChain  } = useWeb3State();
  const [{ chainConfig }] = useAppChain();
  const appChain = chainConfig.id;

  const isWalletConnected = Boolean(userAddress);
  const isChainsIdsAreEq = walletChain === appChain;
  const isWalletChainSupported = isChainSupported(walletChain);

  return { isWalletConnected, isChainsIdsAreEq, isWalletChainSupported };
};
