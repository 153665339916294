import BigNumber from 'bignumber.js';
import { useTokenBalance } from 'src/providers/BalancesProvider';
import { useSelectedPoolWalletSummary } from 'src/providers/WalletSummaryProvider';
import { BToken } from 'src/types/asset';
import { BN } from 'src/utils/bigNumber';
import { calcTokenSupplying } from '../utils/token';
import { useBTokenPrice } from './useBTokenPrice';

export const useTokenMaxWithdraw = (asset?: BToken) => {
  const { accountBorrowingTotalUsd, accountBorrowLimitTotalUsd } = useSelectedPoolWalletSummary();
  const { balance: tokenBalance } = useTokenBalance(asset?.address);
  const price = useBTokenPrice(asset);

  if (!asset) return '0';
  if (!price) return '0';

  const tokenSupplying = calcTokenSupplying(asset, tokenBalance.raw);

  const tokenSupplyingInUsd = BN(tokenSupplying)
    .times(price.fullPrecision)
    .div(BN(10).pow(Number(asset.wrapped.decimals)));

  const borrowLimit = BN(accountBorrowLimitTotalUsd).minus(accountBorrowingTotalUsd);
  const accountMaxWithdrawInUsd = borrowLimit.div(
    BN(Number(asset?.collateralFactorMantissa || '0')).div(1e18),
  );

  const maxWithdrawUsd = BigNumber.min(tokenSupplyingInUsd, accountMaxWithdrawInUsd);

  if (!asset.inMarket) {
    return BN(tokenSupplyingInUsd)
      .div(price.fullPrecision || 0)
      .toFixed(Number(asset.wrapped.decimals));
  }

  if (maxWithdrawUsd.gt(0)) {
    const val = BN(maxWithdrawUsd).div(price.fullPrecision).toFixed(Number(asset.wrapped.decimals));

    return BN(val).eq(0) ? '0' : val;
  }

  return '0';
};
