import { Card, SxProps, Theme } from '@mui/material';
import { FCC } from 'src/types/FCC';

export const UiCard: FCC<{ sx?: SxProps<Theme> | undefined }> = ({ children, sx }) => {
  return (
    <Card
      sx={{
        padding: { xs: 1, md: 3 },
        boxShadow: 'none',
        background: (theme) => theme.palette.background.neutral,
        ...sx,
      }}
    >
      {children}
    </Card>
  );
};
