const navConfig = [
  // {
  //   subheader: 'Home',
  //   path: 'https://multidata.ai',
  //   isExternal: true,
  // },
  {
    subheader: 'Docs',
    path: 'https://docs.booster.foundation/',
    isExternal: true,
  },
  // {
  //   subheader: 'Supported Networks',
  //   path: '/oracles',
  //   items: SUPPORTED_NETWORKS.map((network) => {
  //     return {
  //       title: CHAINS.find((chain) => chain.id === network)?.name || '',
  //       path: `/oracles/${network}`,
  //     };
  //   }),
  // },
];

export default navConfig;
