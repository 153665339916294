import { Stack, Typography } from '@mui/material';

export const RepayHelpMessage = () => (
  <Stack
    sx={{
      padding: '1rem 1.5rem',
      background: (theme) => theme.palette.grey[300],
      borderRadius: '8px',
      mt: '20px',
    }}
  >
    <Typography>
      To repay, wrap your underlying asset first. This protocol exclusively uses wrapped assets for
      enhanced security. Once wrapped, you'll be able to complete the repayment.{' '}
      <a
        href="https://docs.booster.foundation/universal-vault"
        target="_blank"
        rel="noreferrer"
        style={{ color: 'inherit' }}
      >
        Read our Docs for details.
      </a>
    </Typography>
  </Stack>
);
