import { Grid, Typography } from '@mui/material';
import debug from 'debug';
import { ethers } from 'ethers';
import moment from 'moment';
import { FC } from 'react';
import { vaultAbi } from 'src/abi';
import { UiCard } from 'src/components/ui/UiCard';
import { UiExecuteButton } from 'src/components/ui/UiExecuteButton';
import { usePendingUnwrap } from 'src/hooks/usePendingUnwrap';
import { useSetModal } from 'src/providers/ModalsProvider';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { BToken } from 'src/types/asset';
import { BN, getDisplayAmount } from 'src/utils/bigNumber';
import { useTransactions } from '../../../providers/TransactionsProvider';

const log = debug('components:UnwrapPending');

export const UnwrapPending: FC<{ asset: BToken }> = ({ asset }) => {
  const { walletProvider } = useWeb3State();
  const { claimTS, pendingAmount, claimable, fetchPendingUnwraps } = usePendingUnwrap(asset);
  const setModal = useSetModal();
  const { trackTx, trackError } = useTransactions();

  const claim = async () => {
    if (!walletProvider) return;

    let tx;

    try {
      const signer = await walletProvider.getSigner();
      const vaultContract = new ethers.Contract(asset.wrapped.address, vaultAbi, signer);

      setModal({ key: 'loader', title: 'Confirm your transaction in the wallet' });

      tx = await vaultContract.claim();

      setModal({
        key: 'loader',
        title: 'Claiming...',
        txHash: tx.txHash,
      });

      trackTx(tx);

      log('tx', tx);

      await tx.wait();

      fetchPendingUnwraps();

      setModal(null);

      log('Successfully claimed.');
    } catch (err) {
      trackError(err, tx);
      setModal(null);
      console.error('Claim action failed:', err);
      throw err;
    }
  };

  if (BN(pendingAmount).eq(0)) return null;

  return (
    <Grid item xs={12}>
      <UiCard>
        <Typography textAlign="center" variant="h4" mb={4}>
          Unwrap pending
        </Typography>

        {claimable ? (
          <UiExecuteButton
            executeLabel={`Claim ${getDisplayAmount(pendingAmount, {
              decimals: Number(asset.wrapped.decimals),
            })} ${asset.wrapped.underlying.symbol}`}
            onClick={claim}
          />
        ) : (
          <Typography textAlign="center" variant="h6">
            Claim{' '}
            {getDisplayAmount(pendingAmount, {
              decimals: Number(asset.wrapped.decimals),
            })}{' '}
            {asset.wrapped.underlying.symbol} available at{' '}
            {moment(claimTS).format('HH:mm:ss DD/MM/YYYY')}
          </Typography>
        )}
      </UiCard>
    </Grid>
  );
};
