import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { CollateralSwitch } from 'src/components/CollateralSwitch';
import { Label } from 'src/components/label/Label';
import { SupplyTooltip } from 'src/components/SupplyTooltip';
import { UiCard } from 'src/components/ui/UiCard';
import { useAppChain } from 'src/providers/AppChainProvider';
import { useTokenBalance, useTokenBorrowedBalance } from 'src/providers/BalancesProvider';
import { useWeb3State } from 'src/providers/Web3CtxProvider';
import { BToken } from 'src/types/asset';
import { FCC } from 'src/types/FCC';
import { BN, getDisplayAmount, shortenNumber } from 'src/utils/bigNumber';
import { calcTokenBorrowLimit, calcTokenTotalSupplying, calculateAPY } from 'src/utils/token';

const PoolAssetParam: FCC<{ name: string; value: string | bigint | ReactJSXElement }> = ({
  name,
  value,
}) => {
  return (
    <Stack>
      <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
        {name}
      </Typography>
      {React.isValidElement(value) ? (
        value
      ) : (
        <Typography variant="subtitle1">{value.toString()}</Typography>
      )}
    </Stack>
  );
};

export const PoolAsset: FCC<{ asset: BToken }> = ({ asset }) => {
  const [{ chainConfig }] = useAppChain();
  const { userAddress } = useWeb3State();
  const isMobile = useMediaQuery('(max-width:425px)');

  const { balance: underlyingBalance } = useTokenBalance(asset.wrapped.underlying.address);
  const { balance: supplyingBalance } = useTokenBalance(asset.address);
  const borrowedBalance = useTokenBorrowedBalance(asset.address);

  const supplying = calcTokenTotalSupplying(asset);
  const borrowLimit = calcTokenBorrowLimit(asset);

  return (
    <UiCard
      sx={{
        padding: { xs: 3, md: 3 },
        '&:hover': {
          background: (theme) => theme.palette.grey[300],
        },
      }}
    >
      <Stack spacing={2}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            sx={{
              marginRight: 'auto !important',
            }}
          >
            {asset.wrapped.underlying.symbol}
          </Typography>
          {underlyingBalance && BN(underlyingBalance.raw).gt(0) && (
            <Tooltip title="Owning" placement="top">
              <Label color="primary" variant="filled" />
            </Tooltip>
          )}
          {supplyingBalance && BN(supplyingBalance.raw).gt(0) && <SupplyTooltip />}
          {borrowedBalance && BN(borrowedBalance.raw).gt(0) && (
            <Tooltip title="Borrowing" placement="top">
              <Label color="warning" variant="filled" />
            </Tooltip>
          )}
        </Stack>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={2} justifyContent="space-between">
          <Stack
            direction={isMobile ? 'row' : 'column'}
            justifyContent={isMobile ? 'space-between' : 'flex-start'}
            spacing={2}
            width="100%"
          >
            <PoolAssetParam
              name="Supplying"
              value={shortenNumber(
                getDisplayAmount(supplying, { decimals: Number(asset.wrapped.decimals) }),
              )}
            />
            <PoolAssetParam
              name="Supply APY"
              value={`${calculateAPY(asset.supplyRatePerBlock, chainConfig.blocksPerDay)} %`}
            />
          </Stack>

          <Stack
            direction={isMobile ? 'row' : 'column'}
            justifyContent={isMobile ? 'space-between' : 'flex-start'}
            spacing={2}
            width="100%"
          >
            <PoolAssetParam
              name="Borrow Limit"
              value={shortenNumber(
                getDisplayAmount(borrowLimit.raw, {
                  decimals: Number(asset.wrapped.decimals),
                }),
              )}
            />
            <PoolAssetParam
              name="Borrow APY"
              value={`${calculateAPY(asset.borrowRatePerBlock, chainConfig.blocksPerDay)} %`}
            />
          </Stack>

          {userAddress && (
            <Stack direction="column" spacing={2} width="100%">
              <PoolAssetParam name="Use as collateral" value={<CollateralSwitch asset={asset} />} />
            </Stack>
          )}
        </Stack>
      </Stack>
    </UiCard>
  );
};
