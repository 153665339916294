import { useParams } from 'react-router-dom';
import { PoolId } from '../configs/pools.config';
import { useAppChain } from '../providers/AppChainProvider';

export const usePools = () => {
  const [{ chainConfig }] = useAppChain();
  const { poolId } = useParams() as { poolId: PoolId };

  const poolsList = Object.values(chainConfig.pools);
  const selectedPoolConfig = chainConfig.pools[poolId];

  return { poolsList, selectedPoolConfig };
};
