import { Tooltip, useTheme } from '@mui/material';
import { Iconify } from '../Iconify';

export const UiQuestion = ({ message }: { message: string }) => {
  const theme = useTheme();
  return (
    <Tooltip title={message} enterTouchDelay={200}>
      <Iconify
        icon="material-symbols:question-mark"
        sx={{
          fontSize: '.8em',
          height: 'auto',
          width: 'auto',
          padding: '0.1em',
          background: theme.palette.background.default,
          borderRadius: '50%',
          border: `1px solid ${theme.palette.grey['500']}`,
          marginBottom: '-0.2em',
          marginLeft: '.4em',
          cursor: 'pointer',
        }}
      />
    </Tooltip>
  );
};
