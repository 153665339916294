import { Grid, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import { PoolAsset } from 'src/pages/home/components/PoolAsset';
import { useSelectedPoolAssets } from 'src/providers/AssetsProvider';
import { useBoosterPrices } from 'src/providers/BoosterOraclePricesProvider';
import { useGnosisMedianOracle } from 'src/providers/GnosisMedianOracleProvider';

export const PoolAssetsList = () => {
  const { assets } = useSelectedPoolAssets();
  const { fetchingPoolAssets } = useSelectedPoolAssets();
  const { loading: fetchingGnosisPrices } = useGnosisMedianOracle();
  const { loading: fetchingBoosterPrices } = useBoosterPrices();

  const assetsFilteredByGuardian = Object.values(assets).filter(
    (asset) => !asset.mintPaused && !asset.borrowPaused,
  );

  if (fetchingPoolAssets)
    return (
      <Stack spacing={2} alignItems="center" mt={4}>
        <CircularProgress />
        <h2>Loading assets...</h2>
      </Stack>
    );

  if (fetchingGnosisPrices || fetchingBoosterPrices)
    return (
      <Stack spacing={2} alignItems="center" mt={4}>
        <CircularProgress />
        <h2>Loading prices...</h2>
      </Stack>
    );

  return (
    <Grid container spacing={2} mt={2}>
      {assetsFilteredByGuardian.map((asset) => (
        <Grid item xs={12} md={6} key={asset.address}>
          <Link to={`${asset.address}`} style={{ textDecoration: 'none' }}>
            <PoolAsset asset={asset} />
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};
