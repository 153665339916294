import { FC } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Link } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useSetModal } from 'src/providers/ModalsProvider';
import { useAppChain } from 'src/providers/AppChainProvider';

export const LoaderModal: FC<{ title: string; txHash?: string }> = ({ title, txHash }) => {
  const setModal = useSetModal();
  const [{ chainConfig }] = useAppChain();

  return (
    <Dialog
      open={true}
      onClose={() => setModal(null)}
      maxWidth="xs"
      fullWidth
      sx={{
        '.MuiPaper-root': {
          alignItems: 'center',
        },
      }}
    >
      <DialogContent sx={{ my: 4, alignItems: 'center', overflow: 'hidden' }}>
        <CircularProgress size="5rem" thickness={2.5} color="inherit" />
      </DialogContent>
      <DialogTitle textAlign="center">{title}</DialogTitle>
      {txHash && (
        <DialogActions>
          <Link mb={2} href={`${chainConfig.explorer}/tx/${txHash}`} target={'_blank'}>
            Show transaction status
          </Link>
        </DialogActions>
      )}
    </Dialog>
  );
};
