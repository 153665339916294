import { FC } from 'react';
import { ButtonProps, Button } from '@mui/material';
import { useWeb3Controller, useWeb3State } from 'src/providers/Web3CtxProvider';
import { useSetModal } from 'src/providers/ModalsProvider';

export const ConnectBtn: FC<ButtonProps> = (props) => {
  const { userAddress } = useWeb3State();
  const { disconnect } = useWeb3Controller();
  const setModal = useSetModal();

  return userAddress ? (
    <Button {...props} variant="soft" color="inherit" onClick={disconnect}>
      Disconnect
    </Button>
  ) : (
    <Button
      {...props}
      variant="soft"
      color="inherit"
      onClick={() => setModal({ key: 'connectWallet' })}
    >
      Connect wallet
    </Button>
  );
};
